import React, {
  PropsWithChildren,
  createContext,
  useMemo,
  useState,
} from 'react';
import useGetAssetRequestsCount from 'slices/ContentSharing/hooks/useGetAssetRequestsCount';
import { CONTENT_SHARING_REQUESTS_COUNT } from 'slices/ContentSharing/types';

export type MenuCountersType = Record<string, number>;
type MenuCountersContextData = {
  counters: MenuCountersType;
  setCounters: (
    func: (prevState: MenuCountersType) => MenuCountersType
  ) => void;
};

export const MenuCountersContext = createContext<MenuCountersContextData>(
  {} as any
);

const MenuCountersContextProvider: React.FC<PropsWithChildren<{}>> = ({
  children,
}) => {
  const [counters, setCounters] = useState<MenuCountersType>({});
  useGetAssetRequestsCount({
    onSuccess: data => {
      setCounters(prevState => ({
        ...prevState,
        [CONTENT_SHARING_REQUESTS_COUNT]: data,
      }));
    },
  });

  const value = useMemo(
    () => ({
      counters,
      setCounters,
    }),
    [counters]
  );

  return (
    <MenuCountersContext.Provider value={value}>
      {children}
    </MenuCountersContext.Provider>
  );
};

export default MenuCountersContextProvider;
