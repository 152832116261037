import React, { useEffect, useState } from 'react';
import classnames from 'classnames';
import { Link } from 'react-router-dom';
import { Collapse } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown } from '@fortawesome/pro-light-svg-icons';
import useMenuCounters from 'infrastructure/MenuContext/useMenuCounters';

export interface ParentNavPointProps {
  /** Function to force the sidebar closed (for the overlay button and after clicking a link) */
  closeSidebar: () => void;
  /** Object containing all the data required to render a parent nav point */
  navPoint: {
    icon: React.ReactNode;
    name: React.ReactNode;
    counterKey?: string;
    items: Array<{
      current: boolean;
      url: string;
      name: React.ReactNode;
      counterKey?: string;
    }>;
  };
  /** Function to open the sidebar so items nav points are immediately visible */
  openSidebar: () => void;
  /** Is the sidebar open (mostly for phones & tablets, always true on desktop) */
  sidebarIsOpen: boolean;
}

const ParentNavPoint: React.FC<ParentNavPointProps> = props => {
  const { closeSidebar, navPoint, openSidebar, sidebarIsOpen = false } = props;
  const { counters } = useMenuCounters();

  const parentOfCurrent =
    navPoint.items &&
    navPoint.items.filter(childNavPoint => childNavPoint.current).length > 0;

  const [isOpen, setIsOpen] = useState(false);

  const toggle = () => {
    setIsOpen(!isOpen);
    if (!isOpen && !sidebarIsOpen && window.innerWidth < 1200) {
      openSidebar();
    }
    (document.activeElement as HTMLElement)?.blur();
  };

  useEffect(() => {
    const navPointItems = navPoint.items;
    const isParent = navPointItems.find(item => item.current === true);
    setIsOpen(!!isParent);
  }, [navPoint, sidebarIsOpen]);

  useEffect(() => {
    const handleResize = () =>
      window.innerWidth < 1199 && !sidebarIsOpen && setIsOpen(false);
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  });

  const onExited = () => setIsOpen(false);

  return (
    <div className="sub-menu collapse-container">
      <button
        className={classnames('trigger unstyled-button nav-point', {
          open: isOpen,
          closed: !isOpen,
          'parent-of-current': parentOfCurrent,
          current: isOpen && !sidebarIsOpen && window.innerWidth < 1199,
        })}
        color="primary"
        onClick={toggle}
        type="button"
      >
        {navPoint.icon && <div className="icon">{navPoint.icon}</div>}
        <div className="nav-point-title">{navPoint.name}</div>
        <div className="expand-icon">
          <FontAwesomeIcon icon={faChevronDown} fixedWidth />
        </div>
      </button>
      <Collapse
        isOpen={window.innerWidth < 1200 ? isOpen && sidebarIsOpen : isOpen}
        onExited={onExited}
      >
        <div className="collapse-content">
          {navPoint.items &&
            navPoint.items.map(
              (childNavPoint, i) =>
                childNavPoint.url && (
                  <Link
                    // eslint-disable-next-line react/no-array-index-key
                    key={`nav-point-${i}`}
                    onClick={closeSidebar}
                    className={classnames('nav-point sub-nav-point', {
                      current: childNavPoint.current,
                    })}
                    to={childNavPoint.url}
                  >
                    {childNavPoint.counterKey &&
                    counters[childNavPoint.counterKey] ? (
                      <>
                        <div>{childNavPoint.name}</div>
                        <div className="menuCounter">
                          {counters[childNavPoint.counterKey]}
                        </div>
                      </>
                    ) : (
                      <div className="nav-point-title">
                        {childNavPoint.name}
                      </div>
                    )}
                  </Link>
                )
            )}
        </div>
      </Collapse>
    </div>
  );
};

export default ParentNavPoint;
