import { PaginatedListResponse } from 'components/v2/DataTable/DataTable';

export const CONTENT_SHARING_REQUESTS_COUNT = 'contentSharingRequestsCount';

export type ContentSharingStatus =
  | 'available'
  | 'accessRequested'
  | 'denied'
  | 'complete';

export type ContentSharingType = 'story' | 'clip';

export type ContentSharingAssetRecord = {
  id: string;
  thumbnailUrl: string;
  title: string;
  tenantName: string;
  tenantId: string;
  lastModificationTime: string;
  creationTime: string;
  status: ContentSharingStatus;
  type: ContentSharingType;
  permissionRequired: boolean;
  previewUrl: string;
  assetVersionCount: number;
};

export type ContentSharingAssetVersions = {
  id: string;
  thumbnailUrl: string;
  type: ContentSharingType;
  lastModificationTime: string;
  title: string;
};

export type ContentSharingRequestRecord = {
  id: string;
  thumbnailUrl: string;
  title: string;
  requestedByTenantName: string;
  requestedByTenantId: string;
  creationTime: string;
  status: SharedAssetStatus;
  type: SharedAssetType;
  assetId: string;
  previewUrl: string;
};

export type SharedAssetStatus =
  | 'available'
  | 'accessRequested'
  | 'complete'
  | 'denied';

export type SharedAssetType = 'story' | 'clip';

export type ContentSharingAssetsList =
  PaginatedListResponse<ContentSharingAssetRecord>;

export type ContentSharingRequestsList =
  PaginatedListResponse<ContentSharingRequestRecord>;

export type ContentSharingAssetVersionsList =
  PaginatedListResponse<ContentSharingAssetVersions>;
