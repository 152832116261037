import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import ErrorMessage from './components/ErrorMessage';

export default class ErrorBoundry extends PureComponent {
  static propTypes = {
    children: PropTypes.node.isRequired,
  };

  state = {
    error: null,
    info: null,
  };

  componentDidCatch(error, info) {
    this.setState({ error, info });
  }

  render() {
    const { error, info } = this.state;
    const { children } = this.props;

    if (error || info) {
      return <ErrorMessage />;
    }

    return children;
  }
}
