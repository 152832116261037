import React, {
  createContext,
  PropsWithChildren,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react';

export const UploadStates = ['uploading', 'uploaded', 'failed'] as const;

export type UploadState = typeof UploadStates[number];

export interface FileInfo {
  id: string;
  name: string;
  size: number;
  src?: string;
  batchId: string;
  bytesUploaded: number;
  rotation?: number;
  status: UploadState;
  type: 'video' | 'image';
  resourceId?: string; // Story or Clip Id
}

interface UploadsState {
  files: readonly FileInfo[];
  addFiles: (files: FileInfo[]) => void;
  setUploadProgress: (id: string, bytesUploaded: number) => void;
  setUploadCompleted: (id: string) => void;
  setUploadFailed: (id: string) => void;
}

type Mutator = (fileInfo: FileInfo) => FileInfo;

export const UploadsContext = createContext({} as UploadsState);

const UploadsContextProvider: React.FC<PropsWithChildren<{}>> = ({
  children,
}) => {
  const [files, setFiles] = useState<FileInfo[]>([]);
  const addFiles = useCallback((newFiles: readonly FileInfo[]) => {
    setFiles(oldFiles => [...oldFiles, ...newFiles]);
  }, []);

  const mutateFileInfo = useCallback((id: string, mutator: Mutator) => {
    setFiles(oldFiles =>
      oldFiles.map(file => (file.id === id ? mutator(file) : file))
    );
  }, []);

  const setUploadProgress = useCallback(
    (id: string, bytesUploaded: number) => {
      const mutator: Mutator = fileInfo => ({
        ...fileInfo,
        bytesUploaded,
      });
      return mutateFileInfo(id, mutator);
    },
    [mutateFileInfo]
  );

  const setUploadState = useCallback(
    (id: string, status: UploadState) => {
      const mutator: Mutator = fileInfo => ({
        ...fileInfo,
        status,
      });
      return mutateFileInfo(id, mutator);
    },
    [mutateFileInfo]
  );

  const setUploadCompleted = useCallback(
    (id: string) => {
      setUploadState(id, 'uploaded');
      setTimeout(() => {
        setFiles(oldFiles => oldFiles.filter(file => file.id !== id));
        URL.revokeObjectURL(id);
      }, 3000);
    },
    [setUploadState]
  );

  const setUploadFailed = useCallback(
    (id: string) => {
      setUploadState(id, 'failed');
    },
    [setUploadState]
  );

  const contextValue = useMemo(
    () => ({
      files,
      addFiles,
      setUploadProgress,
      setUploadCompleted,
      setUploadFailed,
    }),
    [addFiles, files, setUploadCompleted, setUploadFailed, setUploadProgress]
  );

  return (
    <UploadsContext.Provider value={contextValue}>
      {children}
    </UploadsContext.Provider>
  );
};

export default UploadsContextProvider;

export const useUploadsContext = () => {
  const context = useContext(UploadsContext);

  if (!context) {
    throw new Error(
      'useUploadsContext must be used within a UploadsContextProvider'
    );
  }

  return context;
};
