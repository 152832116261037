import React, {
  createContext,
  PropsWithChildren,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useHistory } from 'react-router';
import '@microsoft/applicationinsights-shims';
import { ReactPlugin } from '@microsoft/applicationinsights-react-js';
import {
  ApplicationInsights,
  type IApplicationInsights,
  type ITelemetryItem,
} from '@microsoft/applicationinsights-web';
import { getConfig } from 'infrastructure/config';
import useCurrentUser from 'infrastructure/currentUser/useCurrentUser';
import useCurrentTenant from 'infrastructure/currentTenant/useCurrentTenant';

type AppInsightsCustomProperties = {
  [key: string]: any;
};

type AppInsightContextValue = {
  trackAppInsightsError: (
    exception: Error | string,
    customProperties?: AppInsightsCustomProperties
  ) => void;
  trackAppInsightsEvent: (
    eventName: string,
    customProperties?: AppInsightsCustomProperties
  ) => void;
};

export const AppInsightsContext = createContext<AppInsightContextValue>({
  trackAppInsightsError: () => {},
  trackAppInsightsEvent: () => {},
});

export const useAppInsights = () => useContext(AppInsightsContext);

export const AppInsightsContextProvider: React.FC<PropsWithChildren<{}>> = ({
  children,
}) => {
  const [appInsights, setAppInsights] = useState<IApplicationInsights | null>(
    null
  );
  const { currentUser } = useCurrentUser();
  const { currentTenant } = useCurrentTenant();
  const config = getConfig();
  const history = useHistory();

  useEffect(() => {
    if (!currentUser || !config.applicationInsightsInstrumentationKey) {
      return;
    }

    const appInsightsKey = config.applicationInsightsInstrumentationKey;

    const telemetryInitializer = (envelope: ITelemetryItem) => {
      if (envelope.data && currentUser) {
        // eslint-disable-next-line no-param-reassign
        envelope.data = {
          ...envelope.data,
          UserId: currentUser.id,
          UserName: currentUser.name,
          UserRole: currentUser.roles?.[0],
        };
      }
    };

    if (appInsightsKey) {
      const reactPlugin = new ReactPlugin();
      const appInsightsInstance = new ApplicationInsights({
        config: {
          instrumentationKey: appInsightsKey,
          // casting to `any` because of this bug https://github.com/microsoft/applicationinsights-react-js/issues/32
          extensions: [reactPlugin as any],
          extensionConfig: {
            [reactPlugin.identifier]: { history },
          },
        },
      });
      appInsightsInstance.loadAppInsights();
      appInsightsInstance.addTelemetryInitializer(telemetryInitializer);
      setAppInsights(appInsightsInstance);
    }
  }, [config.applicationInsightsInstrumentationKey, currentUser, history]);

  const trackAppInsightsError = useCallback(
    (
      error: Error | string,
      customProperties?: {
        [key: string]: any;
      }
    ) => {
      if (!currentTenant || !appInsights) {
        return;
      }

      const exception = error instanceof Error ? error : new Error(error);
      const properties = {
        ...customProperties,
        Tenant: currentTenant.name,
      };

      appInsights.trackException({
        exception,
        properties,
      });

      appInsights.trackEvent({
        name: exception.message,
        properties,
      });
    },
    [appInsights, currentTenant]
  );

  const trackAppInsightsEvent = useCallback(
    (
      eventName: string,
      customProperties?: {
        [key: string]: any;
      }
    ) => {
      if (!currentTenant || !appInsights) {
        return;
      }

      appInsights.trackEvent({
        name: eventName,
        properties: {
          ...customProperties,
          Tenant: currentTenant.name,
        },
      });
    },
    [appInsights, currentTenant]
  );

  const contextValue = useMemo(
    () => ({ trackAppInsightsError, trackAppInsightsEvent }),
    [trackAppInsightsError, trackAppInsightsEvent]
  );

  return (
    <AppInsightsContext.Provider value={contextValue}>
      {children}
    </AppInsightsContext.Provider>
  );
};
