import React from 'react';
import Loadable from 'react-loadable';
import { Loading } from '@stormideas/react-sdk';

export default Loadable({
  loader: () => import('./CollectionsIndex'),
  loading: () => <Loading visible />,
});

export const List = Loadable({
  loader: () => import('./CollectionsList'),
  loading: () => <Loading visible />,
});

export const Form = Loadable({
  loader: () => import('./CollectionsForm'),
  loading: () => <Loading visible />,
});

export const Content = Loadable({
  loader: () => import('./CollectionContent'),
  loading: () => <Loading visible />,
});
