import React from 'react';
import PropTypes from 'prop-types';
import { Progress } from 'reactstrap';
import classnames from 'classnames';
import './ProgressBar.scss';

function ProgressBar(props) {
  const {
    animated,
    className,
    color,
    hidePercentage,
    percentage,
    percentageOutside,
    rounded,
    size,
    title,
    total,
    value,
  } = props;
  return (
    <div
      className={classnames('progress-bar-container', className, size, {
        'percentage-outside': percentageOutside && !hidePercentage,
        'has-title': title,
      })}
    >
      {title && <h4 className="title">{title}</h4>}
      <div className="value-container">
        <Progress multi className={classnames({ 'rounded-pill': rounded })}>
          <Progress
            animated={animated}
            bar
            className={classnames({ 'rounded-pill': rounded })}
            color={color}
            value={percentage || ((value / total) * 100).toFixed(0)}
          >
            {!hidePercentage && (
              <span
                className={classnames('percentage', {
                  [`text-${color}`]: percentageOutside && !hidePercentage,
                })}
              >
                {(percentage || (value / total) * 100).toFixed(0)}%
              </span>
            )}
          </Progress>
        </Progress>
      </div>
    </div>
  );
}

ProgressBar.propTypes = {
  /** Animate in? */
  animated: PropTypes.bool,
  /** className for custom styles */
  className: PropTypes.string,
  /** Color of the bar */
  color: PropTypes.oneOf([
    'primary',
    'secondary',
    'success',
    'info',
    'warning',
    'danger',
    'light',
    'dark',
  ]),
  /** Don't show the completion percentage */
  hidePercentage: PropTypes.bool,
  /** Can be used instead of passing total and value */
  percentage: PropTypes.number,
  /** Move the percentage top right */
  percentageOutside: PropTypes.bool,
  /** Round the corners */
  rounded: PropTypes.bool,
  /** Size of the bar */
  size: PropTypes.oneOf([null, 'xs', 'sm', 'lg']),
  /** Give context to the progress */
  title: PropTypes.string,
  /** Top of the range */
  total: PropTypes.number,
  /** Progress Value */
  value: PropTypes.number,
};

ProgressBar.defaultProps = {
  animated: false,
  className: null,
  color: 'primary',
  hidePercentage: false,
  percentage: null,
  percentageOutside: false,
  rounded: true,
  size: null,
  title: null,
  total: 100,
  value: 50,
};

export default ProgressBar;
