import React from 'react';
import PropTypes from 'prop-types';

import './UserAvatar.scss';

/** Describe the component */

const UserAvatar = props => {
  const { userName } = props;
  return (
    <>
      {userName ? (
        <svg
          className="user-avatar"
          fill="none"
          height="36"
          viewBox="0 0 36 36"
          width="36"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle cx="18" cy="18" r="18" fill="#154E6E" />
          <text
            alignmentBaseline="central"
            fill="white"
            textAnchor="middle"
            x={18}
            y={18}
          >
            {userName
              .split(' ')
              .map(word => word[0])
              .join('')
              .substr(0, 2)}
          </text>
        </svg>
      ) : (
        <div className="user-avatar">
          <svg
            width="36"
            height="36"
            viewBox="0 0 36 36"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle cx="18" cy="18" r="18" fill="#154E6E" />
            <path
              d="M18 18C20.8125 18 23.1429 15.7734 23.1429 13C23.1429 10.2656 20.8125 8 18 8C15.1473 8 12.8571 10.2656 12.8571 13C12.8571 15.7734 15.1473 18 18 18ZM21.5759 19.25H20.8929C20.0089 19.6797 19.0446 19.875 18 19.875C16.9554 19.875 15.9509 19.6797 15.067 19.25H14.3839C11.4107 19.25 9 21.6328 9 24.5234V26.125C9 27.1797 9.84375 28 10.9286 28H25.0714C26.1161 28 27 27.1797 27 26.125V24.5234C27 21.6328 24.5491 19.25 21.5759 19.25Z"
              fill="white"
            />
          </svg>
        </div>
      )}
    </>
  );
};

UserAvatar.propTypes = {
  /** Users name */
  userName: PropTypes.string,
};

UserAvatar.defaultProps = {
  userName: '',
};

export default UserAvatar;
