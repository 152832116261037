import React, { useRef, useMemo, useState, useLayoutEffect } from 'react';
import variables from 'scss/1-settings/colors.scss';

import './EmptyItemBorder.scss';

const EmptyItemBorder = () => {
  const containerRef = useRef<HTMLDivElement>(null);
  /**
   * We can't use a regular dashed border as we can't customise the dash length and spacing.
   * We also can't use an SVG background image, since it gets cropped when the container size is a decimal value.
   * We also can't just set the SVG width and height to 100% for the same reason.
   * Basically we need to know the exact container height for this trick to work, hence the ResizeObserver.
   * */
  const [dimensions, setDimensions] = useState({
    width: 0,
    height: 0,
  });

  const sizeObserver = useMemo(
    () =>
      new ResizeObserver(entries => {
        if (entries[0]) {
          const { width, height } = entries[0].contentRect;
          setDimensions({ width, height });
        }
      }),
    []
  );

  useLayoutEffect(() => {
    if (containerRef.current) {
      sizeObserver.observe(containerRef.current);
    }

    return () => {
      sizeObserver.disconnect();
    };
  }, [sizeObserver]);

  const BORDER_WIDTH = 2;
  const BORDER_RADIUS = 3;

  return (
    <div className="empty-item" ref={containerRef}>
      <svg
        width={dimensions.width || '100%'}
        height={dimensions.height || '100%'}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        {dimensions.width > 0 && (
          <rect
            x={BORDER_WIDTH / 2}
            y={BORDER_WIDTH / 2}
            width={dimensions.width - BORDER_WIDTH}
            height={dimensions.height - BORDER_WIDTH}
            rx={BORDER_RADIUS}
            fill="none"
            stroke={variables['gray-300']}
            strokeWidth={BORDER_WIDTH}
            strokeMiterlimit="16"
            strokeDasharray="8 8"
          />
        )}
      </svg>
    </div>
  );
};

export default EmptyItemBorder;
