import React, { PropsWithChildren, useState } from 'react';
import classnames from 'classnames';
import { Collapse } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronUp } from '@fortawesome/pro-regular-svg-icons';
import Button from 'components/Button';
import { IconProp } from '@fortawesome/fontawesome-svg-core';

import './Fieldset.scss';

/** Used like a normal HTML fieldset element, to define discrete sections in a form. */

export interface FieldsetProps {
  append?: React.ReactNode;
  className?: string;
  closed?: boolean;
  collapsible?: boolean;
  legend?: React.ReactNode;
  actionButton?: {
    onClick: () => void;
    callToAction: string;
    icon: IconProp;
    isDisabled?: boolean;
  };
}

const Fieldset: React.FC<PropsWithChildren<FieldsetProps>> = ({
  append,
  children,
  className,
  closed = false,
  collapsible = false,
  legend,
  actionButton,
}) => {
  const shouldShowButton = collapsible || (!collapsible && closed);
  const statusClassName = closed ? 'closed' : 'opened';
  const [collapse, setCollapse] = useState(!closed);
  const [statusClass, setStatusClass] = useState(statusClassName);

  const onEntering = () => {
    setStatusClass('opening');
  };

  const onEntered = () => {
    setStatusClass('opened');
  };

  const onExiting = () => {
    setStatusClass('closing');
  };

  const onExited = () => {
    setStatusClass('closed');
  };

  const toggle = () => {
    setCollapse(!collapse);
  };

  return (
    <fieldset
      className={classnames(
        'fieldset',
        'collapse__wrapper',
        statusClass,
        className,
        {
          collapsible,
        }
      )}
    >
      <div className="legend-wrapper">
        <legend>
          {legend}
          {shouldShowButton && (
            <button
              onClick={toggle}
              className="btn-light icon rounded fieldset-right-icon"
              type="button"
            >
              <FontAwesomeIcon icon={faChevronUp} />
            </button>
          )}
        </legend>
        {actionButton && (
          <Button
            color="primary"
            icon={<FontAwesomeIcon icon={actionButton.icon} />}
            iconAfter={false}
            disabled={actionButton.isDisabled}
            data-testid="recipeResultActionButton"
            className="result-action-btn"
            onClick={() => actionButton.onClick()}
          >
            {actionButton.callToAction}
          </Button>
        )}
        {append && <div className="legend-wrapper-append">{append}</div>}
      </div>

      <Collapse
        className="fieldset-content"
        isOpen={collapse}
        onEntering={onEntering}
        onEntered={onEntered}
        onExiting={onExiting}
        onExited={onExited}
      >
        {children}
      </Collapse>
    </fieldset>
  );
};

export default Fieldset;
