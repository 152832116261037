import React from 'react';
import Loadable from 'react-loadable';
import { Loading } from 'components';

export default Loadable({
  loader: () => import('./index'),
  loading: () => <Loading visible />,
});

export const Form = Loadable({
  loader: () => import('./FormPage'),
  loading: () => <Loading visible />,
});

export const List = Loadable({
  loader: () => import('./TenantsList'),
  loading: () => <Loading visible />,
});

export const Features = Loadable({
  loader: () => import('./FeaturesPage'),
  loading: () => <Loading visible />,
});

export const BulkUpdate = Loadable({
  loader: () => import('./BulkUpdatePage'),
  loading: () => <Loading visible />,
});
