import React from 'react';
import Loadable from 'react-loadable';
import { Loading } from 'components';

export default Loadable({
  loader: () => import('./FontsIndex'),
  loading: () => <Loading visible />,
});

export const List = Loadable({
  loader: () => import('./FontsList'),
  loading: () => <Loading visible />,
});

export const Form = Loadable({
  loader: () => import('./FontsFormPage'),
  loading: () => <Loading visible />,
});
