import React from 'react';
import { GlyphDot } from '@visx/glyph';
import type { RenderTooltipGlyphProps } from '@visx/xychart/lib/components/Tooltip';
import { GlyphProps } from '@visx/xychart';
import { ChartDataPoint } from '../types';

export const renderGlyph = ({
  x,
  y,
  size,
  color,
  onPointerMove,
  onPointerOut,
  onPointerUp,
}: GlyphProps<ChartDataPoint['values']>) => {
  const handlers = { onPointerMove, onPointerOut, onPointerUp };
  return (
    <GlyphDot
      left={x}
      top={y}
      r={size}
      fill="white"
      stroke={color}
      strokeWidth={2}
      {...handlers}
    />
  );
};

export const renderTooltipGlyph = ({
  isNearestDatum,
  ...rest
}: RenderTooltipGlyphProps<ChartDataPoint['values']>) => {
  if (!isNearestDatum) {
    return null;
  }

  return renderGlyph(rest);
};

export const renderTooltipGlyphMultiple = (
  props: RenderTooltipGlyphProps<ChartDataPoint['values']>
) => renderGlyph(props);

export type RenderTooltipGlyph = typeof renderTooltipGlyph;
