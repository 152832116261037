/**
 * GET/POST Content Sharing
 * @returns {`contentSharing`}
 */
export const contentSharingEndpoint = `/contentSharingConfigurations`;

/**
 * GET/PUT Content Sharing
 * @returns {`contentSharing/{string}`}
 */
export const singleContentSharingEndpoint = (contentSharingId: string) =>
  `/contentSharingConfigurations/${contentSharingId}`;

/**
 * GET Content Sharing Assets
 * @returns {`contentSharingAssets`}
 */
export const contentSharingAssetsEndpoint = `/contentSharingAssets`;

/**
 * GET Content Sharing Asset Versions
 * @returns {`contentSharingAssets/{assetType}/{id}/versions`}
 */
export const contentSharingAssetsVersionsEndpoint = ({
  assetType,
  id,
}: {
  assetType: string;
  id: string;
}) => `/contentSharingAssets/${assetType}/${id}/versions`;

/**
 * GET / POST Content Sharing Requests
 * @returns {`contentSharingAssets`}
 */
export const contentSharingRequestsEndpoint = `/contentSharingRequests`;

/**
 * POST Content Sharing Requests
 * @returns {`contentSharingAssets/{id}/accept`}
 */
export const contentSharingRequestsAcceptEndpoint = (id: string) =>
  `/contentSharingRequests/${id}/accept`;

/**
 * POST Content Sharing Requests
 * @returns {`contentSharingAssets`}
 */
export const contentSharingRequestsDenyEndpoint = (id: string) =>
  `/contentSharingRequests/${id}/deny`;

/**
 * GET Content Sharing Assets Tenants
 * @returns {`contentSharingAssets/tenants`}
 */
export const contentSharingAssetsTenantsEndpoint = `/contentSharingAssets/tenants`;

/**
 * GET Content Sharing Requests Tenants
 * @returns {`contentSharingRequests/tenants`}
 */
export const contentSharingRequestsTenantsEndpoint = `/contentSharingRequests/tenants`;

/**
 * GET Content Sharing Asset Copy
 * @returns {`contentSharingAssets/{assetType}/{id}/copy`}
 */
export const contentSharingAssetsCopyEndpoint = ({
  assetType,
  id,
}: {
  assetType: string;
  id: string;
}) => `/contentSharingAssets/${assetType}/${id}/copy`;
