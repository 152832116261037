import React, { useCallback, useMemo } from 'react';
import { ParentSizeModern } from '@visx/responsive';
import { XYChart, LineSeries, Axis, Tooltip, Grid } from '@visx/xychart';
import { Loading } from 'components';
import { scaleOrdinal } from '@visx/scale';
import { ChartDataPoint } from '../types';
import buildTheme from '../theme';
import { renderTooltip } from '../ChartTooltip/ChartTooltip';
import { renderTooltipGlyph } from '../Glyph';
import calculateXNumTicks from '../utils/calculateXNumTicks';
import formatChartNumber from '../utils/formatChartNumber';
import formatTooltipDate from '../utils/formatTooltipDate';
import formatXAxisDate from '../utils/formatXAxisDate';

import './LineChart.scss';

export interface LineChartProps {
  /** Height in px */
  height?: number;
  /** Array of colors for the lines */
  colorArray?: string[];
  /** An array of objects describing the data. Each object describes the data at a point on the X-axis. */
  data: Array<ChartDataPoint>;
  /** Automatically prefix units (eg. k or M) */
  autoFormatYTicks?: boolean;
  /** Show show loading spinner */
  loading?: boolean;
  /** Calculate X axis `numTicks` based on chart width */
  responsiveXTicks?: boolean;
  customColorAccessors?: { [key: string]: string };
}

const LineChart: React.FC<LineChartProps> = ({
  height,
  colorArray,
  data,
  autoFormatYTicks = true,
  loading,
  responsiveXTicks = true,
  customColorAccessors,
}) => {
  const theme = useMemo(() => buildTheme({ colors: colorArray }), [colorArray]);

  const formattedData = useMemo(() => data.map(x => x.values), [data]);

  const labels = data?.[0]
    ? Object.keys(data[0].values).filter(
        property => property !== 'name' // Remove key for name
      )
    : [];

  const yAxisTickFormatter = useMemo(() => {
    if (autoFormatYTicks) {
      return formatChartNumber;
    }

    return undefined;
  }, [autoFormatYTicks]);

  const colorScale = useMemo(
    () =>
      customColorAccessors
        ? scaleOrdinal({
            domain: Object.keys(customColorAccessors),
            range: Object.values(customColorAccessors),
          })
        : undefined,
    [customColorAccessors]
  );

  const renderTooltipFunc = useCallback(
    tooltipData =>
      renderTooltip({
        tooltipData: tooltipData.tooltipData,
        colorScale: colorScale || tooltipData.colorScale,
        formatter: formatTooltipDate,
      }),
    [colorScale]
  );

  return (
    <ParentSizeModern className="graph-container" debounceTime={10}>
      {({ width: visWidth, height: visHeight }) => {
        const xNumTicks = responsiveXTicks
          ? calculateXNumTicks(visWidth, formattedData)
          : undefined;

        return (
          <>
            <Loading visible={loading} maskContainer />
            <XYChart
              width={visWidth}
              height={height ?? visHeight}
              xScale={{ type: 'band' }}
              yScale={{ type: 'linear' }}
              theme={theme}
              margin={{ top: 10, right: 40, left: 50, bottom: 40 }}
            >
              <Axis
                orientation="bottom"
                numTicks={xNumTicks}
                tickFormat={formatXAxisDate}
              />
              <Axis
                orientation="left"
                strokeWidth={0}
                tickFormat={yAxisTickFormatter}
              />
              <Grid rows columns={false} />
              {labels.map(label => (
                <LineSeries
                  key={label}
                  dataKey={label}
                  data={formattedData}
                  xAccessor={d => d?.name}
                  yAccessor={d => (d as any)?.[label]}
                  colorAccessor={
                    customColorAccessors
                      ? dataKey => customColorAccessors[dataKey]
                      : undefined
                  }
                />
              ))}
              <Tooltip
                snapTooltipToDatumX
                snapTooltipToDatumY
                showVerticalCrosshair
                showSeriesGlyphs
                unstyled
                applyPositionStyle
                renderTooltip={renderTooltipFunc}
                renderGlyph={renderTooltipGlyph}
              />
            </XYChart>
          </>
        );
      }}
    </ParentSizeModern>
  );
};

export default LineChart;
