import React from 'react';
import Loadable from 'react-loadable';
import { Loading } from '@stormideas/react-sdk';

export default Loadable({
  loader: () => import('./ActionsIndex'),
  loading: () => <Loading visible />,
});

export const List = Loadable({
  loader: () => import('./ActionsList'),
  loading: () => <Loading visible />,
});

export const Form = Loadable({
  loader: () => import('./ActionsFormPage'),
  loading: () => <Loading visible />,
});
