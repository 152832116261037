/* eslint-disable no-cond-assign */
/* eslint-disable no-param-reassign */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown } from '@fortawesome/pro-regular-svg-icons';
import { faCheck } from '@fortawesome/pro-solid-svg-icons';
import { faTimes } from '@fortawesome/pro-light-svg-icons';
import { Button } from 'components';

import './CheckboxDropdown.scss';

/** A dropdown containing an array of checkboxes, useful for table filters */

const CheckboxDropdown = props => {
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const toggle = () => setDropdownOpen(prevState => !prevState);

  const { options, placeholder } = props;

  return (
    <div className="checkbox-dropdown-container">
      <Dropdown
        isOpen={dropdownOpen}
        toggle={toggle}
        className="checkbox-dropdown"
      >
        <DropdownToggle
          className="dropdown-trigger form-control"
          role="listbox"
          tag="button"
        >
          <div className="trigger-title">
            {options.filter(option => option.defaultChecked).length === 0 &&
              placeholder}
            {options.map(
              (option, i) =>
                option.defaultChecked && (
                  // eslint-disable-next-line react/no-array-index-key
                  <div className="chip bg-info chip-sm" key={i}>
                    {option.closeFunc && (
                      <Button
                        onClick={e => {
                          option.closeFunc(option);
                          e.stopPropagation();
                        }}
                        icon={<FontAwesomeIcon icon={faTimes} />}
                      />
                    )}
                    <div className="chip-name">{option.label}</div>
                  </div>
                )
            )}
          </div>
          <span className="chevron-container">
            <FontAwesomeIcon icon={faChevronDown} />
          </span>
        </DropdownToggle>
        <DropdownMenu>
          {options.map(option => (
            <DropdownItem
              toggle={false}
              onClick={e => {
                if (option.label !== '') {
                  option.callbackFunc();
                  option.defaultChecked = true;
                  e.stopPropagation();
                }
                if (option.defaultChecked) {
                  option.closeFunc(option);
                }
              }}
            >
              <span
                className={classnames('custom-checkbox', {
                  checked: option.defaultChecked,
                })}
              >
                {option.defaultChecked && <FontAwesomeIcon icon={faCheck} />}
              </span>
              <span className="label">{option.label}</span>
            </DropdownItem>
          ))}
        </DropdownMenu>
      </Dropdown>
    </div>
  );
};

CheckboxDropdown.propTypes = {
  /** Array of options to include labels, default states and callbacks */
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      defaultChecked: PropTypes.bool,
      callbackFunc: PropTypes.func,
      closeFunc: PropTypes.func,
    })
  ),
  placeholder: PropTypes.string,
};

CheckboxDropdown.defaultProps = {
  options: [],
  placeholder: 'Select',
};

export default CheckboxDropdown;

/* eslint-enable no-cond-assign */
/* eslint-enable no-param-reassign */
