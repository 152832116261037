import React from 'react';
import Loadable from 'react-loadable';
import { Loading } from 'components';

export default Loadable({
  loader: () => import('./SettingsIndex'),
  loading: () => <Loading visible />,
});

export const CategorySettingsFormPage = Loadable({
  loader: () => import('./CategorySettingsFormPage'),
  loading: () => <Loading visible />,
});

export const SharingSettingsFormPage = Loadable({
  loader: () => import('./SharingSettingsFormPage'),
  loading: () => <Loading visible />,
});

export const LoginMethodsListPage = Loadable({
  loader: () => import('./LoginMethodsListPage'),
  loading: () => <Loading visible />,
});

export const LoginMethodsSettingsFormPage = Loadable({
  loader: () => import('./LoginMethodsSettingsFormPage'),
  loading: () => <Loading visible />,
});

export const AdSettingsFormPage = Loadable({
  loader: () => import('./AdSettingsFormPage'),
  loading: () => <Loading visible />,
});
