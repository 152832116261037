export const validateStartTime = (value, formValues) => {
  let error;
  const regEx = /^(0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/;
  if (formValues.publishAtDate && !value) {
    error = 'Please enter a Start Time.';
  } else if (!regEx.test(formValues.publishAtTime)) {
    error = 'Please use HH:MM format for the time';
  }
  return error;
};

export const validateEndTime = (value, formValues) => {
  let error;
  const regEx = /^(0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/;
  if (formValues.removeAtDate && !value) {
    error = 'Please enter an End Time.';
  } else if (!regEx.test(formValues.removeAtTime)) {
    error = 'Please use HH:MM format for the time.';
  }

  return error;
};
