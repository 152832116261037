import React, { PropsWithChildren, useEffect, useState } from 'react';
import classnames from 'classnames';
import GlobalSidebar, {
  GlobalSidebarLink,
} from '../GlobalSidebar/GlobalSidebar';
import GlobalTopbar from '../GlobalTopbar/GlobalTopbar';

import './GlobalAppWrapper.scss';

export interface GlobalAppWrapperProps {
  /** Logo image or element */
  logo: React.ReactNode;

  /** Links array containing all sidebar nav points */
  sidebarLinks?: Array<GlobalSidebarLink>;

  /** Space to put user, help dropdowns etc */
  topbarContentRight: React.ReactNode;
}

const GlobalAppWrapper: React.FC<
  PropsWithChildren<GlobalAppWrapperProps>
> = props => {
  const { children, logo, sidebarLinks = [], topbarContentRight } = props;

  const [menuOpen, setMenuOpen] = useState(false);

  const toggle = () => {
    setMenuOpen(!menuOpen);
    (document.activeElement as HTMLElement)?.blur();
  };

  const openSidebar = () => {
    setMenuOpen(true);
  };

  const closeSidebar = () => {
    setMenuOpen(false);
  };

  // Set sidebar open state to false so we don't get the overlay etc
  useEffect(() => {
    const handleResize = () => window.innerWidth > 1200 && setMenuOpen(false);
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  });

  return (
    <div
      className={classnames('global-app-wrapper', {
        'sidebar-open': menuOpen,
        'sidebar-closed': !menuOpen,
      })}
    >
      <GlobalTopbar
        contentRight={topbarContentRight}
        toggleNav={toggle}
        hamburgerClassName={classnames({
          'menu-open': menuOpen,
        })}
        logo={logo}
      />
      <GlobalSidebar
        isOpen={menuOpen}
        links={sidebarLinks}
        openSidebar={openSidebar}
        closeSidebar={closeSidebar}
      />
      {children}
    </div>
  );
};

export default GlobalAppWrapper;
