import $script from 'scriptjs';

function getConfig(container) {
  if (!getConfig.config && container.config) {
    getConfig.config = container.config;
  }

  return getConfig.config;
}

const handleConfigLoad =
  (container, get = getConfig, fallback = () => {}) =>
  () => {
    const config = get(container);
    if (config) {
      $script.done('config-loaded');
    } else {
      // eslint-disable-next-line no-console
      console.error('There was an error attempting to load config.');
      fallback();
    }
  };

export default start => {
  $script.ready('config', handleConfigLoad(global));
  $script.ready('config-loaded', () => {
    start();
  });

  const externals = ['/config.js'].filter(x => x);

  $script(externals, 'config');
};
