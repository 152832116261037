import React from 'react';
import Loadable from 'react-loadable';
import { Loading } from '@stormideas/react-sdk';

export default Loadable({
  loader: () => import('./TextSetsIndex'),
  loading: () => <Loading visible />,
});

export const List = Loadable({
  loader: () => import('./TextSetsList'),
  loading: () => <Loading visible />,
});

export const Form = Loadable({
  loader: () => import('./TextSetsFormPage'),
  loading: () => <Loading visible />,
});
