import React, { type PropsWithChildren } from 'react';
import classnames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/pro-light-svg-icons';
import { Button } from 'components';

import './Chip.scss';

interface ChipProps {
  buttonFunc?: () => void;
  className?: string;
  color?:
    | 'default'
    | 'primary'
    | 'secondary'
    | 'success'
    | 'danger'
    | 'warning'
    | 'info'
    | 'light'
    | 'dark'
    | 'black-tertiary';
  isDisabled?: boolean;
  size?: 'sm' | 'lg' | null;
}

const Chip: React.FC<PropsWithChildren<ChipProps>> = ({
  buttonFunc,
  children,
  className,
  color = 'info',
  isDisabled = false,
  size,
}) => (
  <span
    className={classnames('chip', className, `bg-${color}`, {
      'chip-sm': size === 'sm',
      'chip-lg': size === 'lg',
    })}
  >
    {buttonFunc && (
      <div className="chip-btn-wrapper">
        <Button
          icon={<FontAwesomeIcon icon={faTimes} />}
          onClick={buttonFunc}
          disabled={isDisabled}
        />
      </div>
    )}
    <div className="chip-name">{children}</div>
  </span>
);

export default Chip;
