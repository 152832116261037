const options: Intl.DateTimeFormatOptions = {
  month: 'short',
  day: 'numeric',
  hour: 'numeric',
  minute: 'numeric',
  hour12: true,
};

const formatXAxisDate = (value: string) => {
  const date = new Date(value);
  return date.toLocaleDateString('en-US', options);
};

export default formatXAxisDate;
