import React, { useMemo } from 'react';
import classnames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCheck,
  faChevronUp,
  faExclamation,
} from '@fortawesome/pro-solid-svg-icons';
import variables from 'scss/1-settings/colors.scss';
import { PieChart } from 'components/Charts';

export interface OverallProgressProps {
  hasFailedFiles: boolean;
  overallProgress: number;
  uploadsComplete: boolean;
}

const OverallProgress: React.FC<OverallProgressProps> = ({
  hasFailedFiles,
  overallProgress,
  uploadsComplete,
}) => {
  const collapseIcon = useMemo(() => {
    if (hasFailedFiles) return faExclamation;
    if (uploadsComplete) return faCheck;
    return faChevronUp;
  }, [hasFailedFiles, uploadsComplete]);

  return (
    <div className="collapse-icon-progress">
      <PieChart
        data={
          hasFailedFiles
            ? [{ value: 100, fill: variables.danger }]
            : [
                {
                  value: overallProgress,
                  fill: uploadsComplete ? variables.success : variables.primary,
                },
                {
                  value: 100 - overallProgress,
                  fill: 'rgba(255, 255, 255, 0.15)',
                },
              ]
        }
        startAngle={0}
        innerRadius={80}
        enableTooltip={false}
      />
      <div
        className={classnames('trigger-icon', {
          'no-rotate': hasFailedFiles || (uploadsComplete && !hasFailedFiles),
        })}
      >
        <FontAwesomeIcon fixedWidth icon={collapseIcon} />
      </div>
    </div>
  );
};

export default OverallProgress;
