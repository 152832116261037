const options: Intl.DateTimeFormatOptions = {
  month: 'short',
  day: 'numeric',
  weekday: 'long',
};

const formatTooltipDate = (value: string) => {
  const date = new Date(value);
  return date.toLocaleDateString('en-US', options);
};

export default formatTooltipDate;
