import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faTimesCircle } from '@fortawesome/pro-regular-svg-icons';

/**
 * A description of the component and when it should be used.
 */

export class RadioButtonField extends React.Component {
  componentDidMount() {
    const { defaultChecked, radioValue, onChange } = this.props;

    if (defaultChecked) {
      onChange(radioValue);
    }
  }

  onChange = () => {
    const { onChange, radioValue } = this.props;

    onChange(radioValue);
  };

  render() {
    const { disabled, className, name, label, radioValue, value } = this.props;
    const RadioButtonClass = classNames({
      'radio-btn': true,
      disabled,
    });

    return (
      <label
        className={`${RadioButtonClass} ${
          className ? `radio-btn--${className}` : ''
        }`}
        htmlFor={name}
      >
        <input
          className="radio-btn__radio-custom"
          name={name}
          type="radio"
          onChange={this.onChange}
          checked={value === radioValue}
          disabled={disabled}
        />
        {className === 'button' ? (
          <span className="radio-btn__label-svg">
            <FontAwesomeIcon
              icon={faCheck}
              className="radio-btn__label-check"
            />
            <FontAwesomeIcon
              icon={faTimesCircle}
              className="radio-btn__label-uncheck"
            />
          </span>
        ) : (
          ''
        )}
        <span className="radio-btn__label">{label}</span>
      </label>
    );
  }
}

RadioButtonField.propTypes = {
  onChange: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  label: PropTypes.string,
  defaultChecked: PropTypes.bool,
  radioValue: PropTypes.string,
  disabled: PropTypes.bool,
  className: PropTypes.string,
};

RadioButtonField.defaultProps = {
  label: '',
  defaultChecked: false,
  radioValue: '',
  disabled: false,
  className: '',
};

const renderRadioButtonField = props => {
  const { input, label, defaultChecked, disabled, radioValue, className } =
    props;
  return (
    <RadioButtonField
      {...input}
      label={label}
      defaultChecked={defaultChecked}
      disabled={disabled}
      radioValue={radioValue}
      className={className}
    />
  );
};

renderRadioButtonField.propTypes = {
  input: PropTypes.shape({
    onChange: PropTypes.func,
    name: PropTypes.string,
  }).isRequired,
  label: PropTypes.string,
  defaultChecked: PropTypes.bool,
  disabled: PropTypes.bool,
  radioValue: PropTypes.string,
  className: PropTypes.string,
};

renderRadioButtonField.defaultProps = {
  label: '',
  defaultChecked: false,
  disabled: false,
  radioValue: '',
  className: '',
};

export default renderRadioButtonField;
