/* eslint-disable react/jsx-filename-extension */
/* eslint-disable implicit-arrow-linebreak */

import React from 'react';
import {
  compose,
  branch,
  withHandlers,
  renderNothing,
  withState,
} from 'recompose';

export const loading = (predicate, component) =>
  branch(predicate, () => component);

export const withOnClick = withHandlers({
  onClick: props => (...args) => e => {
    e.preventDefault();
    props.onClick(...args);
  },
});

export const visibility = branch(props => !props.visible, renderNothing);

export const nothingIf = predicate => branch(predicate, renderNothing);

export const withTabs = compose(
  withState('tabs', 'setTabs', {}),
  withHandlers({
    setActiveTab: props => tab =>
      props.setTabs(tabs => ({
        ...tabs,
        [tab]: true,
        active: tab,
      })),
  })
);
