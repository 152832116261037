import React from 'react';
import './Activity.scss';
import PropTypes from 'prop-types';

/** For use in a feed of actions, for example changes to a story */

const Activity = props => {
  const { action, imgSrc, moreInfo, name, timestamp } = props;
  return (
    <div className="activity">
      {imgSrc && (
        <img src={imgSrc} alt={name} className="thumbnail rounded-pill" />
      )}
      <div className="details">
        {action && <small className="action">{action}:</small>}
        {name && <h4 className="name">{name}</h4>}
        {timestamp && <span className="timestamp">{timestamp}</span>}
        {moreInfo && <p className="more-info">{moreInfo}</p>}
      </div>
    </div>
  );
};

Activity.propTypes = {
  /** Action that the user took, eg. Updated a Story */
  action: PropTypes.string,
  /** Link to user image */
  imgSrc: PropTypes.string,
  /** Description of the action */
  moreInfo: PropTypes.node,
  /** User name */
  name: PropTypes.string,
  /** eg. "Yesterday at 4pm" */
  timestamp: PropTypes.string,
};

Activity.defaultProps = {
  action: null,
  imgSrc: null,
  moreInfo: null,
  name: null,
  timestamp: null,
};

export default Activity;
