import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const SwipeUpSelect = ({ field, canBeNone, isFieldInvalid }) => {
  const swipeUpTypes = useMemo(() => {
    const types = [
      {
        label: 'Web',
        value: 'web',
      },
      {
        label: 'In App',
        value: 'inApp',
      },
      {
        label: 'Store',
        value: 'store',
      },
      {
        label: 'External App',
        value: 'externalApp',
      },
    ];

    if (canBeNone) {
      types.unshift({
        label: 'None',
        value: 'none',
      });
    }

    return types;
  }, [canBeNone]);

  return (
    <select
      {...field}
      className={classNames({
        'form-control': true,
        'is-invalid': isFieldInvalid,
      })}
      required
    >
      <option disabled selected value="">
        Select&hellip;
      </option>
      {swipeUpTypes.map(type => (
        <option key={type.value} value={type.value}>
          {type.label}
        </option>
      ))}
    </select>
  );
};

SwipeUpSelect.propTypes = {
  canBeNone: PropTypes.bool,
  field: PropTypes.shape(),
  isFieldInvalid: PropTypes.bool,
};

SwipeUpSelect.defaultProps = {
  field: {},
  canBeNone: true,
  isFieldInvalid: false,
};

export default SwipeUpSelect;
