type ParamKeyValuePairs = Record<string, string>;

const setURLParams = (
  paramNames: ParamKeyValuePairs,
  keepHistory?: boolean
): string => {
  const url = new URL(window.location.href);
  const params = Object.entries(paramNames);
  params.forEach(([key, value]) => {
    url.searchParams.set(key, value);
  });
  const urlHasToChange = url.toString() !== window.location.href;
  if (urlHasToChange) {
    if (keepHistory) {
      window.history.pushState(null, '', url);
    } else {
      window.history.replaceState(null, '', url);
    }
  }
  return url.toString();
};

export default setURLParams;
