import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faFile,
  faFileImage,
  faFileVideo,
  faMinusCircle,
  faTrashAlt,
} from '@fortawesome/pro-solid-svg-icons';
import { Img, ProgressCircle } from '@stormideas/react-sdk';
import Button from 'components/Button';

import './FilePreview.scss';

const CustomOverlay = ({ removeFileFunc }) => (
  <div className="file-overlay">
    {removeFileFunc && (
      <Button
        color="light"
        icon={<FontAwesomeIcon color="#D24747" icon={faTrashAlt} />}
        onClick={removeFileFunc}
      />
    )}
  </div>
);

/** Describe the component */

const FilePreview = props => {
  const {
    autoPlayVideo,
    cropPreview,
    file,
    previewShape,
    removeFileFunc,
    showOverlay,
    isCustomDeleteOverlay,
  } = props;

  const previewContents = () => {
    switch (true) {
      case file &&
        file.type &&
        file.type.toLowerCase().startsWith('image') &&
        !!file.src:
        return (
          <Img
            alt={file.name}
            blurFill={!cropPreview}
            containerClassName="file-thumbnail"
            imageClassName={classnames({
              'crop-img': cropPreview,
            })}
            lightenBackground
            src={file.src}
            style={{
              transform: `rotate(${file.rotation}deg)`,
            }}
          />
        );
      case file &&
        file.type &&
        file.type.toLowerCase().startsWith('video') &&
        !!file.src:
        return (
          <video
            autoPlay={autoPlayVideo}
            className="poll-background"
            controls={autoPlayVideo}
            loop={autoPlayVideo}
            muted={autoPlayVideo}
            playsInline={autoPlayVideo}
            poster={file.poster}
            preload="auto"
          >
            <source src={file.src} type="video/mp4" />
          </video>
        );
      case file && file.type && file.type.toLowerCase().startsWith('image'):
        return (
          <FontAwesomeIcon icon={faFileImage} className="placeholder-icon" />
        );
      case file && file.type && file.type.toLowerCase().startsWith('video'):
        return (
          <FontAwesomeIcon icon={faFileVideo} className="placeholder-icon" />
        );
      default:
        return <FontAwesomeIcon icon={faFile} className="placeholder-icon" />;
    }
  };

  return (
    <div
      className={classnames('file-preview', {
        'preview-circle': previewShape === 'circle',
        'preview-square': previewShape === 'square',
        'preview-portrait': previewShape === 'portrait',
        'preview-landscape': previewShape === 'landscape',
        'preview-2x3': previewShape === '2x3',
        'preview-4x5': previewShape === '4x5',
        'icon-preview':
          file && file.type && !file.type.toLowerCase().startsWith('image'),
      })}
    >
      <div className="preview-contents">{previewContents()}</div>
      {file && file.uploadProgress > 0 && file.uploadProgress < 100 && (
        <div className="progress-circle">
          <ProgressCircle value={file.uploadProgress} stacked />
        </div>
      )}
      {showOverlay ? (
        <div>
          {isCustomDeleteOverlay ? (
            <CustomOverlay removeFileFunc={removeFileFunc} />
          ) : (
            <div className="file-overlay">
              <small className="file-name">{file.name}</small>
              {removeFileFunc && (
                <Button
                  className={classnames('remove', {
                    'rounded-circle': !cropPreview,
                  })}
                  color="danger"
                  icon={<FontAwesomeIcon icon={faMinusCircle} />}
                  onClick={removeFileFunc}
                  size="sm"
                >
                  {cropPreview && 'Remove'}
                </Button>
              )}
            </div>
          )}
        </div>
      ) : (
        removeFileFunc && (
          <Button
            className="remove btn-icon rounded-circle"
            color="danger"
            icon={<FontAwesomeIcon icon={faMinusCircle} />}
            onClick={removeFileFunc}
            size="sm"
          />
        )
      )}
    </div>
  );
};

FilePreview.propTypes = {
  autoPlayVideo: PropTypes.bool,
  cropPreview: PropTypes.bool,
  file: PropTypes.shape({
    name: PropTypes.string,
    poster: PropTypes.string,
    rotation: PropTypes.number,
    src: PropTypes.string,
    type: PropTypes.string,
    uploadProgress: PropTypes.number,
  }).isRequired,
  previewShape: PropTypes.oneOf([
    null,
    'circle',
    'square',
    'portrait',
    'landscape',
    '2x3',
    '4x5',
  ]),
  removeFileFunc: PropTypes.func,
  showOverlay: PropTypes.bool,
  isCustomDeleteOverlay: PropTypes.bool,
};

FilePreview.defaultProps = {
  autoPlayVideo: true,
  cropPreview: true,
  previewShape: null,
  removeFileFunc: null,
  showOverlay: true,
  isCustomDeleteOverlay: false,
};

export default FilePreview;
