import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { useDropzone } from 'react-dropzone';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowAltCircleDown } from '@fortawesome/pro-solid-svg-icons';
import { CSSTransition } from 'react-transition-group';
import { Loading } from '@stormideas/react-sdk';
import { FilePreview } from 'components';
import { faPencil } from '@fortawesome/pro-regular-svg-icons';
import Button from 'components/Button';

const DropZoneField = props => {
  const {
    accept,
    aspectRatio,
    autoPlayPreview,
    className,
    cropPreview,
    customFilePreview,
    disabled,
    removeFileCallback,
    dropToUploadMessage,
    dropZoneShape,
    editButtonProps,
    editButtonContents,
    emptyMessage,
    fieldProps,
    initialValue,
    maxSize,
    multiple,
    noClick,
    name,
    input,
    onDropRejected,
    previewShape,
    processing,
    showEditButton,
    showFileOverlay,
    showRemoveButton,
    isFileValid,
    isCustomFileTypeValidationOn,
    isCustomDeleteOverlay,
  } = props;

  const [files, setFiles] = useState(initialValue);
  const CustomFilePreview = customFilePreview;

  useEffect(() => {
    setFiles(initialValue);
  }, [initialValue]);

  const { getRootProps, getInputProps, isDragActive, open } = useDropzone({
    accept,
    disabled: disabled || processing,
    multiple,
    noClick,
    onDrop: acceptedFiles => {
      setFiles(
        multiple
          ? files.concat(
              acceptedFiles.map(file =>
                Object.assign(file, {
                  src: URL.createObjectURL(file),
                })
              )
            )
          : acceptedFiles.map(file =>
              Object.assign(file, {
                src: URL.createObjectURL(file),
              })
            )
      );
      input.onChange(acceptedFiles);
    },
    onDropRejected,
  });

  useEffect(
    () => () => {
      // Make sure to revoke the data uris to avoid memory leaks
      if (files) {
        files.forEach(file => URL.revokeObjectURL(file.preview));
      }
    },
    [files]
  );

  const removeFile = (event, file) => {
    const newFiles = [...files];
    newFiles.splice(file, 1);
    setFiles(newFiles);
    event.stopPropagation();
    document.activeElement.blur();

    if (removeFileCallback) {
      removeFileCallback(event, file);
    }
  };

  const defaultDropToUploadMessage = (
    <div className="drop-overlay">
      <FontAwesomeIcon icon={faArrowAltCircleDown} className="drop-icon" />
      Drop file{multiple && 's'} to upload.
    </div>
  );

  const aspectRatioNumber = parseFloat(aspectRatio);

  return (
    <>
      <div
        {...getRootProps({
          className: classnames('dropzone form-control', className, {
            disabled,
            'dropzone-circle': dropZoneShape === 'circle',
            'dropzone-square':
              dropZoneShape === 'square' || aspectRatioNumber === 1,
            'dropzone-portrait':
              dropZoneShape === 'portrait' || aspectRatioNumber > 1,
            'dropzone-landscape':
              dropZoneShape === 'landscape' || aspectRatioNumber < 1,
            'dropzone-landscape-modal': dropZoneShape === 'landscape-modal',
            'dropzone-2x3': dropZoneShape === '2x3',
            'dropzone-4x5': dropZoneShape === '4x5',
            'drag-active': isDragActive,
            'has-files':
              files &&
              files.length > 0 &&
              !(isCustomFileTypeValidationOn && !isFileValid),
            'no-click': noClick,
            'custom-aspect': aspectRatioNumber,
          }),
        })}
        style={
          aspectRatioNumber > 1 && aspectRatioNumber !== 1
            ? { height: 160 * aspectRatioNumber }
            : { width: 160 / aspectRatioNumber }
        }
        {...fieldProps}
      >
        <Loading visible={processing} maskContainer />
        <CSSTransition
          in={isDragActive && !processing}
          classNames="fade"
          timeout={200}
          unmountOnExit
        >
          {dropToUploadMessage || defaultDropToUploadMessage}
        </CSSTransition>
        <input
          {...getInputProps()}
          className="dropzone-input"
          id={name}
          maxsize={maxSize}
          multiple={multiple}
          name={name}
        />
        {(!files ||
          (files && Array.isArray(files) && files.length < 1) ||
          (isCustomFileTypeValidationOn && !isFileValid)) && (
          <CSSTransition
            in={!isDragActive && !processing}
            classNames="fade"
            timeout={200}
            unmountOnExit
          >
            <div className="dropzone-drop-here">{emptyMessage}</div>
          </CSSTransition>
        )}
        {files &&
          Array.isArray(files) &&
          files.length > 0 &&
          !(isCustomFileTypeValidationOn && !isFileValid) && (
            <div className="files-wrapper">
              {files.map((file, index) =>
                customFilePreview ? (
                  <CustomFilePreview
                    autoPlay={autoPlayPreview}
                    cropPreview={cropPreview}
                    file={file}
                    previewShape={previewShape}
                    removeFileFunc={
                      showRemoveButton
                        ? event => removeFile(event, index)
                        : null
                    }
                    showOverlay={showFileOverlay}
                  />
                ) : (
                  <FilePreview
                    key={file.src}
                    autoPlay={autoPlayPreview}
                    cropPreview={cropPreview}
                    file={file}
                    previewShape={previewShape}
                    removeFileFunc={
                      showRemoveButton
                        ? event => removeFile(event, index)
                        : null
                    }
                    showOverlay={showFileOverlay}
                    isCustomDeleteOverlay={isCustomDeleteOverlay}
                  />
                )
              )}
            </div>
          )}
      </div>
      {showEditButton && (
        <Button
          className="open-file-picker"
          color="info"
          disabled={processing}
          icon={<FontAwesomeIcon icon={faPencil} />}
          onClick={open}
          size="sm"
          {...editButtonProps}
        >
          {editButtonContents}
        </Button>
      )}
    </>
  );
};

DropZoneField.propTypes = {
  accept: PropTypes.arrayOf(PropTypes.string),
  aspectRatio: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  autoPlayPreview: PropTypes.bool,
  className: PropTypes.string,
  cropPreview: PropTypes.bool,
  customFilePreview: PropTypes.node,
  removeFileCallback: PropTypes.func,
  disabled: PropTypes.bool,
  dropToUploadMessage: PropTypes.node,
  dropZoneShape: PropTypes.oneOf([
    null,
    'circle',
    'square',
    'portrait',
    'landscape',
    '2x3',
    '4x5',
  ]),
  editButtonProps: PropTypes.shape(),
  editButtonContents: PropTypes.node,
  emptyMessage: PropTypes.node.isRequired,
  fieldProps: PropTypes.shape({}),
  initialValue: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      poster: PropTypes.string,
      rotation: PropTypes.number,
      src: PropTypes.string,
      type: PropTypes.string,
    })
  ),
  input: PropTypes.shape({
    onChange: PropTypes.func,
  }),
  maxSize: PropTypes.number,
  multiple: PropTypes.bool,
  name: PropTypes.string.isRequired,
  noClick: PropTypes.bool,
  onDropRejected: PropTypes.func,
  previewShape: PropTypes.oneOf([
    null,
    'circle',
    'square',
    'portrait',
    'landscape',
    '2x3',
  ]),
  processing: PropTypes.bool,
  showEditButton: PropTypes.bool,
  showFileOverlay: PropTypes.bool,
  showRemoveButton: PropTypes.bool,
  isFileValid: PropTypes.bool,
  isCustomFileTypeValidationOn: PropTypes.bool,
};

DropZoneField.defaultProps = {
  accept: [
    'image/jpg',
    'image/jpeg',
    'image/png',
    'video/mp4',
    'video/quicktime',
  ],
  aspectRatio: null,
  autoPlayPreview: false,
  className: '',
  cropPreview: true,
  customFilePreview: null,
  disabled: false,
  dropToUploadMessage: null,
  dropZoneShape: null,
  editButtonProps: null,
  editButtonContents: 'Edit',
  fieldProps: null,
  initialValue: [],
  input: {
    onChange: () => {},
  },
  maxSize: 64000000,
  multiple: false,
  noClick: false,
  onDropRejected: null,
  previewShape: null,
  processing: false,
  removeFileCallback: null,
  showEditButton: false,
  showFileOverlay: true,
  showRemoveButton: true,
  isFileValid: false,
  isCustomFileTypeValidationOn: false,
};

const renderDropZoneField = props => <DropZoneField {...props} />;

export default renderDropZoneField;
