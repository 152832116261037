import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimesCircle } from '@fortawesome/pro-solid-svg-icons';
import variables from 'scss/1-settings/colors.scss';
import { ClearIndicatorProps, GroupBase } from 'react-select';
import type { SelectFieldOptionObject } from '../SelectField/types';

const SelectFieldCustomClearIndicator = <
  Option extends SelectFieldOptionObject,
  IsMulti extends boolean = false,
  Group extends GroupBase<Option> = GroupBase<Option>
>({
  innerProps,
}: ClearIndicatorProps<Option, IsMulti, Group>) => (
  <div style={{ fontSize: 16, zIndex: 1 }}>
    <FontAwesomeIcon
      icon={faTimesCircle}
      color={variables['gray-400']}
      {...innerProps}
    />
  </div>
);

export default SelectFieldCustomClearIndicator;
