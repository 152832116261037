import { ACCESS_TOKEN } from 'shared/constants/security';

const EXPIRY_BUFFER_FACTOR = 0.25;

export const calculateExpiryTime = expires_in => {
  const currentTime = new Date();
  const expiryTime = new Date(currentTime.getTime() + expires_in * 1000);
  const expiryTimeWithBuffer = new Date(
    expiryTime.getTime() - expires_in * EXPIRY_BUFFER_FACTOR * 1000
  );
  return expiryTimeWithBuffer;
};

export const getAccessToken = () => {
  try {
    const accessTokenData = JSON.parse(localStorage.getItem(ACCESS_TOKEN));
    if (accessTokenData) return accessTokenData.access_token;
  } catch (error) {
    localStorage.removeItem(ACCESS_TOKEN);
  }
  return undefined;
};

export const getAccessTokenExpiryTime = () => {
  try {
    const accessTokenData = JSON.parse(localStorage.getItem(ACCESS_TOKEN));
    if (accessTokenData) return accessTokenData.expires_at;
  } catch (error) {
    localStorage.removeItem(ACCESS_TOKEN);
  }
  return undefined;
};

export const setAccessToken = token =>
  localStorage.setItem(ACCESS_TOKEN, token);

export const removeAccessToken = () => localStorage.removeItem(ACCESS_TOKEN);
