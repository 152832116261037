import React from 'react';
import Loadable from 'react-loadable';
import { Loading } from 'components';

export default Loadable({
  loader: () => import('./index'),
  loading: () => <Loading visible />,
});

export const Analytics = Loadable({
  loader: () => import('./containers/AnalyticsPageV2'),
  loading: () => <Loading visible />,
});
