import React, { createContext, PropsWithChildren, useMemo } from 'react';
import axios, { AxiosError, AxiosInstance } from 'axios';
import { useConfig } from 'infrastructure/config';
import history from 'infrastructure/history';
import {
  calculateExpiryTime,
  getAccessToken,
  getAccessTokenExpiryTime,
  removeAccessToken,
  setAccessToken,
} from 'infrastructure/security/accessTokenManager';
const handleLogout = () => {
  removeAccessToken();
  history.push('/login');
};

export const AxiosContext = createContext({} as AxiosInstance);

const AxiosContextProvider = ({ children }: PropsWithChildren<{}>) => {
  const config = useConfig();

  const axiosInstance = useMemo(() => {
    const instance = axios.create();

    instance.interceptors.response.use(
      response => {
        const wasRefreshRequest =
          response.config.url?.includes('/auth/refresh');
        const wasLoginLogoutRequest =
          response.config.url?.includes('/auth/login') ||
          response.config.url?.includes('/auth/logout');
        const bearerToken = getAccessToken();
        const isTokenExpring =
          new Date(getAccessTokenExpiryTime()) < new Date();

        if (
          !wasRefreshRequest &&
          !wasLoginLogoutRequest &&
          bearerToken &&
          isTokenExpring
        ) {
          axios
            .post(
              new URL('/api/auth/refresh', config?.apiUrl).href,
              {},
              {
                headers: {
                  Authorization: `Bearer ${bearerToken}`,
                  'Content-type': 'application/json; charset=UTF-8',
                },
              }
            )
            .then(tokenRefreshResponse => {
              const expiryTimeWithBuffer = calculateExpiryTime(
                tokenRefreshResponse.data.expires_in
              );

              setAccessToken(
                JSON.stringify({
                  ...tokenRefreshResponse.data,
                  expires_at: expiryTimeWithBuffer,
                })
              );
            });
        }
        return response;
      },
      (error: AxiosError) => Promise.reject(error)
    );

    instance.interceptors.request.use(request => {
      const localStorageToken = getAccessToken();
      const token = localStorageToken;

      request.headers.Authorization = `Bearer ${token}`;

      return request;
    });

    instance.interceptors.response.use(
      response => response,
      error => {
        if (error?.response?.status === 401) {
          const finalURL = new URL('/api/auth/logout', config?.apiUrl).href;
          instance.get(finalURL);
          handleLogout();
        }
        return Promise.reject(error);
      }
    );

    return instance;
  }, [config]);

  return (
    <AxiosContext.Provider value={axiosInstance}>
      {children}
    </AxiosContext.Provider>
  );
};

export default AxiosContextProvider;
