import { SortedColumn } from 'components/v2/DataTable/DataTable';
import urlParams from 'components/v2/DataTable/data/urlParams';
import getSortingDirection from 'components/v2/DataTable/helpers/getSortingDirection';

const setURLSortingParams = (sortingParams: SortedColumn): void => {
  const url = new URL(window.location.href);
  const { id, desc } = sortingParams;
  url.searchParams.set(urlParams.sortId, `${id}`);
  url.searchParams.set(urlParams.sorting, `${getSortingDirection(desc)}`);
  const urlHasToChange = url.toString() !== window.location.href;
  if (urlHasToChange) window.history.replaceState(null, '', url);
};

export default setURLSortingParams;
