import React, { useState } from 'react';
import Popover from 'components/v2/Popover/Popover';
import { ChromePicker } from 'react-color';

export interface ColorInputFieldProps {
  fieldName: string;
  onColorSelected: (colorValue: string) => void;
  color?: string;
  defaultColor?: string;
}

const ColorInputField: React.FC<ColorInputFieldProps> = ({
  fieldName,
  onColorSelected,
  color,
  defaultColor = '#FFFFFF',
}) => {
  const [sketchPickerColor, setSketchPickerColor] =
    useState<string>(defaultColor);

  const currentColor = color || sketchPickerColor;

  return (
    <div className="color-picker">
      <Popover
        buttonClassName="btn color-picker__button"
        id={fieldName}
        popoverTriggerMethod="legacy"
        trigger={
          <>
            <span className="color-picker__color">{currentColor}</span>
            <div
              className="color-picker__color-view"
              style={{ backgroundColor: currentColor }}
            />
          </>
        }
      >
        <ChromePicker
          color={sketchPickerColor}
          onChange={selectedColor => {
            setSketchPickerColor(selectedColor.hex);
          }}
          onChangeComplete={selectedColor => {
            onColorSelected(selectedColor.hex);
          }}
          disableAlpha
        />
      </Popover>
    </div>
  );
};

export default ColorInputField;
