import React from 'react';
import { Route, Switch } from 'react-router-dom';

import { AnalyticsPage } from './Loadable';

export default () => (
  <Switch>
    <Route path="/clips-analytics">
      <AnalyticsPage />
    </Route>
  </Switch>
);
