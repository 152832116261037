/**
 * POST Returns Login Methods
 * @returns {`/loginMethods`}
 */
export const loginMethodsEndpoint = `/loginMethods`;

/**
 * POST Login
 * @returns {`/login`}
 */
export const loginEndpoint = `/login`;

/**
 * POST External Provider Login
 * @returns {`/externalProviderLogin`}
 */
export const externalProviderLoginEndpoint = `/externalProviderLogin`;

/**
 * POST Send Password Reset Email
 * @returns {`/sendPasswordResetEmail`}
 */
export const sendPasswordResetEmailEndpoint = `/sendPasswordResetEmail`;

/**
 * POST Reset Password
 * @returns {`/resetPassword`}
 */
export const resetPasswordEndpoint = `/resetPassword`;

/**
 * GET Logout
 * @returns {`/logout`}
 */
export const logoutEndpoint = `/logout`;
