import React, { ReactElement } from 'react';
import cx from 'classnames';
import {
  Card,
  CardBody,
  CardHeader,
  CardSubtitle,
  CardTitle,
} from 'reactstrap';

import './Panel.scss';
import { PanelBackgroundsType, TopBorderType } from './PanelTypes';

export interface PanelProps {
  /** Bootstrap colors for the main background, usually the same as headerBackground */
  backgroundColor?: PanelBackgroundsType;
  /** Main content */
  children?: ReactElement | string;
  /** Custom classes */
  className?: string;
  /** Background color just for the header, usually the same as backgroundColor */
  headerBackground?: PanelBackgroundsType;
  /** Use a gradient for the header background */
  headerGradient?: boolean;
  /** Icon to display before the title */
  icon?: ReactElement;
  /** An image instead of the footer */
  imgBottom?: ReactElement;
  /** An image instead of the header */
  imgTop?: ReactElement;
  /** onClick handler for the whole card */
  onClick?: () => void;
  /** Use an image as the background, requires a top or bottom image */
  overlayContent?: boolean;
  /** Subtitle in the header */
  subtitle?: string;
  /** Tag for the card, defaults to div but could be a button if using a click handler */
  tag?: any;
  /** Card title */
  title?: string | ReactElement;
  /** Buttons in the top right */
  titleRight?: ReactElement;
  /** A highlight color for the top border */
  topBorder?: TopBorderType;
}

/** A card or panel containing content on the page,
 * primarily for providing some visual structure. */

const Panel: React.FC<PanelProps> = ({
  backgroundColor,
  titleRight,
  children,
  className,
  headerBackground,
  headerGradient = false,
  icon,
  imgBottom,
  imgTop,
  onClick,
  overlayContent = false,
  subtitle,
  tag = 'div',
  title,
  topBorder,
}) => {
  const content = (
    <>
      {(title || subtitle) && (
        <CardHeader
          className={cx({
            [`bg-${headerBackground}`]: headerBackground,
            gradient: headerGradient,
          })}
        >
          <div>
            {(title || icon) && (
              <CardTitle
                tag="h2"
                className={cx('h5', { 'bg-solid': headerBackground })}
              >
                {icon && <span className="card-icon">{icon}</span>}
                {title}
              </CardTitle>
            )}
            {subtitle && <CardSubtitle tag="h6">{subtitle}</CardSubtitle>}
          </div>
          {titleRight && <div className="title-right">{titleRight}</div>}
        </CardHeader>
      )}
      <CardBody>{children}</CardBody>
    </>
  );

  return (
    <Card
      className={cx('panel', className, {
        [`bg-${backgroundColor}`]: backgroundColor,
        [`border-${topBorder}`]: topBorder,
        'top-border': topBorder,
        clip: overlayContent,
      })}
      onClick={onClick}
      tag={tag}
    >
      {imgTop}
      {overlayContent ? (
        <div
          className={cx({
            'card-img-overlay': overlayContent && (imgBottom || imgTop),
          })}
        >
          {content}
        </div>
      ) : (
        content
      )}
      {imgBottom}
    </Card>
  );
};

export default Panel;
