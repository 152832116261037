import { ChartDataPoint } from 'components/Charts/types';
import {
  SinglePollAnswerStatType,
  SinglePollStatsType,
  SingleTriviaQuizStatsType,
} from 'slices/Story/types';

export const toReadablePercentage = (
  dividend?: number,
  divisor?: number,
  digitsAfterComma: number = 1
) =>
  dividend && divisor
    ? Number(((dividend / divisor) * 100).toFixed(digitsAfterComma))
    : 0;

export interface PollStatsUseReady {
  answersStats: {
    name: string;
    value: number;
    imageUrl: string;
  }[];
  viewsTotal: number;
  votesTotal: number;
  votesPercentage: number;
  sharesTotal: number;
  questionTitle: string;
  sharesPercantage: number;
  sharesChartData: ChartDataPoint[];
  viewsAndVotesChartData: ChartDataPoint[];
  hasStats: boolean;
}

export const getPollStats = (stats: SinglePollStatsType): PollStatsUseReady => {
  const { answersStats } = stats;
  const viewsAndVotesAllData = stats.charts.multiLinesCharts[0];
  const sharesAllData = stats.charts.singleLineCharts[0];
  const viewsAndVotesChartData = viewsAndVotesAllData.data;
  const viewsAllData = viewsAndVotesAllData.totals.find(
    (singleData: { name: string; total: number; totalDisplay: string }) =>
      singleData.name === 'Views'
  );
  const votesAllTotal = viewsAndVotesAllData.totals.find(
    (singleData: { name: string; total: number; totalDisplay: string }) =>
      singleData.name === 'Votes'
  );

  const pieChartReadyStats = answersStats.map(
    (singleAnswerStat: SinglePollAnswerStatType) => ({
      name: singleAnswerStat.title,
      value: singleAnswerStat.total,
      imageUrl: singleAnswerStat.imageUrl,
    })
  );

  return {
    answersStats: pieChartReadyStats,
    viewsTotal: viewsAllData?.total || 0,
    votesTotal: votesAllTotal?.total || 0,
    votesPercentage: toReadablePercentage(
      votesAllTotal?.total,
      viewsAllData?.total
    ),
    sharesTotal: sharesAllData.total,
    sharesPercantage: toReadablePercentage(
      sharesAllData.total,
      viewsAllData?.total
    ),
    sharesChartData: sharesAllData.data,
    viewsAndVotesChartData,
    hasStats:
      (viewsAllData?.total || 0) +
        (votesAllTotal?.total || 0) +
        (sharesAllData.total || 0) >
      0,
    questionTitle: stats.question,
  };
};

export interface TriviaQuizStatsUseReady {
  startsTotal: number;
  completionsTotal: number;
  completionsPercentage: number;
  startsAndCompletionsChartData: ChartDataPoint[];
  sharesTotal: number;
  sharesPercentage: number;
  averageScore: string;
  sharesChartData: ChartDataPoint[];
  title: string;
}

export const getTriviaQuizStats = (
  triviaQuiz: SingleTriviaQuizStatsType
): TriviaQuizStatsUseReady => ({
  startsTotal: triviaQuiz.starts,
  completionsTotal: triviaQuiz.completions,
  completionsPercentage: toReadablePercentage(
    triviaQuiz.completions,
    triviaQuiz.starts
  ),
  startsAndCompletionsChartData: triviaQuiz.charts.multiLinesCharts[0].data,
  sharesTotal: triviaQuiz.shares,
  sharesPercentage: toReadablePercentage(triviaQuiz.shares, triviaQuiz.starts),
  averageScore: triviaQuiz.averageScore,
  sharesChartData: triviaQuiz.charts.singleLineCharts[0].data,
  title: triviaQuiz.title,
});
