import React from 'react';
import Loadable from 'react-loadable';
import { Loading } from 'components';

export default Loadable({
  loader: () => import('./AccountIndex'),
  loading: () => <Loading visible />,
});

export const Form = Loadable({
  loader: () => import('./AccountFormPage'),
  loading: () => <Loading visible />,
});

export const ForgotPassword = Loadable({
  loader: () => import('./ForgotPasswordPage'),
  loading: () => <Loading visible />,
});

export const ResetPassword = Loadable({
  loader: () => import('./ResetPasswordPage'),
  loading: () => <Loading visible />,
});

export const PasswordResetConfirmation = Loadable({
  loader: () => import('./PasswordResetConfirmationPage'),
  loading: () => <Loading visible />,
});
