import React from 'react';
import ErrorMessage from './components/ErrorMessage';

const ErrorPage = () => (
  <div className="theme-lightondark">
    <div className="wrapper">
      <div className="account">
        <div className="account__wrapper">
          <ErrorMessage canRetry />
        </div>
      </div>
    </div>
  </div>
);

export default ErrorPage;
