import React from 'react';
import { Loading } from 'components';
import Loadable from 'react-loadable';

export default Loadable({
  loader: () => import('./ClipsIndex'),
  loading: () => <Loading visible />,
});

export const List = Loadable({
  loader: () => import('./ClipsList'),
  loading: () => <Loading visible />,
});

export const Form = Loadable({
  loader: () => import('./ClipsFormPage'),
  loading: () => <Loading visible />,
});

export const Analytics = Loadable({
  loader: () => import('./ClipsAnalyticsPage'),
  loading: () => <Loading visible />,
});

export const Captions = Loadable({
  loader: () => import('./ClipsCaptionsPage'),
  loading: () => <Loading visible />,
});
