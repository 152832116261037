import { useMutation } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { logoutEndpoint } from 'infrastructure/api/endpoints/auth';
import useApi from 'infrastructure/api/useApi';
import useCurrentUser from 'infrastructure/currentUser/useCurrentUser';
import { removeAccessToken } from 'infrastructure/security/accessTokenManager';
import { useHistory } from 'react-router';

const useLogout = ({ onError }: { onError?: (error: AxiosError) => void }) => {
  const history = useHistory();
  const { requestApi } = useApi();
  const { triggerManualLogout } = useCurrentUser();

  return useMutation(
    () =>
      requestApi(logoutEndpoint, {
        method: 'GET',
        resource: 'auth',
      }),
    {
      onError,
      onSuccess: () => {
        removeAccessToken();
        triggerManualLogout();
        const currentPath = history.location.pathname;
        history.push(
          currentPath ? `/login?returnUrl=${currentPath}` : '/login'
        );
      },
    }
  );
};

export default useLogout;
