import React, { PropsWithChildren, useCallback } from 'react';
import classnames from 'classnames';
import {
  Modal as ModalContainer,
  ModalBody,
  ModalFooter,
  ModalHeader,
  ModalProps,
} from 'reactstrap';
import { Button, Loading } from 'components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/pro-light-svg-icons';

import './Modal.scss';

export interface Props extends Omit<ModalProps, 'title' | 'toggle'> {
  /** Modal container class */
  className?: string;
  /** Border color for confirmation modal */
  color?:
    | 'primary'
    | 'secondary'
    | 'success'
    | 'danger'
    | 'warning'
    | 'info'
    | 'light'
    | 'dark';
  /** Footer contents and actions */
  footer?: JSX.Element;
  /** Show close button? */
  hideCloseButton?: boolean;
  /** Custom onClosed function */
  onClosed?: () => void;
  /** Custom onEnter function */
  onEnter?: () => void;
  /** Custom onExit function */
  onExit?: () => void;
  /** Custom onOpened function */
  onOpened?: () => void;
  /** Modal size */
  size?: 'auto' | 'sm' | 'lg';
  /** Show or hide the modal */
  isOpen?: boolean;
  /** Show a spinner instead. Overrides any modal content */
  isLoading?: boolean;
  /** Header node, will be in a `<h5 />` tag so avoid using tags apart from <span /> */
  title?: JSX.Element | string;
  /** Custom toggle function */
  handleClose?: () => void;
}

/** A wrapper for the reactstrap modal component ensuring more consistent styling */
const Modal: React.FC<PropsWithChildren<Props>> = ({
  children,
  className,
  color,
  footer,
  hideCloseButton = false,
  onClosed,
  onEnter,
  onExit,
  onOpened,
  size,
  isOpen,
  isLoading,
  title,
  handleClose,
}) => {
  const onToggle = useCallback(() => {
    if (isOpen) {
      handleClose?.();
    }
    (document?.activeElement as HTMLButtonElement)?.blur();
  }, [isOpen, handleClose]);

  return (
    <ModalContainer
      className={classnames(className, color)}
      isOpen={isOpen}
      size={size}
      toggle={onToggle}
      onClosed={onClosed}
      onEnter={onEnter}
      onExit={onExit}
      onOpened={onOpened}
      autoFocus={false}
    >
      {!hideCloseButton && (
        <Button
          title="Close"
          className="close-btn rounded-pill"
          color="light"
          data-testid="closeModalBtn"
          onClick={onToggle}
          type="button"
          icon={<FontAwesomeIcon icon={faTimes} color="#444647" />}
        />
      )}
      {isLoading ? (
        <Loading visible minHeight={240} />
      ) : (
        <>
          {title && (
            <ModalHeader tag="div" data-testid="modalHeader">
              {title}
            </ModalHeader>
          )}
          {children && <ModalBody>{children}</ModalBody>}
          {footer && <ModalFooter>{footer}</ModalFooter>}
        </>
      )}
    </ModalContainer>
  );
};

export default Modal;
