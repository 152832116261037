import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import './DefinitionList.scss';

/** Wrapper for HTML definition list: `<dl />` */

const DefinitionList = props => {
  const { tableLayout, terms } = props;
  return (
    <dl className={classnames({ 'definition-table': tableLayout })}>
      {terms
        .filter(x => x)
        .map((term, i) => (
          // eslint-disable-next-line react/no-array-index-key
          <div key={term.key || i}>
            <dt>{term.name}: </dt>
            <dd>{term.definition}</dd>
          </div>
        ))}
    </dl>
  );
};

DefinitionList.propTypes = {
  /** Make the definitions line up along the left */
  tableLayout: PropTypes.bool,
  /** An Array of terms */
  terms: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      /** `<dt />` Term */
      name: PropTypes.string,
      /** `<dd />` Definition */
      definition: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
    })
  ),
};

DefinitionList.defaultProps = {
  tableLayout: false,
  terms: [],
};

export default DefinitionList;
