import { buildChartTheme } from '@visx/xychart';
import { ThemeConfig } from '@visx/xychart/lib/theme/buildChartTheme';
import variables from 'scss/1-settings/colors.scss';

const buildTheme = (theme?: Partial<ThemeConfig>) =>
  buildChartTheme({
    backgroundColor: 'white',
    colors: ['rgba(255,231,143,0.8)', '#6a097d', '#d6e0f0'],
    gridColor: variables['gray-300'],
    gridColorDark: '#1d1b38',
    tickLength: 4,
    gridStyles: { stroke: variables['gray-300'] },
    xAxisLineStyles: { stroke: variables['gray-300'], strokeWidth: 1 },
    xTickLineStyles: { stroke: variables['gray-300'], strokeWidth: 1 },
    ...theme,
  });

export default buildTheme;
