import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faLongArrowUp,
  faLongArrowDown,
} from '@fortawesome/pro-solid-svg-icons';
import classNames from 'classnames';

import './DataTableHeader.scss';

/**
 * Renders arrows according to current column state
 * @param column - headerGroup column object from react-table
 * @returns {string|null}
 */
const renderSortIndicator = column => {
  const { isSorted, isSortedDesc } = column;
  if (isSorted && isSortedDesc) {
    return <FontAwesomeIcon icon={faLongArrowDown} size="xs" />;
  }
  if (isSorted && !isSortedDesc) {
    return <FontAwesomeIcon icon={faLongArrowUp} size="xs" />;
  }

  return (
    <span className="sort-icons">
      <FontAwesomeIcon size="xs" icon={faLongArrowUp} />
      <FontAwesomeIcon size="xs" icon={faLongArrowDown} />
    </span>
  );
};

const DataTableHeader = ({ tableInstance, tableId }) => {
  const { headerGroups } = tableInstance;

  return (
    <thead className="dataTableHeader">
      {headerGroups.map(headerGroup => (
        <tr {...headerGroup.getHeaderGroupProps()}>
          {headerGroup.headers.map(column => (
            <th
              {...column.getHeaderProps(column.getSortByToggleProps())}
              title={
                column.canSort && column.header
                  ? `Sort By ${column.header}`
                  : ''
              }
              data-testid={`${tableId}__th_${column.id}`}
              className={classNames(
                `${tableId}_${column.id}_header`,
                {
                  sorted: column.isSorted,
                  actions: column.id === 'action',
                  'flex-grow-0': column.dontGrow,
                },
                column.className
              )}
            >
              {column.render('header')}
              {column.canSort && (
                <span className="arrow">{renderSortIndicator(column)}</span>
              )}
            </th>
          ))}
        </tr>
      ))}
    </thead>
  );
};

DataTableHeader.propTypes = {
  tableId: PropTypes.string.isRequired,
  tableInstance: PropTypes.shape({
    headerGroups: PropTypes.arrayOf(PropTypes.shape({})),
  }).isRequired,
};

export default DataTableHeader;
