import { useQuery } from '@tanstack/react-query';
import { currentUserEndpoint } from 'infrastructure/api/endpoints';
import useApi from 'infrastructure/api/useApi';
import { userInfoKeys } from './keys';
import type { UserInfo } from './UserContext';

const useGetUserInfo = () => {
  const { requestApi } = useApi();

  return useQuery(
    userInfoKeys.base,
    (): Promise<UserInfo> =>
      requestApi(currentUserEndpoint, { method: 'GET' }).then(
        ({ data }) => data
      ),
    {
      // handle API returning 204 No Content
      // Axios returns empty string in case of 204
      select: (data: UserInfo) =>
        typeof data === 'string' && (data as any).length === 0
          ? undefined
          : data,
    }
  );
};

export default useGetUserInfo;
