import React, { PropsWithChildren, useState } from 'react';
import cx from 'classnames';
import {
  Tooltip as ReactstrapTooltip,
  TooltipProps as ReactstrapTooltipProps,
} from 'reactstrap';

import './Tooltip.scss';

/**
 * A wrapper that can be used to add a tooltip to an element.
 * Added so we don't need to deal with state every time we use a Tooltip.
 */

export interface TooltipProps {
  containerClassName?: string;
  id: string;
  inModal?: boolean;
  placement?: ReactstrapTooltipProps['placement'];
  tooltipContents: JSX.Element | string;
}

const Tooltip = ({
  containerClassName = '',
  id,
  inModal,
  placement = 'top',
  tooltipContents,
  children,
}: PropsWithChildren<TooltipProps>) => {
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const toggle = () => {
    setTooltipOpen(!tooltipOpen);
  };

  return (
    <>
      <span id={id} className={cx('tooltip-container', { containerClassName })}>
        {children}
      </span>
      <ReactstrapTooltip
        placement={placement}
        isOpen={tooltipOpen}
        target={id}
        autohide={false}
        toggle={toggle}
        fade={false}
        className={cx({ 'tooltip-in-modal': inModal })}
      >
        {tooltipContents}
      </ReactstrapTooltip>
    </>
  );
};

export default Tooltip;
