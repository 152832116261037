import React from 'react';
import { Loading } from 'components';
import Loadable from 'react-loadable';

export default Loadable({
  loader: () => import('./ContentSharingConfigIndex'),
  loading: () => <Loading visible />,
});

export const List = Loadable({
  loader: () => import('./containers/ContentSharingConfigListPage'),
  loading: () => <Loading visible />,
});

export const Form = Loadable({
  loader: () => import('./containers/ContentSharingConfigFormPage'),
  loading: () => <Loading visible />,
});
