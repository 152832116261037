import React, { ChangeEvent, KeyboardEvent, useCallback, useRef } from 'react';
import { TableInstance } from 'react-table';
import { SelectFieldv2 } from 'components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faArrowRight } from '@fortawesome/pro-regular-svg-icons';

import './DataTablePagination.scss';

interface PaginationProps<Data extends object> {
  tableId: string;
  tableInstance: TableInstance<Data>;
  displayItemsNumberOptions: number[];
  itemName: string;
  hideDisplayedElementsDropdown?: boolean;
  hasCustomLastBodyItem?: boolean;
}

const DataTablePagination = <Data extends object>({
  tableId,
  tableInstance,
  displayItemsNumberOptions,
  itemName,
  hideDisplayedElementsDropdown = false,
  hasCustomLastBodyItem,
}: PaginationProps<Data>) => {
  const {
    canPreviousPage,
    canNextPage,
    pageOptions,
    gotoPage: goToPageIndex,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize },
  } = tableInstance;
  const goToPageInputRef = useRef<HTMLInputElement>(null);
  const nextPageClickHandler = useCallback(
    event => {
      nextPage();
      (event.target as HTMLButtonElement).blur();
    },
    [nextPage]
  );

  const previousPageClickHandler = useCallback(
    event => {
      previousPage();
      (event.target as HTMLButtonElement).blur();
    },
    [previousPage]
  );

  const setPageSizeSelectHandler = useCallback(
    tableSize => {
      setPageSize(Number(tableSize?.value));
    },
    [setPageSize]
  );

  const gotoPageInputHandler = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const element = event.target as HTMLInputElement;
      const pageNumber = Number(element.value) || 0;
      const maximumPageNumber = pageOptions.length;
      const selectedPageNumberExceeded = pageNumber > maximumPageNumber;
      const goToPageInput = goToPageInputRef.current;
      if (goToPageInput) {
        if (selectedPageNumberExceeded) {
          goToPageInput.value = String(maximumPageNumber);
        }
      }
    },
    [pageOptions.length]
  );

  const onBlurHandler = useCallback(
    (
      event: ChangeEvent<HTMLInputElement> | KeyboardEvent<HTMLInputElement>
    ) => {
      const element = event.target as HTMLInputElement;
      const pageNumber = Number(element.value) || 0;
      const isPageNumberNegative = pageNumber <= 0;
      if (isPageNumberNegative) {
        element.value = String(1); // Assign minimal correct value to input
        goToPageIndex(0); // Go to first available page
      } else {
        goToPageIndex(pageNumber - 1); // If page number is correct, go to selected page
      }
    },
    [goToPageIndex]
  );

  const handleKeyDown = useCallback(
    (event: KeyboardEvent<HTMLInputElement>) => {
      if (event.key === 'Enter') {
        onBlurHandler(event);
      }
    },
    [onBlurHandler]
  );

  const handleOnInput = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      event.target.value = event.target.value.replace(/[^0-9]+/g, '');
    },
    []
  );

  return (
    <div className="pagination" data-testid={`${tableId}__paginationContainer`}>
      <div className="choose-page">
        <span>Page </span>
        <input
          className="form-control page-jump"
          type="number"
          data-testid={`${tableId}__goToPageInput`}
          defaultValue={pageIndex + 1}
          key={pageIndex + 1}
          onChange={gotoPageInputHandler}
          onBlur={onBlurHandler}
          min={1}
          max={pageOptions.length}
          ref={goToPageInputRef}
          onKeyDown={handleKeyDown}
          onInput={handleOnInput}
          onFocus={e => e.target.select()}
        />
        <span data-testid={`${tableId}__pageNumeration`}>
          of {pageOptions.length}
        </span>
      </div>

      <div>
        {hideDisplayedElementsDropdown ? null : (
          <SelectFieldv2
            className="menuPlacementTop"
            onChange={setPageSizeSelectHandler}
            id="table-size"
            isClearable={false}
            options={displayItemsNumberOptions.map(option => ({
              value: option.toString(),
              label: `${
                hasCustomLastBodyItem ? option + 1 : option
              } ${itemName}`,
            }))}
            defaultValue={pageSize.toString()}
            isMulti={false}
            isSearchable={false}
            menuPlacement="top"
          />
        )}
      </div>
      <div className="btn-group" role="group" aria-label="Basic example">
        <button
          className="btn btn-light border"
          type="button"
          data-testid={`${tableId}__goToPreviousPageBtn`}
          onClick={previousPageClickHandler}
          disabled={!canPreviousPage}
        >
          <FontAwesomeIcon icon={faArrowLeft} />
        </button>
        <button
          className="btn btn-light border"
          type="button"
          data-testid={`${tableId}__goToNextPageBtn`}
          onClick={nextPageClickHandler}
          disabled={!canNextPage}
        >
          <FontAwesomeIcon icon={faArrowRight} />
        </button>
      </div>
    </div>
  );
};

export default DataTablePagination;
