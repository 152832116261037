import React from 'react';
import PropTypes from 'prop-types';
import { getIn } from 'formik';
import { Input, FormFeedback } from 'reactstrap';
import { isEmpty } from 'lodash';
import { getValidity } from './validate';

const renderField = ({
  field,
  form: { touched = {}, errors = {}, values = {} },
  handleChange,
  value,
  ...props
}) => {
  const optionalValue = value ? { value } : {};
  return (
    <>
      <Input
        {...props}
        {...field}
        {...getValidity(
          getIn(touched, field.name),
          getIn(errors, field.name),
          getIn(values, field.name)
        )}
        onChange={handleChange(field.onChange)}
        {...optionalValue}
      />
      {getIn(touched, field.name) &&
        getIn(errors, field.name) &&
        !isEmpty(getIn(errors, field.name)) && (
          <FormFeedback
            className="validation form__form-group-error"
            tag="small"
          >
            {getIn(errors, field.name)}
          </FormFeedback>
        )}
    </>
  );
};

renderField.propTypes = {
  field: PropTypes.shape().isRequired,
  type: PropTypes.string,
  form: PropTypes.shape({
    touched: PropTypes.shape(),
    errors: PropTypes.shape(),
  }),
  handleChange: PropTypes.func,
};

renderField.defaultProps = {
  form: null,
  type: 'text',
  handleChange: () => {},
};

export default renderField;
