import React, { useEffect, type PropsWithChildren } from 'react';
import { useHistory, useLocation } from 'react-router';

if (history.scrollRestoration) {
  history.scrollRestoration = 'manual';
}

const scrollPositions = new Map<string, number>();

const ScrollRestoration: React.FC<PropsWithChildren<{}>> = ({ children }) => {
  const history = useHistory();
  const { pathname: key } = useLocation();

  useEffect(() => {
    const unlisten = history.listen((_, action) => {
      if (action === 'PUSH') {
        scrollPositions.set(key, window.scrollY);
      } else if (action === 'POP') {
        const scrollY = scrollPositions.get(key);
        if (!!scrollY) {
          /** Timeout to make sure that page get loaded & can scroll */
          setTimeout(() => {
            window.scrollTo({ top: scrollY, left: 0, behavior: 'smooth' });
          }, 500);
        } else {
          window.scrollTo({ top: 0, left: 0 });
        }
      }
    });

    return () => {
      unlisten();
    };
  }, []);

  return <>{children}</>;
};

export default ScrollRestoration;
