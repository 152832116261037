import { StylesConfig } from 'react-select';
import variables from 'scss/1-settings/colors.scss';

const BLUE_500 = variables['blue-500']; // #1C62EB
const BLUE_100 = variables['blue-100']; // #DFEAFF
const BLUE_50 = variables['blue-50']; // #F3F6FC
const GRAY_300 = variables['gray-300']; // #BBBCC2
const INDICATOR_WIDTH = '15px';
const DEFAULT_FONT_SIZE = '0.875rem';

// Additional custom styles for this particular react-select look are located in SelectField.scss under .alternative class
const customStyles = <Option extends {}, IsMulti extends boolean>(
  isLink?: boolean,
  isMulti = false,
  isClearable = true,
  showDropdownIndicator = false
): StylesConfig<Option, IsMulti> => {
  const inputFontWeight = isLink ? 600 : 'inherit';
  const inputFontSize = isLink ? DEFAULT_FONT_SIZE : 'inherit';
  const inputColor = isLink ? BLUE_500 : 'inherit';

  const indicatorWidth = isClearable ? INDICATOR_WIDTH : 'auto';

  return {
    container: base => ({
      ...base,
      padding: 0,
      margin: '0 4px',
    }),
    control: (base, { isDisabled }) => ({
      ...base,
      border: 'none',
      boxShadow: 'none',
      backgroundColor: isDisabled ? 'transparent' : 'inherit',
      borderRadius: 0,
      borderBottom: `1px solid ${isLink ? BLUE_500 : GRAY_300}`,
      minHeight: '21px',
      '&:hover': {
        borderColor: isLink ? BLUE_500 : GRAY_300,
      },
    }),
    clearIndicator: base => ({
      ...base,
      padding: 0,
      width: indicatorWidth,
    }),
    dropdownIndicator: base => ({
      ...base,
      padding: 0,
      display: isMulti || showDropdownIndicator ? 'flex' : 'none',
      color: inputColor,
      '&:hover': {
        color: inputColor,
      },
    }),
    indicatorsContainer: base => ({
      ...base,
      marginLeft: '4px',
      padding: 0,
      width: indicatorWidth,
    }),
    indicatorSeparator: base => ({
      ...base,
      width: 0,
    }),
    input: base => ({
      ...base,
      padding: 0,
      height: '20px',
      margin: 0,
    }),
    option: (base, { isSelected }) => ({
      ...base,
      background: isSelected ? BLUE_100 : 'inherit',
      color: 'black',
      '&:hover': {
        color: 'black',
        background: BLUE_50,
      },
    }),
    placeholder: base => ({
      ...base,
      fontSize: inputFontSize,
      color: inputColor,
      margin: 0,
      fontWeight: inputFontWeight,
    }),
    singleValue: base => ({
      ...base,
      color: inputColor,
      fontSize: inputFontSize,
      maxWidth: '250px',
      margin: 0,
      fontWeight: inputFontWeight,
    }),
    menu: base => ({
      ...base,
      width: 'max-content',
      maxWidth: '350px',
      borderRadius: '3px',
    }),
    valueContainer: base => ({
      ...base,
      padding: 0,
    }),
  };
};

export default customStyles;
