import React, { PropsWithChildren, createContext } from 'react';
import { useQuery } from '@tanstack/react-query';
import { featureFlagsEndpoint } from 'infrastructure/api/endpoints';
import useApi from 'infrastructure/api/useApi';
import { ErrorPage } from 'infrastructure/error';
import { DEFAULT_TENANT_ID } from 'infrastructure/currentTenant/useCurrentTenant';
import { FeatureFlagValues, FeatureFlags } from './types';
import { featureFlagsKeys } from './queryKeys';

export const FeatureFlagsContext = createContext<FeatureFlagValues>({});

/**
 * Returns flags in human-readable format key:value instead of nested dictionaries with unwanted metadata
 * @param flags - feature flag object from API
 */
const normalizeFlags = (flags: FeatureFlags): FeatureFlagValues => {
  const normalizedFlags: FeatureFlagValues = {};
  Object.entries(flags).forEach(([flagName, flagValue]) => {
    if (!flagValue) {
      normalizedFlags[flagName] = false;
    } else {
      normalizedFlags[flagName] = flagValue.value;
    }
  });
  return normalizedFlags;
};

/**
 * Provides all of feature flags without additional HoC complexity.
 * Feature flag value meaning:
 * - true: feature flag is turned on
 * - false: feature flag is turned off
 * - undefined: feature flag is not fetched (yet)
 * - null: there is no such feature flag or error has occured
 * BE AWARE THAT CHECKING FOR FALSY VALUES CAN LEAD TO WRONG RESULTS, eg.
 * `if (!someFeatureFlag) { ... }` is wrong, since the flag can be turned off or either not yet fetched.
 */
const FeatureFlagsContextProvider: React.FC<PropsWithChildren<{}>> = ({
  children,
}) => {
  const { requestApi } = useApi();

  const flagsQuery = useQuery(
    featureFlagsKeys.base,
    () =>
      requestApi(featureFlagsEndpoint(DEFAULT_TENANT_ID), {}).then(
        ({ data }) => data
      ),
    {
      retry: false,
      select: normalizeFlags,
    }
  );

  return (
    <FeatureFlagsContext.Provider value={flagsQuery.data!}>
      {/* eslint-disable-next-line no-nested-ternary */}
      {flagsQuery.status === 'success' ? (
        children
      ) : flagsQuery.status === 'error' ? (
        <ErrorPage />
      ) : null}
    </FeatureFlagsContext.Provider>
  );
};

export default FeatureFlagsContextProvider;
