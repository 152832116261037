import React from 'react';
import Loadable from 'react-loadable';
import { Loading } from 'components';

export default Loadable({
  loader: () => import('./IndexPage'),
  loading: () => <Loading visible />,
});

export const FormV2 = Loadable({
  loader: () => import('./containers/FormPageV2'),
  loading: () => <Loading visible />,
});

export const List = Loadable({
  loader: () => import('./StoriesList'),
  loading: () => null,
});

export const Preview = Loadable({
  loader: () => import('./containers/PreviewPageV2'),
  loading: () => <Loading visible />,
});

export const Analytics = Loadable({
  loader: () => import('./containers/AnalyticsPageV2'),
  loading: () => <Loading visible />,
});
