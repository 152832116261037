import React, { useState, useRef, useCallback } from 'react';
import { Row, Col } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes, faTrashAlt } from '@fortawesome/pro-regular-svg-icons';
import { Button, Modalv2 } from 'components';

interface UnscheduleModalProps {
  title: string;
  onUnschedule: () => void;
  onCancel: () => void;
  isPublished: boolean;
  entityName: string;
}

const UnscheduleModal: React.FC<UnscheduleModalProps> = ({
  title,
  onUnschedule,
  onCancel,
  isPublished,
  entityName,
}) => {
  const [isRemoving, setIsRemoving] = useState(false);
  const [published, setIsPublished] = useState(isPublished);
  const removeButtonRef = useRef<HTMLButtonElement>(null);
  const focusSaveButtonOnOpened = () => removeButtonRef?.current?.focus();

  const handleOnRemove = useCallback(() => {
    setIsPublished(isPublished);
    setIsRemoving(true);
    onUnschedule();
  }, [isPublished, onUnschedule]);

  return (
    <Modalv2
      isOpen
      color="danger"
      onOpened={focusSaveButtonOnOpened}
      size="sm"
      handleClose={onCancel}
      footer={
        <Row>
          <Col xs="auto">
            <Button
              color="black-tertiary"
              icon={<FontAwesomeIcon icon={faTimes} />}
              onClick={onCancel}
              outline
              disabled={isRemoving}
            >
              Cancel
            </Button>
          </Col>
          <Col xs="auto">
            <Button
              autoFocus
              innerRef={removeButtonRef}
              color="danger"
              icon={<FontAwesomeIcon icon={faTrashAlt} />}
              onClick={handleOnRemove}
              loading={isRemoving}
            >
              Yes, Remove
            </Button>
          </Col>
        </Row>
      }
    >
      <h3>
        {published
          ? `${title} is live.
      This will immediately remove the "${entityName}" from the Story Row.`
          : `Are you sure you want to remove the schedule from "${title}"?`}
      </h3>
    </Modalv2>
  );
};

export default UnscheduleModal;
