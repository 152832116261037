import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'formik';
import { startCase } from 'lodash';
import { processValidate, renderField } from 'infrastructure/form';

/**
 * Generic input element with some restrictions to ensure better usage
 * @deprecated
 * New version: src/components/v2/TextField
 */
const TextField = props => {
  const { id, name, label, type, validate, maxLength, onChange } = props;
  const handleChange = formikHandleChange => event => {
    if (onChange) {
      onChange(event);
    }
    formikHandleChange(event);
  };

  return (
    <Field
      {...props}
      component={renderField}
      id={id || name}
      maxLength={type === 'time' ? 5 : maxLength}
      name={name}
      type={type}
      pattern={type === 'time' && '(0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]'}
      validate={processValidate(label || startCase(name), validate, props)}
      handleChange={handleChange}
    />
  );
};

TextField.propTypes = {
  label: PropTypes.string,
  /** Input name & ID */
  name: PropTypes.string,
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  /** Input type, others are supported in HTML but there should be separate components for those */
  type: PropTypes.oneOf([
    'email',
    'number',
    'password',
    'search',
    'tel',
    'text',
    'textarea',
    'time',
    'url',
    'time',
  ]),
  /** Validation array */
  validate: PropTypes.oneOfType([
    PropTypes.arrayOf(
      PropTypes.oneOfType([PropTypes.func, PropTypes.shape({})])
    ),
    PropTypes.bool,
  ]),
  maxLength: PropTypes.string,
  onChange: PropTypes.func,
};

TextField.defaultProps = {
  label: '',
  name: '',
  id: '',
  type: 'text',
  validate: [],
  maxLength: '',
  onChange: null,
};

export default TextField;
