type LocalFilterValue = string | number | boolean | null;
const getFiltersRecordKey = (tableId: string): string => `${tableId}-filters`;

const loadAllFilters = (tableId: string): Record<string, LocalFilterValue> => {
  const localFilters = window.localStorage.getItem(
    getFiltersRecordKey(tableId)
  );
  return localFilters ? JSON.parse(localFilters) : {};
};

const getLocalFilter = (
  tableId: string,
  filterName: string
): LocalFilterValue => {
  const allFilters = loadAllFilters(tableId);
  return allFilters[filterName] ?? null;
};

const saveLocalFilter = (
  tableId: string,
  filterName: string,
  value: string | number | boolean
) => {
  const allFilters = { ...loadAllFilters(tableId) };
  allFilters[filterName] = value;
  window.localStorage.setItem(
    getFiltersRecordKey(tableId),
    JSON.stringify(allFilters)
  );
};

export { getFiltersRecordKey, loadAllFilters, getLocalFilter, saveLocalFilter };
