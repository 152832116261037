import React from 'react';
import { loading } from 'shared/enhancers';
import SelectField from '../SelectField';

/**
 * A description of the component and when it should be used.
 */

export default loading(
  ({ options }) => !options || !options.length,
  ({ name }) => <SelectField isDisabled placeholder="Loading..." name={name} />
)(props => <SelectField {...props} />);
