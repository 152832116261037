export const debounce = (func: (...args: any[]) => void, timeout = 500) => {
  let timer: ReturnType<typeof setTimeout> | null;

  return function (this: any, ...args: any[]) {
    const context = this;

    if (timer) clearTimeout(timer);

    timer = setTimeout(() => {
      timer = null;
      func.apply(context, args);
    }, timeout);
  };
};
