import useFeatureFlags from 'infrastructure/features/useFeatureFlags';

const APPROVAL_LOCAL_STORAGE_KEY = 'useContentSharingRoutingGuard';

const useContentSharing = () => {
  const { useContentSharing: useContentSharingFlag } =
    useFeatureFlags() ?? false;

  if (localStorage.getItem(APPROVAL_LOCAL_STORAGE_KEY) === 'true') {
    return true;
  }

  return useContentSharingFlag;
};

export default useContentSharing;
