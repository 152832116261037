const isRouteCurrent = (
  unformattedMenuUrl: string,
  unformattedPath: string
): boolean => {
  const menuUrl = unformattedMenuUrl.toLowerCase();
  const currentPath = unformattedPath.toLowerCase();

  // Edge cases
  if (
    menuUrl === '/engagement-unit' &&
    (currentPath.includes('quiz') || currentPath.includes('poll'))
  ) {
    return true;
  }
  if (menuUrl === '/recipes' && currentPath.includes('/recipes/runs')) {
    return false;
  }

  // Check if the current path starts with the menu URL
  if (!currentPath.startsWith(menuUrl)) {
    return false;
  }

  // Extract the parts of the current path after the menu URL
  const currentPathParts = currentPath
    .substring(menuUrl.length)
    .split('/')
    .filter(part => part !== '');

  // If there is no submenu part, return true
  return (
    currentPathParts.length === 0 ||
    currentPath.split('/').length > menuUrl.split('/').length
  );
};

export default isRouteCurrent;
