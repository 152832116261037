import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

const WizardNav = ({
  activePage,
  disableNav,
  disableNavByIdx,
  onPageClick,
  pageNames,
}) => {
  const onClick = (e, idx) => {
    e.preventDefault();
    document.activeElement.blur();
    onPageClick(idx);
  };

  return (
    <nav className="wizard-nav">
      {pageNames.map((pageName, idx) => {
        const active = idx === activePage;
        const completed = idx < activePage;
        return (
          <div
            className={classnames('wizard-nav-item', {
              completed,
              active,
            })}
            key={pageName}
          >
            <button
              active={active}
              className="unstyled-button"
              disabled={disableNav || disableNavByIdx[idx]}
              onClick={e => onClick(e, idx)}
              tag="button"
              type="button"
            >
              <span className="step-number rounded-circle">{idx + 1}</span>
              <span className="step-name">{pageName}</span>
            </button>
          </div>
        );
      })}
    </nav>
  );
};

WizardNav.propTypes = {
  activePage: PropTypes.number,
  disableNav: PropTypes.bool,
  disableNavByIdx: PropTypes.arrayOf(PropTypes.bool),
  onPageClick: PropTypes.func,
  pageNames: PropTypes.arrayOf(PropTypes.string).isRequired,
};

WizardNav.defaultProps = {
  activePage: 0,
  disableNav: false,
  disableNavByIdx: [],
  onPageClick: () => {},
};

export default WizardNav;
