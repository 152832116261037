import { library } from '@fortawesome/fontawesome-svg-core';
import {
  faLink,
  faChartLine,
  faCode,
  faTags,
  faUsers,
  faStamp,
  faJoystick,
  faQuestionCircle,
  faBullhorn,
  faCalendarAlt,
  faCog,
  faBook,
  faBorderAll,
  faVideo,
  faPalette,
  faCauldron,
  faWrench,
  faPhotoVideo,
  faShareAlt,
  faShareAltSquare,
} from '@fortawesome/pro-light-svg-icons';
import { faInstagram } from '@fortawesome/free-brands-svg-icons';

const addFontAwesomeIconsToLibrary = () => {
  library.add([
    faLink,
    faChartLine,
    faCode,
    faTags,
    faUsers,
    faStamp,
    faJoystick,
    faQuestionCircle,
    faInstagram,
    faBullhorn,
    faCalendarAlt,
    faCog,
    faBook,
    faBorderAll,
    faVideo,
    faPalette,
    faCauldron,
    faWrench,
    faPhotoVideo,
    faShareAlt,
    faShareAltSquare,
  ]);
};

export default addFontAwesomeIconsToLibrary;
