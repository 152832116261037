import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { FieldArray, FieldArrayRenderProps, getIn } from 'formik';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faGripVertical } from '@fortawesome/pro-regular-svg-icons';
import {
  DragDropContext,
  Droppable,
  Draggable,
  DraggableProvided,
  DraggableStateSnapshot,
  DropResult,
  DroppableProvided,
} from 'react-beautiful-dnd';

import Button from 'components/Button';

import SingleFieldItems, { FieldItemComponentProps } from './SingleFieldItems';
import MultipleFieldItems from './MultipleFieldItems';

import './ListField.scss';

/** Used for wrapping sections of a form that can be added multiple times */

const componentWrapper = (
  { sortable }: { sortable: boolean },
  Component: React.FC<FieldItemComponentProps>
) => {
  if (!sortable) return Component;

  // eslint-disable-next-line react/prop-types
  return ({ index, ...props }: FieldItemComponentProps) => (
    <Draggable key={index} draggableId={`${index}`} index={index}>
      {(p: DraggableProvided, s: DraggableStateSnapshot) => (
        <div
          className={classnames('list-group-item', {
            active: s.isDragging,
          })}
          ref={p.innerRef}
          {...p.draggableProps}
          {...p.dragHandleProps}
        >
          <FontAwesomeIcon icon={faGripVertical} className="icon drag-handle" />
          <Component index={index} {...props} />
        </div>
      )}
    </Draggable>
  );
};

const onDragEnd =
  (swap: (indexA: number, indexB: number) => void) => (result: DropResult) => {
    if (!result.destination) return;

    swap(result.source.index, result.destination.index);
  };

const Wrapper = ({
  sortable,
  children,
  swap,
}: React.PropsWithChildren<{
  sortable: boolean;
  swap: (indexA: number, indexB: number) => void;
}>) => {
  const wrapper = <div className="list-field">{children}</div>;

  if (sortable) {
    return (
      <DragDropContext onDragEnd={onDragEnd(swap)}>
        <Droppable droppableId="droppable">
          {(provided: DroppableProvided) => (
            <div ref={provided.innerRef} className="dnd">
              {wrapper}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    );
  }

  return wrapper;
};

Wrapper.propTypes = {
  sortable: PropTypes.bool,
  children: PropTypes.node.isRequired,
};
Wrapper.defaultProps = {
  sortable: false,
};

type ListFieldProps = {
  name: string;
  addButtonCopy: string;
  component: React.FC<FieldItemComponentProps>;
  lineItem: string;
  singleField: boolean;
  sortable: boolean;
  label: string;
};

const ListField = ({
  addButtonCopy = 'Add',
  component,
  label,
  name,
  singleField = false,
  lineItem = 'Item',
  sortable = false,
  ...props
}: ListFieldProps) => {
  const Component = componentWrapper({ sortable }, component);
  return (
    <FieldArray
      name={name}
      render={({ form, push, remove, ...rest }: FieldArrayRenderProps) => {
        const values = getIn(form.values, name, []);

        return (
          <Wrapper {...props} {...rest}>
            {singleField && (
              <SingleFieldItems
                Component={Component}
                name={name}
                values={values}
                remove={remove}
                {...props}
              />
            )}
            {!singleField && (
              <MultipleFieldItems
                Component={Component}
                name={name}
                values={values}
                remove={remove}
                lineItem={lineItem}
                {...props}
              />
            )}
            <div className="d-flex justify-content-end">
              <Button
                data-cy="addEventButton"
                className="btn-add-field"
                color="primary"
                icon={<FontAwesomeIcon icon={faPlus} />}
                onClick={() => {
                  push({});
                  (document.activeElement as HTMLElement)?.blur();
                }}
              >
                {addButtonCopy}
              </Button>
            </div>
          </Wrapper>
        );
      }}
    />
  );
};

export default ListField;
