import React, { PropsWithChildren } from 'react';
import classnames from 'classnames';
import { Link } from 'react-router-dom';

import logo from 'img/logo/storyteller-logo-black.svg';

import './PublicPageContainer.scss';

/** A page container for the public preview */
const PublicPageContainer: React.FC<PropsWithChildren<{ light: boolean }>> = ({
  children,
  light,
}) => (
  <div
    className={classnames('public-page-container', {
      'light-background': light,
    })}
  >
    <div className="public-topbar">
      <div className="topbar-container meta">
        <Link to="/">
          <img
            src={logo}
            alt="Storyteller"
            width="289"
            height="76"
            className="logo"
          />
        </Link>
      </div>
    </div>
    <div className="content">{children}</div>
  </div>
);

export default PublicPageContainer;
