import { SortingRule } from 'react-table';
import getSortingParamsFromURL from 'components/v2/DataTable/helpers/getSortingParamsFromURL';

const getInitialSorting = (
  defaultSortBy: SortingRule<string>[] | undefined
): SortingRule<string>[] => {
  const sortParamsFromURL = getSortingParamsFromURL();
  if (sortParamsFromURL.id) {
    return [sortParamsFromURL];
  }

  if (defaultSortBy) {
    return defaultSortBy;
  }

  return [];
};

export default getInitialSorting;
