/** ############################# **
 *  ##### ACTIONS ENDPOINTS #####
 *  ############################# */

/**
 * GET Returns All Active Actions
 * @returns {`/actionLinks/active`}
 */
export const activeActionsEndpoint = `/actionLinks/active`;

/**
 * GET/PUT Action
 * @returns {`/actionLinks/{string}`}
 */
export const singleActionEndpoint = actionId => `/actionLinks/${actionId}`;

/**
 * POST Creates Action
 * @returns {`/actionLinks`}
 */
export const saveActionEndpoint = `/actionLinks`;

/**
 * PATCH Deactivates Action
 * @returns {`/actionLinks/${string}/deactivate`}
 */
export const deactivateActionEndpoint = actionId =>
  `/actionLinks/${actionId}/deactivate`;

/**
 * GET Checks if Actions Title is Available
 * @returns {`/actionLinks/titleIsAvailable`}
 */
export const isTitleAvailableEndpoint = `/actionLinks/titleIsAvailable`;

/**
 * GET Menu Items
 * @returns {`system/menu`}
 */
export const menuEndpoint = `system/menu`;

/** ###################################### **
 *  ##### ENGAGEMENT UNITS ENDPOINTS #####
 *  ###################################### */

/**
 * POST Poll
 * @type {string}
 */
export const postPollEndpoint = '/poll';

/**
 * GET/PUT/DELETE Poll by Id
 */
export const pollByIdEndpoint = pollId => `/poll/${pollId}`;

/**
 * POST Answers for poll
 */
export const answersForPollEndpoint = pollId => `/poll/${pollId}/answers`;

/**
 * POST Trivia Quiz
 * @type {string}
 */
export const postTriviaQuizEndpoint = '/triviaQuiz';

/**
 * GET/DELETE Trivia Quiz by Id
 */
export const triviaQuizEndpointById = id => `/triviaQuiz/${id}`;

/**
 * POST Trivia Quiz questions
 */
export const triviaQuizQuestionEndpoint = id => `/triviaQuiz/${id}/question`;

/**
 * DELETE Trivia Quiz question
 */
export const triviaQuizQuestionByIdEndpoint = (id, questionId) =>
  `/triviaQuiz/${id}/question/${questionId}`;

/**
 * PUT Trivia Quiz questions order
 */
export const triviaQuizQuestionsOrderEndpoint = id =>
  `/triviaQuiz/${id}/questionsOrder`;

/**
 * PUT Trivia Quiz results page
 */
export const triviaQuizResultPageEndpoint = id =>
  `/triviaQuiz/${id}/resultPage`;

/**
 * GET Engagement Unit by Id
 */
export const engagementUnitByIdEndpoint = pollId => `/engagementUnit/${pollId}`;

/**
 * GET Engagement Units
 * @returns {`/engagementUnit`}
 */
export const engagementUnitsEndpoint = '/engagementUnit';

/**
 * POST Creates Engagement Unit page in story
 * @returns {`/page/engagementUnitPage`}
 */
export const engagementUnitPageEndpoint = '/page/engagementUnitPage';

/**
 * GET Trivia Quiz by Id
 */
export const triviaQuizByIdEndpoint = triviaQuizId =>
  `/triviaQuiz/${triviaQuizId}`;

/**
 * GET EngagementUnit
 * @returns {`/engagementUnit/{string}`}
 */
export const engagementUnitById = engagementUnitId =>
  `/engagementUnit/${engagementUnitId}`;

/**
 * POST Add Poll to story (by Poll ID)
 * @returns {string}
 */
export const addPollToStoryEndpoint = `/poll/pollToStory`;

/**
 * POST Add Quiz to story (by Quiz ID)
 * @returns {string}
 */
export const addQuizToStoryEndpoint = `/triviaQuiz/quizToStory`;

/**
 * POST Add Engagement Unit to new story (by Engagement Unit ID)
 * @returns {`/engagementUnit/${string}/engagementUnitToNewStory`}
 */
export const addEngagementUnitToNewStoryEndpoint = (
  engagementUnitId,
  copiedFromStoryId
) =>
  `/engagementUnit/${engagementUnitId}/engagementUnitToNewStory/${copiedFromStoryId}`;

/**
 * POST Add Engagement Unit to existing story (by Engagement Unit ID)
 * @returns {`/engagementUnit/${string}/engagementUnitToExistingStory/${string}`}
 */
export const addEngagementUnitToExistingStoryEndpoint = (
  engagementUnitId,
  storyId
) =>
  `/engagementUnit/${engagementUnitId}/engagementUnitToExistingStory/${storyId}`;

/** ################################ **
 *  ##### CATEGORIES ENDPOINTS #####
 *  ################################ */

/**
 * GET All Categories
 * @returns {`/category`}
 */
export const categoriesEndpoint = '/category';

/**
 * POST Category to Story
 * @returns {`/category/{id}/categoryToStory`}
 */
export const saveCategoryToStoryEndpoint = storyId =>
  `/category/${storyId}/categoryToStory`;

/**
 * POST Remove Category From Story
 * @returns {`/category/{id}/categoryToStory`}
 */
export const removeCategoryFromStoryEndpoint = (storyId, categoryId) =>
  `/category/${storyId}/removeCategoryFromStory/${categoryId}`;

/**
 * POST Category to Interstitial Ad
 * @returns {`/category/{id}/categoryToInterstitialAd`}
 */
export const saveCategoryToInterstitialAdEndpoint = interstitialAdId =>
  `/category/${interstitialAdId}/categoryToInterstitialAd`;

/**
 * POST Remove Category From Interstitial Ad
 * @returns {`/category/{id}/categoryToInterstitialAd`}
 */
export const removeCategoryFromInterstitialAdEndpoint = (
  interstitialAdId,
  categoryId
) =>
  `/category/${interstitialAdId}/removeCategoryFromInterstitialAd/${categoryId}`;

/**
 * GET Category
 * @returns {`category/{string}`}
 */
export const getCategoryEndpoint = categoryId => `/category/${categoryId}`;

/**
 * GET Checks if name is available
 * @returns {`category/nameIsAvailable`}
 */
export const categoryNameAvailableEndpoint = `/category/nameIsAvailable`;

/**
 * GET Checks if externalId is available
 * @returns {`category/externalIdIsAvailable/{externalId}`}
 */
export const categoryExternalIdAvailableEndpoint = externalId =>
  `/category/externalIdIsAvailable/${externalId}`;

/**
 * GET Category
 * @returns {`category/usedCategories`}
 */
export const getUsedCategoriesEndpoint = `/category/usedCategories`;

/**
 * DELETE Category
 * @returns {`category/{string}`}
 */
export const deleteCategoryEndpoint = categoryId => `/category/${categoryId}`;

/**
 * POST Imports Categories From Csv
 * @returns {`category/{string}`}
 */
export const importCategoriesFromCsvEndpoint = `/category/importCategoriesFromCsv`;

/**
 * GET Category canSafelyDelete information
 * @returns {`/category/{string}/canSafelyDelete`}
 */
export const canSafelyDeleteCategoryEndpoint = categoryId =>
  `/category/${categoryId}/canSafelyDelete`;

/**
 * GET Category forTenant
 * @returns {`/category/forTenant/{tenantId}`}
 */
export const forTenantCategoryEndpoint = tenantId =>
  `/category/forTenant/${tenantId}`;

/** ########################## **
 *  ##### APPS ENDPOINTS #####
 *  ########################## */

/**
 * GET Returns All Apps
 * @returns {`/apps`}
 */
export const appsEndpoint = `/apps`;

/**
 * GET/PUT/DELETE App
 * @returns {`/apps/${string}`}
 */
export const appByIdEndpoint = appId => `/apps/${appId}`;

/**
 * GET Polls
 * @returns {`/poll/forEngagementUnitTable`}
 */
export const pollsEndpoint = '/poll/forEngagementUnitTable';

/**
 * GET TriviaQuizzes
 * @returns {`/triviaQuiz`}
 */
export const triviaQuizzesEndpoint = '/triviaQuiz';

/**
 * GET/DELETE TriviaQuiz
 * @returns {`/triviaQuiz/${string}`}
 */
export const triviaQuizEndpoint = triviaQuizId => `/triviaQuiz/${triviaQuizId}`;

/**
 * GET TriviaQuiz Stats
 * @returns {`/triviaQuiz/${string}/stats`}
 */
export const getTriviaQuizStatsEndpoint = triviaQuizId =>
  `/triviaQuiz/${triviaQuizId}/stats`;

/**
 * GET TriviaQuiz ByQuestion Stats
 * @returns {`/triviaQuiz/${string}/byQuestionStats`}
 */
export const getTriviaQuizByQuestionStatsEndpoint = triviaQuizId =>
  `/triviaQuiz/${triviaQuizId}/byQuestionStats`;

/**
 * GET/DELETE Poll
 * @returns {`/poll/${string}`}
 */
export const pollEndpoint = pollId => `/poll/${pollId}`;

/**
 * GET Poll Stats
 * @returns {`/poll/${string}/stats`}
 */
export const getPollStatsEndpoint = pollId => `/poll/${pollId}/stats`;

/** ##################################### **
 *  ########## USER ENDPOINTS ###########
 *  ##################################### */

/**
 * GET/POST User
 * @returns {`/user`}
 */
export const userEndpoint = `/user`;

/**
 * GET/PUT/DELETE User by id
 * @returns {`/user/${string}`}
 */
export const userByIdEndpoint = userId => `/user/${userId}`;

/**
 * GET currently logged in user data
 * @returns {`/user/userInfo`}
 */
export const currentUserEndpoint = `/user/userInfo`;

/** ##################################### **
 *  ########## AUTH ENDPOINTS ###########
 *  ##################################### */

/**
 * GET/POST AuthRefresh
 * @returns {`/auth/refresh`}
 */
export const authTokenRefreshEndpoint = '/auth/refresh';

/**
 * GET/POST User
 * @returns {`/auth/loginMethods`}
 */
export const loginMethodsEndpoint = `/auth/loginMethods`;

/** ############################# **
 *  ##### TENANTS ENDPOINTS #####
 *  ############################# */

/**
 * GET currently logged in tenant data
 * @returns {`/tenant/currentTenant`}
 */
export const currentTenantEndpoint = `/tenant/currentTenant`;

/**
 * UPDATE the content cache for the current tenant
 * @returns {`/tenant/updateContentCacheForTenant`}
 */
export const updateContentCacheForTenantEndpoint = `/tenant/updateContentCacheForTenant`;

/**
 * UPDATE the Clips cache for the current tenant
 * @returns {`/tenant/clips/cache`}
 */
export const updateClipsCacheForTenantEndpoint = `/tenant/clips/cache`;

/**
 * GET tenant list
 * @returns {`/tenant/:id`}
 */
export const tenantByIdEndpoint = id => `/tenant/${id}`;

/**
 * GET tenant list
 * @returns {`/tenant`}
 */
export const tenantEndpoint = `/tenant`;

/**
 * POST Updates all web stories for a tenant
 * @returns {`/tenant/regenerateWebStories/${string}`}
 */
export const updateWebStoriesEndpoint = tenantId =>
  `/tenant/regenerateWebStories/${tenantId}`;

/**
 * GET/POST Tenant Settings
 * @returns {`/tenant/regenerateWebStories/${string}`}
 */
export const settingsForTenantEndpoint = tenantId =>
  `/settings/settingsForTenant/${tenantId}`;

/**
 * GET Login image for the current tenant
 * @returns {'/tenant/loginImage'}
 */
export const getLoginImageEndpoint = () => '/tenant/loginImage';

/** ################################### **
 *  ##### FEATURE FLAGS ENDPOINTS #####
 *  ################################### */

/**
 * GET Returns all of tenant's feature flags
 * @param tenantId
 * @returns {`/feature/${string}`}
 */
export const featureFlagsEndpoint = tenantId => `/feature/${tenantId}`;

/**
 * POST Sets feature flags for all tenants
 * @returns {`/feature/updateForAllTenants`}
 */
export const featureFlagsUpdateForAllTenantsEndpoint = `/feature/updateForAllTenants`;

/** ################################### **
 *  ##### STORIES ENDPOINTS #####
 *  ################################### */

/**
 * GET All Stories
 * @returns {`/story`}
 */
export const storiesEndpoint = `/story`;

/**
 * GET All Stories in a slim dto
 * @returns {`/story/slimList`}
 */
export const slimStoriesEndpoint = `/story/slimList`;

/**
 * GET All Stories in a slim dto
 * @returns {`/story/v2/slimList`}
 */
export const slimStoriesEndpointV2 = `/story/v2/slimList`;

/**
 * GET / PUT / DELETE Story
 * @returns {`story/{string}`}
 */
export const storyEndpoint = storyId => `/story/${storyId}`;

/**
 * POST story
 * @returns {`/story`}
 */
export const postNewStoryEndpoint = `/story`;

/**
 * GET Story Stats
 * @returns {`story/{string}/stats`}
 */
export const getStatsEndpoint = storyId => `/story/${storyId}/stats`;

/**
 * GET Story's Polls Stats
 * @returns {`story/{string}/pollStats`}
 */
export const getStoryPollsStatsEndpoint = storyId =>
  `/story/${storyId}/pollStats`;

/**
 * GET Story's Trivia Quizzes Stats
 * @returns {`story/{string}/triviaQuizStats`}
 */
export const getStoryTriviaQuizStatsEndpoint = storyId =>
  `/story/${storyId}/triviaQuizStats`;

/**
 * GET ByPage Stats
 * @returns {`story/{string}/byPageStats`}
 */
export const getByPageStatsEndpoint = storyId =>
  `/story/${storyId}/byPageStats`;

/**
 * GET/PUT story live status.
 * @param id - live story ID
 * @returns {`/story/${string}/liveStatus`}
 */
export const liveStoryStatusEndpoint = id => `/story/${id}/liveStatus`;

/**
 * POST story pinned status.
 * @param id - pinned story ID
 * @returns {`/story/${string}/togglePinned`}
 */
export const pinnedStoryStatusEndpoint = id => `/story/${id}/togglePinned`;

/**
 * PUT story title
 * @param id - page ID
 * @returns {`/story/${string}/title`}
 */
export const storyTitleEndpoint = id => `/story/${id}/title`;

/**
 * POST public preview status
 * @param id - story ID
 * @returns {`/story/${string}/togglePreviewIsPublic`}
 */
export const storyTogglePreviewIsPublicEndpoint = id =>
  `/story/${id}/togglePreviewIsPublic`;

/**
 * PUT page order
 * @param id - page ID
 * @returns {`/story/${string}/pageOrder`}
 */
export const pageOrderEndpoint = id => `/story/${id}/pageOrder`;

/**
 * PUT Schedule Story
 * @param id - story ID
 * @returns {`/story/${string}/schedule`}
 */
export const scheduleStoryEndpoint = id => `/story/${id}/schedule`;

/**
 * POST Unschedule Story
 * @param id - story ID
 * @returns {`/story/${string}/unschedule`}
 */
export const unscheduleStoryStoryEditorEndpoint = id =>
  `/story/${id}/unschedule`;
/**
 * POST new pages
 * @param id - page ID
 * @returns {`/story/newPages/${string}`}
 */
export const storyNewPagesEndpoint = id => `/story/newPages/${id}`;

/**
 * PUT Add Custom Round Image
 * @param id - Story ID
 * @returns {`/story/${string}/roundImage`}
 */
export const addRoundImageEndpoint = id => `/story/${id}/roundImage`;

/**
 * PUT Add Custom Thumbnail Image
 * @param id - Story ID
 * @returns {`/story/${string}/thumbnail`}
 */
export const addThumbnailEndpoint = id => `/story/${id}/thumbnail`;

/**
 * POST Reset Asset to Default
 * @param id - Story ID
 * @returns {`/story/${string}/resetDefault`}
 */
export const resetAssetEndpoint = id => `/story/${id}/resetDefault`;

/**
 * POST Download Story Assets as zip
 * @param id - page ID
 * @returns {`/story/${string}/downloadStoryAsZip`}
 */
export const downloadStoryAsZipEndpoint = id =>
  `/story/${id}/downloadStoryAsZip`;

/**
 * GET Story Preview
 * @param id - page ID
 * @returns {`/story/${string}/preview`}
 */
export const storyPreviewEndpoint = id => `/story/${id}/preview`;

/**
 * GET Story Public Preview
 * @param id - page ID
 * @returns {`/story/${string}/publicPreview`}
 */
export const storyPublicPreviewEndpoint = id => `/story/${id}/publicPreview`;

/**
 * GET / POST Story Audience Targeting Key/Value Pairs
 * @param id - story ID
 * @returns {`/story/${string}/audience-targeting`}
 */
export const getStoryAudienceTargetingEndpoint = id =>
  `/story/${id}/audience-targeting`;

/**
 * PUT / DELETE Story Audience Targeting Key/Value Pairs
 * @param id - story ID
 * @param audienceTargetingId - story ID
 * @returns {`/story/${string}/audience-targeting/${string}`}
 */
export const editStoryAudienceTargetingEndpoint = (id, audienceTargetingId) =>
  `/story/${id}/audience-targeting/${audienceTargetingId}`;

/**
 * PUT Update Story AMPHTML
 * @param id - story ID
 * @returns {`/story/${string}/storyAmpHtml`}
 */
export const updateStoryAmpHtmlEndpoint = id => `/story/${id}/storyAmpHtml`;

/**
 * GET All Audience Targeting Attributes
 * @returns {`/audience-targeting/attribute`}
 */
export const getAudienceTargetingAttributesEndpoint = `/audience-targeting/attribute`;

/**
 * POST Story Metadata
 * @returns {`/story/${string}/metadata`}
 */
export const saveStoryMetadata = storyId => `/story/${storyId}/metadata`;

/**
 * POST Duplicate Story
 * @returns {`/story/${string}/duplicate`}
 */
export const duplicateStoryEndpoint = storyId => `/story/${storyId}/duplicate`;

/** ################################### **
 *  ##### PAGES ENDPOINTS #####
 *  ################################### */

/**
 * POST page reupload
 * @param id - page ID
 * @returns {`/page/${string}/reUpload`}
 */
export const pageReuploadEndpoint = id => `/page/${id}/reUpload`;

/**
 * GET/PUT/DELETE page
 * @param id - page ID
 * @returns {`/page/${string}`}
 */
export const pageEndpoint = id => `/page/${id}`;

/**
 * POST toggle page hidden status
 * @param id - page ID
 * @returns {`/page/${string}/toggleHidden`}
 */
export const pageToggleHiddenEndpoint = id => `/page/${id}/toggleHidden`;

/**
 * GET Checks if page asset is processed without error
 * @param pageId
 * @returns {`/page/${string}/hasError`}
 */
export const pageHasErrorEndpoint = pageId => `/page/${pageId}/hasError`;

/**
 * POST Duplicate page to new story
 * @returns {`/page/${string}/duplicateToNewStory`}
 */
export const duplicatePageToNewStoryEndpoint = pageId =>
  `/page/${pageId}/duplicateToNewStory`;

/**
 * POST Duplicate page to existing story
 * @returns {`/page/${string}/duplicateToExistingStory/${string}`}
 */
export const duplicatePageToExistingStoryEndpoint = (pageId, storyId) =>
  `/page/${pageId}/duplicateToExistingStory/${storyId}`;

/** ################################### **
 *  ##### CLIPS ENDPOINTS #####
 *  ################################### */

/**
 * GET/POST Clip
 * @returns {`/clips`}
 */
export const clipsEndpoint = `/clips`;

/**
 * GET/PUT Clip
 * @returns {`/clips/{string}`}
 */
export const singleClipEndpoint = clipId => `/clips/${clipId}`;

/**
 * DELETE Clip
 * @returns {`/clips/${string}`}
 */
export const deleteClipEndpoint = clipId => `/clips/${clipId}`;

/**
 * Retry Clip Video Processing
 * @returns {`/clips/${string}/retryVideoProcessing`}
 */
export const retryClipVideoProcessingEndpoint = clipId =>
  `/clips/${clipId}/retryVideoProcessing`;

/**
 * POST Reset Custom Thumbnail
 * @param id - Clip ID
 * @returns {`/clips/${string}/resetCustomThumbnail`}
 */
export const resetCustomThumbnailEndpoint = id =>
  `/clips/${id}/resetCustomThumbnail`;

/** ################################### **
 *  ###### COLLECTIONS ENDPOINTS ######
 *  ################################### */

/**
 * GET/POST Collections
 * @returns {`/collections`}
 */
export const collectionsEndpoint = `/collections`;

/**
 * GET/PUT/DELETE Collection
 * @returns {`/collections/:collectionId`}
 */
export const collectionEndpoint = collectionId =>
  `/collections/${collectionId}`;

/**
 * GET Collection Specific Clips
 * @returns {`/collections/:collectionId/collection`}
 */
export const collectionSpecificClipsEndpoint = collectionId =>
  `/collections/${collectionId}/collection`;

/**
 * GET Collection Details
 * @returns {`/collections/:collectionId/details`}
 */
export const collectionDetailsEndpoint = collectionId =>
  `/collections/${collectionId}/details`;

/**
 * PUT OrderingOption for Collection
 * @returns {`/collections/:collectionId/orderingOption`}
 */
export const collectionOrderingOptionEndpoint = collectionId =>
  `/collections/${collectionId}/orderingOption`;

/**
 * PUT Categories for Collection
 * @returns {`/collections/:collectionId/categories`}
 */
export const collectionCategoriesEndpoint = collectionId =>
  `/collections/${collectionId}/categories`;

/**
 * DELETE Single Collection Clip
 * @returns {`/collections/:collectionId/clip/:clipId`}
 */
export const removeSingleCollectionClipEndpoint = (collectionId, clipId) =>
  `/collections/${collectionId}/clip/${clipId}`;

/**
 * PUT Multiple Collection Clips
 * @returns {`/collections/:collectionId/addAndRemoveClips`}
 */
export const addAndRemoveCollectionClipsEndpoint = collectionId =>
  `/collections/${collectionId}/addAndRemoveClips`;

/**
 * PUT Reorder Collection Clips
 * @returns {`/collections/:collectionId/reorderClips`}
 */
export const reorderCollectionClipsEndpoint = collectionId =>
  `/collections/${collectionId}/reorderClips`;

/**
 * PUT Viewed Ordering for Collection
 * @returns {`/collections/:collectionId/viewedOrdering`}
 */
export const collectionViewedOrderingEndpoint = collectionId =>
  `/collections/${collectionId}/viewedOrdering`;

/**
 * POST Collection to Interstitial Ad
 * @returns {`/collections/{id}/collectionToInterstitialAd`}
 */
export const saveCollectionToInterstitialAdEndpoint = interstitialAdId =>
  `/collections/${interstitialAdId}/collectionToInterstitialAd`;

/**
 * POST Remove Collection From Interstitial Ad
 * @returns {`/collections/{id}/collectionToInterstitialAd`}
 */
export const removeCollectionFromInterstitialAdEndpoint = (
  interstitialAdId,
  collectionId
) =>
  `/collections/${interstitialAdId}/removeCollectionFromInterstitialAd/${collectionId}`;

/** ##################################### **
 *  ##### INTERSTITIAL AD ENDPOINTS #####
 *  ##################################### */

/**
 * GET All Interstitial Ads
 * @returns {`/interstitialAd`}
 */
export const getAllInterstitialAdEndpoint = '/interstitialAd';

/**
 * GET/DELETE Interstitial Ad
 * @returns {`/interstitialAd/${string}`}
 */
export const interstitialAdEndpoint = adId => `/interstitialAd/${adId}`;

/**
 * GET Interstitial Ad Stats
 * @returns {`/interstitialAd/${string}/stats`}
 */
export const interstitialAdStatsEndpoint = adId =>
  `/interstitialAd/${adId}/stats`;

/**
 * GET Ad IDs for date range
 */
export const getAdIdsForDateRange = () => `/interstitialAd/adIdsForDateRange`;

/**
 * POST interstitial ad
 * @returns {`/interstitialAd`}
 */
export const postNewInterstitialAdEndpoint = `/interstitialAd`;

/**
 * PUT interstitial ad title
 * @param id - Interstitial Ad ID
 * @returns {`/interstitialAd/${string}/title`}
 */
export const putInterstitialAdTitlesEndpoint = id =>
  `/interstitialAd/${id}/title`;

/**
 * POST Duplicate interstitial ad
 * @returns {`/interstitialAd/${string}/duplicate`}
 */
export const duplicateInterstitialAdEndpoint = id =>
  `/interstitialAd/${id}/duplicate`;

/**
 * PUT interstitial ad publish status.
 * @param id - live interstitial ad ID
 * @returns {`/interstitialAd/${string}/status`}
 */
export const publishInterstitialAdStatusEndpoint = id =>
  `/interstitialAd/${id}/status`;

/**
 * PUT interstitial ad action.
 * @param id - live interstitial ad ID
 * @returns {`/interstitialAd/${string}/action`}
 */
export const putInterstitialAdActionEndpoint = id =>
  `/interstitialAd/${id}/action`;

/**
 * PUT interstitial ad advertiser name
 * @returns {`/interstitialAd/${string}/advertiserName`}
 */
export const putAdvertiserName = id => `/interstitialAd/${id}/advertiserName`;

/**
 * PUT interstitial ad impression url
 * @returns {`/interstitialAd/${string}/impressionUrl`}
 */
export const putImpressionUrl = id => `/interstitialAd/${id}/impressionUrl`;

/**
 * POST Schedule interstitial ad for story
 * @param id - interstitialAd ID
 * @returns {`/interstitialAd/${string}/schedule/category`}
 */
export const postScheduleStoryAdEndpoint = id =>
  `/interstitialAd/${id}/schedule/category`;

/**
 * POST Unschedule interstitial ad for story
 * @param id - interstitialAd ID
 * @returns {`/interstitialAd/${string}/unSchedule/category`}
 */
export const postUnscheduleStoryAdEndpoint = id =>
  `/interstitialAd/${id}/unSchedule/category`;

/**
 * POST Schedule interstitial ad for clip
 * @param id - interstitialAd ID
 * @returns {`/interstitialAd/${string}/schedule/collection`}
 */
export const scheduleClipAdEndpoint = id =>
  `/interstitialAd/${id}/schedule/collection`;

/**
 * POST Unschedule interstitial ad for clip
 * @param id - interstitialAd ID
 * @returns {`/interstitialAd/${string}/unSchedule/collection`}
 */
export const unscheduleClipAdEndpoint = id =>
  `/interstitialAd/${id}/unSchedule/collection`;

/** ##################################### **
 *  ##### SCHEDULE ENDPOINTS #####
 *  ##################################### */

export const categoryScheduleEndpoint = `/schedule`;

export const categoryOrderingOptionEndpoint = `/schedule/ordering`;

export const reorderScheduleStoriesEndpoint = `/schedule/reorderStories`;

export const unscheduleStoryEndpoint = storyId =>
  `/schedule/unscheduleStory/${storyId}`;

/**
 * PUT story publish status.
 * @param id - live story ID
 * @returns {`/story/${string}/publishStatus`}
 */
export const publishStoryStatusEndpoint = id => `/story/${id}/publishStatus`;

/** ##################################### **
 *  ##### INSTAGRAM ENDPOINTS #####
 *  ##################################### */

/**
 * GET All Instagram Accounts With PAges
 * @returns {`/instagramAccounts/accountsWithPages`}
 */
export const instagramAccountsWithPagesEndpoint =
  '/instagramAccounts/accountsWithPages';

/**
 * GET All Instagram's Account Pages
 * @returns {`/instagramPages`}
 */
export const instagramPagesEndpoint = '/instagramPages';

/**
 * GET / POST All Instagram Accounts
 * @returns {`/instagramAccounts`}
 */
export const instagramAccountsEndpoint = '/instagramAccounts';

/**
 * GET / PUT / DELETE Instagram Account
 * @param id - Instagram Account ID
 * @returns {`/instagramAccounts/${id}`}
 */
export const instagramAccountEndpoint = id => `/instagramAccounts/${id}`;

/**
 * POST Schedule Import Stories
 * @param instagramAccountId - Instagram Account ID
 * @returns {`/instagramAccounts/scheduleImportStories/${instagramAccountId}`}
 */
export const scheduleImportStoriesEndpoint = instagramAccountId =>
  `/instagramAccounts/scheduleImportStories/${instagramAccountId}`;

/**
 * GET Instagram Business Accounts
 * @returns {`/instagramAccounts/instagramBusinessAccounts`}
 */
export const instagramBusinessAccountsEndpoint = `/instagramAccounts/instagramBusinessAccounts`;

/**
 * POST Instagram Accounts
 * @returns {`/instagramAccounts/saveAccounts`}
 */
export const saveInstagramAccountsEndpoint = `/instagramAccounts/saveAccounts`;

/**
 * POST Instagram Pages To Story
 * @returns {`/story/${id}/instagramPages`}
 */
export const postInstagramPagesToStoryEndpoint = id =>
  `/story/${id}/instagramPages`;

/** ############################# **
 *  ##### ANALYTICS ENDPOINTS #####
 *  ############################# */

/**
 * GET Stats
 */
export const statsEndpoint = `/analytics/stats`;

/**
 * GET Stats grouped by Story
 */
export const statsGroupedByStoryEndpoint = `/analytics/groupedByStoryStats`;

/**
 * GET Stats grouped by Platform
 */
export const statsGroupedByPlatformEndpoint = `/analytics/groupedByPlatformStats`;

/**
 * GET Unique Viewers
 */
export const getUniqueViewers = `/analytics/uniqueViewers`;

/** ############################# **
 *  ## STUDIO ASSETS ENDPOINTS ##
 *  ############################# */

/**
 * GET/POST Studio Assets Text Sets
 * @type {string}
 */
export const textSetsEndpoint = '/studioTextSet';

/**
 * GET Studio Asset Text Sets by ID
 * @param id
 * @returns {`/studioTextSet/${string}`}
 */
export const textSetByIdEndpoint = id => `/studioTextSet/${id}`;

/**
 * GET/POST Studio Assets Text Sets
 * @type {string}
 */
export const templatesEndpoint = '/template';

/**
 * GET Templates folders list
 * @type {string}
 */
export const templatesFolderEndpoint = '/template/templateFolders';

/**
 * GET Studio Asset Text Sets by ID
 * @param id
 * @returns {`/studioTextSet/${string}`}
 */
export const templateByIdEndpoint = id => `/template/${id}`;

/**
 * GET Shapes list
 * @type {string}
 */
export const studioShapeEndpoint = '/studioShape';

/**
 * GET Shape by ID
 * @param id
 * @returns {`/studioShape/${string}`}
 */
export const studioShapeByIdEndpoint = id => `/studioShape/${id}`;

/**
 * GET/POST Font
 * @type {string}
 */
export const studioFontEndpoint = `/studioFont`;

/**
 * GET Font by ID
 * @param id
 * @returns {`/studioFont/${string}`}
 */

export const studioFontByIdEndpoint = id => `/studioFont/${id}`;
/* GET Stickers list
 * @type {string}
 */
export const studioStickerEndpoint = '/studioSticker';

/**
 * GET Sticker by ID
 * @param id
 * @returns {`/studioSticker/${string}`}
 */
export const studioStickerByIdEndpoint = id => `/studioSticker/${id}`;

/**
 * GET Media Source List
 * @returns {`/mediaSource/GetList`}
 */
export const mediaSourceGetListEndpoint = '/mediaSource/getList';

/**
 * GET / DELETE Media Source
 * @returns {`/mediaSource/${string}`}
 */
export const mediaSourceEndpointById = id => `/mediaSource/${id}`;

/**
 * GET Media Source Settings
 * @returns {`/mediaSource/${string}/settings`}
 */
export const mediaSourceSettingsEndpointById = id =>
  `/mediaSource/${id}/settings`;

/**
 * POST / PUT Media Source
 * @returns {`/mediaSource`}
 */
export const mediaSourceEndpoint = '/mediaSource';

/**
 * POST / PUT Media Source External Asset
 * @returns {`/mediaSource/externalAsset`}
 */
export const mediaSourceExternalAssetEndpoint = '/mediaSource/externalAsset';

/**
 * GET / DELETE Media Source External Asset
 * @returns {`/mediaSource/externalAsset/${string}`}
 */
export const mediaSourceExternalAssetByIdEndpoint = id =>
  `/mediaSource/externalAsset/${id}`;

/**
 * PUT Media Source Change Display Order
 * @returns {`/mediaSource/displayOrder`}
 */
export const mediaSourceChangeDisplayOrderEndpoint =
  '/mediaSource/displayOrder';

/** ############################# **
 *  ## RECIPES ENDPOINTS ##
 *  ############################# */

/**
 * GET Recipes
 * @returns {`/recipes`}
 */
export const recipesEndpoint = '/recipes';

/**
 * GET SingleRecipe
 * @param recipeId
 * @returns {`/recipes/${recipeId}`}
 */
export const getSingleRecipeEndpoint = recipeId => `/recipes/${recipeId}`;

/**
 * GET Recipe Fields
 * @param recipeId
 * @returns {`/recipes/${recipeId}/recipeFields`}
 */
export const getRecipeFieldsEndpoint = recipeId =>
  `/recipes/${recipeId}/recipeFields`;

/**
 * POST Run Recipe
 * @param recipeId
 * @returns {`/recipes/${recipeId}/runRecipe`}
 */
export const runRecipeEndpoint = recipeId => `/recipes/${recipeId}/runRecipe`;

/**
 * GET Recipe Runs
 * @returns {`/recipeRuns`}
 */
export const recipeRunsEndpoint = '/recipeRuns';

/**
 * GET Recipe Run
 * @param runId
 * @returns {`/recipeRuns/${runId}`}
 */
export const getRecipeRunEndpoint = runId => `/recipeRuns/${runId}`;

/** ################################### **
 *  ##### CUSTOM RECIPE ENDPOINTS #####
 *  ################################### */

/**
 * GET NBA Games dates
 * @returns {`/recipes/custom/matchups/nbaGames`}
 */
export const matchupsRecipeNBAGamesEndpoint = (league, teamId) =>
  `/recipes/custom/matchups/nbaGames?league=${league}&teamId=${teamId || ''}`;

/**
 * GET Voiceover content
 * @returns {`/recipes/custom/matchups/voiceoverText`}
 */
export const matchupsRecipeGetVoiceoverTextEndpoint = `/recipes/custom/matchups/voiceoverText`;

/**
 * POST Voiceover audio preview
 * @returns {`/recipes/custom/matchups/voiceoverPreview`}
 */
export const matchupsRecipeGetVoiceoverAudioEndpoint = `/recipes/custom/matchups/voiceoverPreview`;

/** ########################## **
 *  ###### WSC ENDPOINTS #####
 *  ########################## */

/**
 * GET/POST WSC Config
 * @returns {`/wscConfiguration`}
 */
export const wscConfigurationEndpoint = '/wscConfiguration';

/**
 * GET WSC Config by id
 * @param wscConfigId
 * @returns {`/wscConfiguration/${id}`}
 */
export const wscConfigurationByIdEndpoint = wscConfigId =>
  `/wscConfiguration/${wscConfigId}`;

/**
 * POST Generate Ad Export
 */
export const generateAdExportEndpoint = '/exports/generateAdExport';

/** ########################## **
 *  ###### Studio Story Thumbnail Endpoints #####
 *  ########################## */

/**
 * POST Retry Studio Story Thumbnail Processing
 */
export const retryStudioStoryThumbnailProcessingEndpoint = storyId =>
  `/studioStoryThumbnail/retry-processing/${storyId}`;

/** ########################## **
 *  ###### Studio Story Icon Endpoints #####
 *  ########################## */

/**
 * POST Retry Studio Story Icon Processing
 */
export const retryStudioStoryIconProcessingEndpoint = storyId =>
  `/studioStoryIcon/retry-processing/${storyId}`;
