import urlParams from 'components/v2/DataTable/data/urlParams';

const unsetURLSortingParams = () => {
  const url = new URL(window.location.href);
  url.searchParams.delete(urlParams.sortId);
  url.searchParams.delete(urlParams.sorting);
  const urlHasToChange = url.toString() !== window.location.href;
  if (urlHasToChange) window.history.replaceState(null, '', url);
};

export default unsetURLSortingParams;
