import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { findLastIndex, get, isEmpty } from 'lodash';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faArrowLeft,
  faArrowRight,
  faCheck,
  faTimes,
} from '@fortawesome/pro-regular-svg-icons';

import { Button } from 'components';
import WizardNav from './WizardNav';

import './Wizard.scss';

const PREVIOUS_PAGE = 'previous';
const NEXT_PAGE = 'next';

const Wizard = ({
  current,
  errors,
  finishButtonShouldSubmit,
  showSaveButton,
  isSubmitting,
  isPreviousLoading,
  onCancel,
  onSave,
  pageFunctions,
  pages,
  setTouched,
  startPage,
  submitButtonDisabled,
  submitText,
  touched,
  validateForm,
  backgroundLoadingInProgress,
}) => {
  const hasErrors = !isEmpty(errors);
  const [activePage, setActivePage] = useState(startPage);

  const validatePage = () => {
    setTouched(
      pages[activePage].pageFields.reduce(
        (ac, field) => ({ ...ac, [field]: true }),
        touched
      )
    );
    return validateForm();
  };

  const validateCurrentPage = () => {
    document.activeElement.blur();
    validatePage().then(err => err);
  };

  const updateActivePage = (newPage, err) => {
    if (!isEmpty(err)) return;
    setActivePage(newPage);

    if (newPage === 1) {
      validatePage();
    }
  };

  const firstUntouchedPage = () =>
    findLastIndex(pages, (_, pageIdx) => {
      const { pageFields } = pages[pageIdx];
      return pageFields.every(x => touched[x]);
    }) + 1;

  const disablePageNavByIdx = pages.map((_, idx) =>
    current ? false : idx > firstUntouchedPage() + 1
  );

  const onPreviousClick = async () => {
    const err = validateCurrentPage();

    if (pageFunctions[activePage - 1]) {
      await pageFunctions[activePage - 1](PREVIOUS_PAGE);
    }

    updateActivePage(activePage - 1, err);
  };

  const onNextClick = async () => {
    const err = validateCurrentPage();

    if (pageFunctions[activePage + 1]) {
      await pageFunctions[activePage + 1](NEXT_PAGE);
    }

    updateActivePage(activePage + 1, err);
  };

  const onPageClick = async page => {
    const err = validateCurrentPage();

    if (pageFunctions[page]) {
      await pageFunctions[page](NEXT_PAGE);
    }

    updateActivePage(page, err);
  };

  const firstPageActive = activePage === 0;
  const lastPageActive = activePage === pages.length - 1;

  return (
    <div className="wizard">
      <WizardNav
        activePage={activePage}
        disableNav={hasErrors}
        disableNavByIdx={disablePageNavByIdx}
        onPageClick={onPageClick}
        pageNames={pages.map(page => page.pageName)}
      />
      <div className="wizard-form-container">
        {get(pages, `${activePage}.pageContent`)}
      </div>
      <div className="wizard-actions">
        {(firstPageActive && (
          <Button
            buttonStyle="outline"
            color="secondary"
            icon={<FontAwesomeIcon icon={faTimes} />}
            onClick={onCancel}
            data-testid="wizardCancelButton"
          >
            Cancel
          </Button>
        )) || (
          <Button
            buttonStyle="outline"
            color="secondary"
            disabled={hasErrors}
            loading={isPreviousLoading}
            icon={<FontAwesomeIcon icon={faArrowLeft} />}
            onClick={onPreviousClick}
            data-testid="wizardPreviousButton"
          >
            Previous
          </Button>
        )}
        {lastPageActive && showSaveButton && (
          <Button
            className="btn-save-changes"
            color="primary"
            disabled={
              hasErrors || submitButtonDisabled || backgroundLoadingInProgress
            }
            icon={<FontAwesomeIcon icon={faCheck} />}
            iconAfter
            loading={isSubmitting}
            onClick={onSave}
            size="lg"
            type={finishButtonShouldSubmit ? 'submit' : 'button'}
            data-testid="wizardFinishButton"
          >
            {submitText}&nbsp;
          </Button>
        )}
        {!lastPageActive && (
          <Button
            color="primary"
            disabled={hasErrors || backgroundLoadingInProgress}
            icon={<FontAwesomeIcon icon={faArrowRight} />}
            iconAfter
            loading={isSubmitting}
            onClick={() => onNextClick()}
            size="lg"
            data-testid="wizardNextButton"
          >
            Next&nbsp;
          </Button>
        )}
      </div>
    </div>
  );
};

Wizard.propTypes = {
  current: PropTypes.shape({}),
  finishButtonShouldSubmit: PropTypes.bool,
  onCancel: PropTypes.func,
  onSave: PropTypes.func,
  pageFunctions: PropTypes.arrayOf(PropTypes.func),
  pages: PropTypes.arrayOf(
    PropTypes.shape({
      pageName: PropTypes.string,
      pageFields: PropTypes.arrayOf(PropTypes.string),
      pageContent: PropTypes.node,
    })
  ),
  errors: PropTypes.shape({}),
  isSubmitting: PropTypes.bool,
  isPreviousLoading: PropTypes.bool,
  setTouched: PropTypes.func,
  showSaveButton: PropTypes.bool,
  startPage: PropTypes.number,
  submitButtonDisabled: PropTypes.bool,
  submitText: PropTypes.string,
  touched: PropTypes.shape({}),
  validateForm: PropTypes.func,
  backgroundLoadingInProgress: PropTypes.bool,
};

Wizard.defaultProps = {
  current: {},
  finishButtonShouldSubmit: true,
  onCancel: () => {},
  onSave: null,
  pageFunctions: [],
  pages: [],
  errors: {},
  startPage: 0,
  isSubmitting: false,
  isPreviousLoading: false,
  setTouched: () => {},
  showSaveButton: true,
  submitButtonDisabled: false,
  submitText: 'Save',
  touched: {},
  validateForm: () => {},
  backgroundLoadingInProgress: false,
};

export default Wizard;
