import { SortingRule } from 'react-table';
import URL_PARAMS from 'components/v2/DataTable/data/urlParams';
import { SortingDirection } from 'components/v2/DataTable/helpers/getSortingDirection';

const getSortingParamsFromURL = (): SortingRule<string> => {
  const url = new URL(window.location.href);
  const sortId = url.searchParams.get(URL_PARAMS.sortId) ?? '';
  const sortDesc =
    url.searchParams.get(URL_PARAMS.sorting) === SortingDirection.desc ??
    undefined;
  return {
    id: sortId,
    desc: sortDesc,
  };
};
export default getSortingParamsFromURL;
