import React from 'react';
import PropTypes from 'prop-types';
import { ChromePicker } from 'react-color';
import { Popover } from 'components';

/** A color picker component similar to Chrome's */

class ChromeColorPickerField extends React.Component {
  constructor() {
    super();
    this.colorPickerContainer = React.createRef();
  }

  handleTouchStart = () => {
    if (this.colorPickerContainer.current) {
      this.colorPickerContainer.current.addEventListener(
        'touchmove',
        event => {
          event.preventDefault();
        },
        false
      );
    }
  };

  handleTouchEnd = () => {
    if (this.colorPickerContainer.current) {
      this.colorPickerContainer.current.removeEventListener('touchmove');
    }
  };

  handleChange = color => {
    const { onChange } = this.props;
    onChange(color);
  };

  render() {
    const { name, value } = this.props;
    const color = value || '#FFFFFF';

    return (
      <div className="color-picker">
        <Popover
          buttonClassName="btn color-picker__button"
          id={name}
          trigger={
            <React.Fragment>
              <span className="color-picker__color">{color}</span>
              <div
                className="color-picker__color-view"
                style={{ backgroundColor: color }}
              />
            </React.Fragment>
          }
        >
          <div
            onTouchStart={this.handleTouchStart}
            onTouchEnd={this.handleTouchEnd}
            ref={this.colorPickerContainer}
          >
            <ChromePicker
              color={color}
              onChange={this.handleChange}
              disableAlpha
            />
          </div>
        </Popover>
      </div>
    );
  }
}

ChromeColorPickerField.propTypes = {
  onChange: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  value: PropTypes.string,
};

ChromeColorPickerField.defaultProps = {
  value: '#FFFFFF',
};

const renderChromeColorPickerField = props => {
  const { input, meta } = props;
  return (
    <div className="form__form-group-input-wrap">
      <ChromeColorPickerField {...input} />
      {meta.touched && meta.error && (
        <span className="form__form-group-error">{meta.error}</span>
      )}
    </div>
  );
};

renderChromeColorPickerField.propTypes = {
  input: PropTypes.shape().isRequired,
  meta: PropTypes.shape({
    touched: PropTypes.bool,
    error: PropTypes.string,
  }),
};

renderChromeColorPickerField.defaultProps = {
  meta: null,
};

export default renderChromeColorPickerField;
