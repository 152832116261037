import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Field } from 'formik';
import classnames from 'classnames';

import './ToggleButton.scss';

class ToggleButtonField extends PureComponent {
  static propTypes = {
    onChange: PropTypes.func,
    name: PropTypes.string,
    id: PropTypes.string,
    disabled: PropTypes.bool,
    value: PropTypes.bool,
    dataTestId: PropTypes.string,
  };

  static defaultProps = {
    onChange: null,
    name: '',
    id: '',
    disabled: false,
    value: false,
    dataTestId: '',
  };

  render() {
    const { name, disabled, value, onChange, id, dataTestId } = this.props;

    return (
      <div className={classnames('toggle-btn', { disabled })}>
        <input
          checked={value}
          className="toggle-input"
          disabled={disabled}
          name={name}
          onChange={onChange}
          type="checkbox"
        />
        <button
          className="toggle-input-btn"
          disabled={disabled}
          onClick={() => onChange(!value)}
          type="button"
          data-checked={value ? 'true' : 'false'}
          data-test-id={dataTestId || `toggleButton_${id}`}
        >
          Toggle
        </button>
      </div>
    );
  }
}

const renderToggleButtonField = ({ disabled, input, dataTestId }) => (
  <ToggleButtonField {...input} dataTestId={dataTestId} disabled={disabled} />
);

renderToggleButtonField.propTypes = {
  input: PropTypes.shape({
    onChange: PropTypes.func,
    name: PropTypes.string,
  }),
  disabled: PropTypes.bool,
  dataTestId: PropTypes.string,
};

renderToggleButtonField.defaultProps = {
  input: PropTypes.shape({
    onChange: null,
    name: '',
  }),
  disabled: false,
  dataTestId: '',
};

/**
 * A custom toggle switch
 * @deprecated
 * New version: src/components/v2/ToggleButton
 */
const ToggleButton = props => {
  const { name, value, onToggle, onChange, id, disabled, dataTestId } = props;

  return (
    <div>
      {name ? (
        <Field
          name={name}
          render={({ form, field, ...p }) =>
            renderToggleButtonField({
              ...props,
              input: {
                ...field,
                id,
                onChange: v => {
                  form.setFieldValue(name, v);
                  form.setFieldTouched(name, true);
                  onChange(v);
                  if (onToggle) {
                    onToggle(v);
                  }
                },
              },
              ...p,
            })
          }
        />
      ) : (
        <ToggleButtonField
          onChange={e => onChange(e)}
          value={value}
          id={id}
          disabled={disabled}
          dataTestId={dataTestId}
        />
      )}
    </div>
  );
};

ToggleButton.propTypes = {
  /** Input name & ID */
  name: PropTypes.string,
  id: PropTypes.string,
  /** value if not controlled by formik field */
  value: PropTypes.bool,
  /** onChange function handler */
  onChange: PropTypes.func,
  /** Optional function to trigger on toggle */
  onToggle: PropTypes.func,
  disabled: PropTypes.bool,
  dataTestId: PropTypes.string,
};

ToggleButton.defaultProps = {
  name: '',
  id: '',
  value: false,
  disabled: false,
  onChange: () => {},
  onToggle: () => {},
  dataTestId: '',
};

export default ToggleButton;
