import React, { Dispatch, SetStateAction, useCallback, useEffect } from 'react';
import { Col, Input, Label, Row } from 'reactstrap';
import { Button, SelectFieldAsyncPaginate, SelectFieldv2 } from 'components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faFileImport,
  faPlus,
  faSearch,
} from '@fortawesome/pro-regular-svg-icons';
import { debounce } from 'shared/helpers/debounce';
import { SingleValue } from 'react-select';
import getGlobalFilterOptionURLParam from 'components/v2/DataTable/helpers/getGlobalFilterOptionURLParam';
import setURLParams from 'components/v2/DataTable/helpers/setURLParams';
import URL_PARAMS from 'components/v2/DataTable/data/urlParams';
import type { SelectFieldOptionObject } from '../SelectField/types';
import { LoadOptions, ReduceOptions } from '../SelectFieldAsyncPaginate';

import './TopBar.scss';

const DEFAULT_SORTING_VALUE = 'internalTitle';

const onChange = (
  setValue: (value?: SelectFieldOptionObject) => void,
  selectedValue?: SingleValue<SelectFieldOptionObject>
): void => {
  if (selectedValue) {
    setValue(selectedValue);
  } else {
    setValue(undefined);
  }
};

interface SearchProps {
  searchQuery?: string;
  setSearchQuery: (query: string) => void;
  tableId: string;
  isDisabled?: boolean;
  gotoPage: (page: number) => void;
}

export const Search: React.FC<SearchProps> = ({
  searchQuery = '',
  setSearchQuery,
  tableId,
  isDisabled,
  gotoPage,
}) => {
  const handleChange: React.ChangeEventHandler<HTMLInputElement> = event => {
    setSearchQuery(event.target.value);
    gotoPage(0);
  };

  const debouncedChangeHandler = useCallback(debounce(handleChange), []);

  return (
    <Col sm={6} md={6} lg={3}>
      <form
        className="form search-bar"
        onSubmit={event => {
          event.preventDefault();
        }}
      >
        <div className="form__form-group-field">
          <Label className="sr-only" htmlFor="tableSearch">
            Search
          </Label>
          <Input
            className="search-input"
            id="tableSearch"
            name="tableSearch"
            placeholder="Search"
            type="search"
            defaultValue={searchQuery}
            onChange={event => {
              event.persist();
              debouncedChangeHandler(event);
            }}
            data-testid={`${tableId}__searchInput`}
            disabled={isDisabled}
          />
          <Button
            aria-label="Search"
            type="button"
            className="input-group-append"
            color="secondary"
            icon={<FontAwesomeIcon icon={faSearch} />}
            disabled={isDisabled}
          />
        </div>
      </form>
    </Col>
  );
};

type Filter = SelectFieldOptionObject;

interface StatusFiltersProps {
  filters: Array<Filter> | LoadOptions<Filter>;
  setFilteredOption: (value?: SelectFieldOptionObject) => void;
  filteredOption?: SelectFieldOptionObject;
  filterName?: string;
  placeholder?: string;
  tableId: string;
  isDisabled?: boolean;
  topOptions?: Array<Filter>;
  gotoPage: (page: number) => void;
}

export const StatusFilters: React.FC<StatusFiltersProps> = ({
  filters,
  setFilteredOption,
  filteredOption,
  filterName = '',
  placeholder = 'Search...',
  tableId,
  isDisabled,
  topOptions = [],
  gotoPage,
}) => {
  const filterUrlParamKey = getGlobalFilterOptionURLParam(filterName);
  const onSelectFieldChange = useCallback(
    (newValue: SingleValue<SelectFieldOptionObject>) => {
      const filter = newValue?.value;
      setURLParams({
        [filterUrlParamKey]: filter || '',
      });
      onChange(setFilteredOption, newValue);
      gotoPage(0);
    },
    [filterUrlParamKey, gotoPage, setFilteredOption]
  );

  const isAsync = typeof filters === 'function';

  const reduceOptions: ReduceOptions = useCallback(
    (prevOptions, loadedOptions) => [
      ...topOptions,
      ...prevOptions,
      ...loadedOptions,
    ],
    [topOptions]
  );

  return (
    <Col xs="auto" className="labeled-toggle-container">
      {isAsync ? (
        <SelectFieldAsyncPaginate
          isClearable={!!filteredOption}
          loadOptions={filters}
          reduceOptions={reduceOptions}
          onChange={onSelectFieldChange}
          debounceTimeout={200}
          placeholder={placeholder}
          isDisabled={isDisabled}
          clearInputValueOnFocus
          value={filteredOption}
        />
      ) : (
        <SelectFieldv2
          onChange={onSelectFieldChange}
          id={filterName}
          isClearable={false}
          isSearchable={false}
          options={filters}
          selectFieldId={tableId}
          isMulti={false}
          isDisabled={isDisabled}
          controlledValue={filteredOption || filters[0]}
        />
      )}
    </Col>
  );
};

interface Option {
  label: string;
  value: string;
}

interface SortingOptionsProps {
  options: Array<Option>;
  setSortingOption: (value?: SelectFieldOptionObject) => void;
  defaultSortingOption?: string;
  sortingOption?: Option;
  isDisabled?: boolean;
  gotoPage: (page: number) => void;
}

export const SortingOptions: React.FC<SortingOptionsProps> = ({
  options,
  setSortingOption,
  sortingOption,
  defaultSortingOption = DEFAULT_SORTING_VALUE,
  isDisabled,
  gotoPage,
}) => {
  const onSelectFieldChange = useCallback(
    (event: SingleValue<SelectFieldOptionObject>) => {
      onChange(setSortingOption, event);
      gotoPage(0);
    },
    [gotoPage, setSortingOption]
  );

  useEffect(() => {
    setURLParams({
      [URL_PARAMS.globalSortingOption]:
        sortingOption?.value || defaultSortingOption,
    });
  }, [defaultSortingOption, sortingOption]);

  return (
    <Col xs="auto" className="labeled-toggle-container">
      <span className="sortingLabel">Sort by:</span>
      <div className="sortingOptionsContainer">
        <SelectFieldv2
          onChange={onSelectFieldChange}
          id="sorting"
          isClearable={false}
          isSearchable={false}
          options={options}
          isMulti={false}
          isDisabled={isDisabled}
          controlledValue={
            sortingOption || {
              value: defaultSortingOption,
              label: defaultSortingOption,
            }
          }
        />
      </div>
    </Col>
  );
};

export interface SearchQueryProps {
  searchQuery?: string;
  setSearchQuery: Dispatch<SetStateAction<string>>;
}

export interface TopBarConfig {
  searchFeature?: SearchQueryProps;
  sortingFeature?: {
    sortingOption?: SelectFieldOptionObject;
    defaultSortingOption?: string;
    setSortingOption: (value?: SelectFieldOptionObject) => void;
    options: Array<Option>;
  };
  filterFeature?: Array<{
    filters: Array<Filter> | LoadOptions<Filter>;
    filteredOption?: SelectFieldOptionObject;
    defaultFilterOption?: string;
    setFilteredOption: (value?: SelectFieldOptionObject) => void;
    filterName: string;
    placeholder?: string;
    topOptions?: Array<Filter>;
  }>;
  customButtons?: React.ReactNode;
  addButton?: {
    addButtonText: string;
    onAddButtonClick: () => void;
    id?: string;
    dataTestId?: string;
    isLoading?: boolean;
    isDisabled?: boolean;
  };
  otherButton?: {
    buttonText: string;
    onButtonClick: () => void;
  };
}

export interface TopBarProps {
  topbarConfig: TopBarConfig;
  pageSize: number; // Needed for top Pagination Info, e.g. 21 to 40 of 100
  pageIndex: number; // Needed for top Pagination Info, e.g. 21 to 40 of 100
  totalElementsCount: number; // Needed for top Pagination Info, e.g. 21 to 40 of 100
  tableId: string; // e.g. for testing purposes
  gotoPage: (page: number) => void;
}

const TopBar: React.FC<TopBarProps> = ({
  pageSize,
  pageIndex,
  totalElementsCount,
  tableId,
  gotoPage,
  topbarConfig: {
    searchFeature,
    filterFeature,
    sortingFeature,
    customButtons,
    addButton,
    otherButton,
  },
}) => {
  const pagesFrom = totalElementsCount === 0 ? 0 : 1 + pageSize * pageIndex;
  const pagesUntil =
    pageSize * (pageIndex + 1) > totalElementsCount
      ? totalElementsCount
      : pageSize * (pageIndex + 1);

  const addButtonTestId = addButton?.dataTestId
    ? {
        'data-testid': addButton.dataTestId,
      }
    : {};

  return (
    <Row className="topbar">
      {searchFeature && (
        <Search
          searchQuery={searchFeature.searchQuery}
          setSearchQuery={searchFeature.setSearchQuery}
          tableId={tableId}
          gotoPage={gotoPage}
        />
      )}
      {sortingFeature && (
        <SortingOptions
          sortingOption={sortingFeature?.sortingOption}
          options={sortingFeature.options}
          defaultSortingOption={sortingFeature.defaultSortingOption}
          setSortingOption={sortingFeature.setSortingOption}
          gotoPage={gotoPage}
        />
      )}
      {filterFeature &&
        filterFeature.map(filter => (
          <StatusFilters
            key={filter.filterName}
            filters={filter.filters}
            filteredOption={filter.filteredOption}
            setFilteredOption={filter.setFilteredOption}
            filterName={filter.filterName}
            tableId={tableId}
            topOptions={filter.topOptions}
            gotoPage={gotoPage}
            placeholder={filter.placeholder}
          />
        ))}

      <span data-testid={`${tableId}__pagesInfo`}>
        Showing {pagesFrom} to {pagesUntil} of {totalElementsCount}
      </span>
      <div className="buttons-section">
        {customButtons || (
          <>
            {otherButton && (
              <button
                onClick={otherButton.onButtonClick}
                type="button"
                className="btn btn-outline-primary"
              >
                <FontAwesomeIcon icon={faFileImport} /> {otherButton.buttonText}
              </button>
            )}
            {addButton && (
              <Button
                onClick={addButton.onAddButtonClick}
                type="button"
                className="btn btn-primary btn-create new"
                disabled={addButton.isDisabled}
                loading={addButton.isLoading}
                {...addButtonTestId}
              >
                <FontAwesomeIcon icon={faPlus} /> {addButton.addButtonText}
              </Button>
            )}
          </>
        )}
      </div>
    </Row>
  );
};

export default TopBar;
