import { GlobalAppWrapper, TopRightNav } from 'components';
import useCurrentUser from 'infrastructure/currentUser/useCurrentUser';
import { ErrorBoundary } from 'infrastructure/error';
import React, { useMemo } from 'react';
import { Redirect, Route, Switch, useLocation } from 'react-router';
import logo from 'img/logo/storyteller-logo-white.svg';
import useGetMenu from 'slices/AccountV2/hooks/useGetMenu';
import Actions from 'slices/Actions/Loadable';
import Analytics from 'slices/Analytics/Loadable';
import Apps from 'slices/Apps/Loadable';
import EngagementUnit from 'slices/EngagementUnit/Loadable';
import Poll from 'slices/Poll/Loadable';
import Quiz from 'slices/Quiz/Loadable';
import Schedule from 'slices/Schedule/Loadable';
import Settings from 'slices/SettingsV2/Loadable';
import Tenant from 'slices/Tenant/Loadable';
import User from 'slices/User/Loadable';
import Categories from 'slices/Category/Loadable';
import Clips from 'slices/Clips/Loadable';
import Collections from 'slices/Collections/Loadable';
import InstagramImport from 'slices/InstagramImportV2/Loadable';
import TextSets from 'slices/StudioAssets/TextSets/Loadable';
import Templates from 'slices/StudioAssets/Templates/Loadable';
import Shapes from 'slices/StudioAssets/Shapes/Loadable';
import Fonts from 'slices/StudioAssets/Fonts/Loadable';
import Stickers from 'slices/StudioAssets/Stickers/Loadable';
import MediaLibrary from 'slices/StudioAssets/MediaLibrary/Loadable';
import ClipsAnalytics from 'slices/ClipsAnalytics';
import Recipes from 'slices/Recipes/Loadable';
import WSCConfig from 'slices/WSCConfig/Loadable';
import Story from 'slices/Story/Loadable';
import InterstitialAd from 'slices/InterstitialAdv2/Loadable';
import ContentBank from 'slices/ContentBank/Loadable';
import ContentSharing from 'slices/ContentSharing/Loadable';
import Locales from 'slices/Locales/Loadable';
import ContentSharingConfig from 'slices/ContentSharingConfig/Loadable';
import isRouteCurrent from 'shared/helpers/isRouteCurrent';
import { CONTENT_SHARING_REQUESTS_COUNT } from 'slices/ContentSharing/types';

const Dashboard = () => {
  const location = useLocation();
  const menuQuery = useGetMenu();
  const {
    currentUser,
    isSystemAdmin,
    isAdmin,
    hasDeveloperPermissions,
    hasSuperAdminPermissions,
  } = useCurrentUser();
  const tenantInfo = useMemo(() => currentUser?.tenantInfo, [currentUser]);
  const roles = useMemo(() => currentUser?.roles || [], [currentUser]);

  const systemAdminTenant = window.location?.host?.includes('systemadmin');

  const sidebarLinks = useMemo(
    () =>
      menuQuery.data?.map(menuItem => ({
        ...menuItem,
        items: menuItem.items.map(item => ({
          ...item,
          ...(item.elementId === 'MenuItem_Requests' && {
            counterKey: CONTENT_SHARING_REQUESTS_COUNT,
          }),
          current: isRouteCurrent(item.url, location.pathname),
        })),
        current: isRouteCurrent(menuItem.url, location.pathname),
      })),
    [location.pathname, menuQuery.data]
  );

  return (
    <GlobalAppWrapper
      logo={<img src={logo} alt="Storyteller" />}
      sidebarLinks={sidebarLinks}
      topbarContentRight={<TopRightNav />}
    >
      {isSystemAdmin && systemAdminTenant && (
        <ErrorBoundary>
          <Switch location={location}>
            <Route path="/tenant" component={Tenant} />
            <Route path="/user" component={User} />
            <Route path="/wsc" component={WSCConfig} />
            <Route
              path="/content-sharing-configuration"
              component={ContentSharingConfig}
            />
            <Route path="*">
              <Redirect to="/tenant" />
            </Route>
          </Switch>
        </ErrorBoundary>
      )}
      {(roles.length > 0 || isSystemAdmin) && !systemAdminTenant && (
        <ErrorBoundary>
          <Switch location={location}>
            <Route path="/schedule" component={Schedule} />
            {isAdmin && <Route path="/user" component={User} />}
            {(hasDeveloperPermissions ||
              hasSuperAdminPermissions ||
              isSystemAdmin) && <Route path="/apps" component={Apps} />}
            <Route path="/stories" component={Story} />
            <Route path="/analytics" component={Analytics} />
            <Route path="/clips-analytics" component={ClipsAnalytics} />
            <Route path="/adexport" component={InterstitialAd} />
            <Route path="/interstitialAd" component={InterstitialAd} />
            <Route path="/engagement-unit" component={EngagementUnit} />
            <Route path="/poll" component={Poll} />
            <Route path="/quiz" component={Quiz} />
            <Route path="/actions" component={Actions} />
            <Route path="/clips" component={Clips} />
            <Route path="/collections" component={Collections} />
            <Route path="/recipes" component={Recipes} />
            <Route path="/content-bank" component={ContentBank} />
            <Route path="/content-sharing" component={ContentSharing} />
            <Route path="/locales" component={Locales} />

            {isAdmin && (
              <Route path="/instagram-accounts" component={InstagramImport} />
            )}
            {isSystemAdmin && (
              <Route path="/categorySettings" component={Settings} />
            )}
            {isSystemAdmin && (
              <Route path="/sharingSettings" component={Settings} />
            )}
            {isSystemAdmin && (
              <Route path="/loginMethods" component={Settings} />
            )}
            {isSystemAdmin && <Route path="/adSettings" component={Settings} />}
            {isSystemAdmin && (
              <Route path="/textSets">
                <TextSets />
              </Route>
            )}
            {isSystemAdmin && (
              <Route path="/mediaLibraries">
                <MediaLibrary />
              </Route>
            )}
            {isSystemAdmin && (
              <Route path="/templates">
                <Templates />
              </Route>
            )}
            {isSystemAdmin && (
              <Route path="/shapes">
                <Shapes />
              </Route>
            )}
            {isSystemAdmin && (
              <Route path="/fonts">
                <Fonts />
              </Route>
            )}
            {isSystemAdmin && (
              <Route path="/stickers">
                <Stickers />
              </Route>
            )}
            {(tenantInfo?.categoriesProvider === 'storyteller' ||
              !roles.length) && (
              <Route path="/categories" component={Categories} />
            )}
            <Route path="*">
              <Redirect to="/stories" />
            </Route>
          </Switch>
        </ErrorBoundary>
      )}
    </GlobalAppWrapper>
  );
};

export default Dashboard;
