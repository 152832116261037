import React from 'react';
import { Loading } from 'components';
import Loadable from 'react-loadable';

export default Loadable({
  loader: () => import('./ContentSharingIndex'),
  loading: () => <Loading visible />,
});

export const AssetsList = Loadable({
  loader: () => import('./containers/ContentSharingAssetsPage'),
  loading: () => <Loading visible />,
});

export const RequestsList = Loadable({
  loader: () => import('./containers/ContentSharingRequestsPage'),
  loading: () => <Loading visible />,
});
