import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

const ToggleButton = ({ id, name, disabled, onChange, value, dataTestId }) => (
  <div className={classnames('toggle-btn', { disabled })}>
    <input
      id={id}
      checked={value}
      className="toggle-input"
      disabled={disabled}
      name={name}
      type="checkbox"
      data-testid={dataTestId || `${id}__toggleInput`}
    />
    <button
      className="toggle-input-btn"
      disabled={disabled}
      onClick={onChange}
      type="button"
      data-testid={`${id}__toggleButton`}
    >
      Toggle
    </button>
  </div>
);

ToggleButton.defaultProps = {
  disabled: false,
  id: '',
  name: '',
  dataTestId: undefined,
};

ToggleButton.propTypes = {
  disabled: PropTypes.bool,
  id: PropTypes.string,
  name: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.bool.isRequired,
  dataTestId: PropTypes.string,
};

export default ToggleButton;
