import React, { useCallback, useMemo } from 'react';
import { ParentSizeModern } from '@visx/responsive';
import {
  XYChart,
  AreaSeries,
  Axis,
  Tooltip,
  Grid,
  GlyphSeries,
  Margin,
} from '@visx/xychart';
import type { TickFormatter, TickLabelProps } from '@visx/axis';
import { Loading } from 'components';
import buildTheme from '../theme';
import { ChartDataPoint } from '../types';
import { RenderTooltip, renderTooltip } from '../ChartTooltip/ChartTooltip';
import { renderGlyph, RenderTooltipGlyph, renderTooltipGlyph } from '../Glyph';
import calculateXNumTicks from '../utils/calculateXNumTicks';
import formatChartNumber from '../utils/formatChartNumber';
import formatXAxisDate from '../utils/formatXAxisDate';
import formatTooltipDate from '../utils/formatTooltipDate';

export interface AreaChartProps {
  /** Height in px */
  height?: number;
  /** Margin */
  margin?: Partial<Margin>;
  /** Array of colors for the lines */
  colorArray?: string[];
  /** An array of objects describing the data. Each object describes the data at a point on the X-axis. */
  data: Array<ChartDataPoint>;
  /** Show show loading spinner */
  loading?: boolean;
  /** Hide X axis */
  xHide?: boolean;
  /** Approximate number of ticks on X axis */
  xNumTicks?: number;
  /** Outer padding on X axis */
  xScalePaddingOuter?: number;
  /** Tick formatter for Y axis */
  yTickFormatter?: TickFormatter<number>;
  /** Approximate number of ticks on Y axis */
  yNumTicks?: number;
  /** Tick values on Y axis */
  yTickValues?: number[];
  /** Automatically prefix units (eg. k or M) */
  autoFormatYTicks?: boolean;
  /** Approximate number of ticks on Grid */
  gridNumTicks?: number;
  /** Calculate X axis `numTicks` based on chart width. Defaults to `true`. Takes precedence over `xNumTicks` */
  responsiveXTicks?: boolean;
  /** Render additional Glyph series */
  renderGlyphs?: boolean;
  /** Render tooltip glyphs */
  showTooltipGlyphs?: boolean;
  customRenderTooltip?: RenderTooltip;
  customRenderTooltipGlyph?: RenderTooltipGlyph;
}

const xTickLabelProps: TickLabelProps<ChartDataPoint['values']> = () => ({
  width: 90,
  dy: 16,
});

const AreaChart: React.FC<AreaChartProps> = ({
  height,
  margin,
  colorArray,
  data,
  loading,
  xScalePaddingOuter,
  xHide,
  xNumTicks,
  yTickFormatter,
  yNumTicks,
  yTickValues,
  autoFormatYTicks = true,
  gridNumTicks,
  customRenderTooltip,
  customRenderTooltipGlyph,
  showTooltipGlyphs = true,
  renderGlyphs,
  responsiveXTicks = true,
}) => {
  const theme = useMemo(() => buildTheme({ colors: colorArray }), [colorArray]);

  const formattedData = useMemo(() => data.map(x => x.values), [data]);

  const labels = data?.[0]
    ? Object.keys(data[0].values).filter(
        property => property !== 'name' // Remove key for name
      )
    : [];

  const yAxisTickFormatter = useMemo(() => {
    if (yTickFormatter) {
      return yTickFormatter;
    }
    if (autoFormatYTicks) {
      return formatChartNumber;
    }

    return undefined;
  }, [autoFormatYTicks, yTickFormatter]);

  const renderTooltipFunc = useCallback(
    tooltipData => {
      if (customRenderTooltip) {
        return customRenderTooltip(tooltipData);
      }
      return renderTooltip({
        ...tooltipData,
        formatter: formatTooltipDate,
      });
    },
    [customRenderTooltip]
  );

  return (
    <ParentSizeModern className="graph-container" debounceTime={10}>
      {({ width: visWidth, height: visHeight }) => {
        const xAxisNumTicks = responsiveXTicks
          ? calculateXNumTicks(visWidth, formattedData)
          : xNumTicks;

        return (
          <>
            <Loading visible={loading} maskContainer />
            <XYChart
              width={visWidth}
              height={height ?? visHeight}
              xScale={{
                type: 'band',
                paddingOuter: xScalePaddingOuter,
              }}
              yScale={{ type: 'linear' }}
              theme={theme}
              margin={{ top: 10, right: 40, left: 60, bottom: 40, ...margin }}
            >
              {!xHide && (
                <Axis
                  orientation="bottom"
                  numTicks={xAxisNumTicks}
                  tickLabelProps={xTickLabelProps}
                  tickFormat={formatXAxisDate}
                />
              )}
              <Axis
                orientation="left"
                strokeWidth={0}
                numTicks={yNumTicks}
                tickValues={yTickValues}
                tickFormat={yAxisTickFormatter}
              />
              <Grid rows columns={false} numTicks={gridNumTicks} />

              {labels.map(label => (
                <AreaSeries
                  key={label}
                  dataKey={label}
                  data={formattedData}
                  xAccessor={d => d?.name}
                  yAccessor={d => (d as any)?.[label]}
                  fillOpacity={0.3}
                />
              ))}

              {renderGlyphs &&
                labels.map(label => (
                  <GlyphSeries
                    key={`${label}-glyph`}
                    dataKey={label}
                    data={formattedData}
                    xAccessor={d => d?.name}
                    yAccessor={d => (d as any)?.[label]}
                    renderGlyph={renderGlyph}
                  />
                ))}

              <Tooltip
                snapTooltipToDatumX
                snapTooltipToDatumY
                showVerticalCrosshair
                showSeriesGlyphs={showTooltipGlyphs}
                unstyled
                applyPositionStyle
                renderTooltip={renderTooltipFunc}
                renderGlyph={customRenderTooltipGlyph ?? renderTooltipGlyph}
              />
            </XYChart>
          </>
        );
      }}
    </ParentSizeModern>
  );
};

export default AreaChart;
