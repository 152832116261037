import React from 'react';
import Loadable from 'react-loadable';
import { Loading } from '@stormideas/react-sdk';

export default Loadable({
  loader: () => import('./ScheduleRouter'),
  loading: () => <Loading visible />,
});

export const CategorySchedule = Loadable({
  loader: () => import('./CategorySchedule'),
  loading: () => <Loading visible />,
});

export const List = Loadable({
  loader: () => import('./ScheduleList'),
  loading: () => <Loading visible />,
});
