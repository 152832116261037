import React, { PropsWithChildren } from 'react';
import classnames from 'classnames';
import previewFrame from './img/iPhoneX.svg';
import './DeviceWrapper.scss';

export type DeviceWrapperProps = PropsWithChildren<{
  device?: 'iPhoneX';
  withSafeArea?: boolean;
}>;

/** A wrapper for previewing content in a device frame.
 * Requires a node for the content.
 */
const DeviceWrapper: React.FC<DeviceWrapperProps> = props => {
  const { children, device = 'iPhoneX', withSafeArea = true } = props;

  return (
    <div className="device-wrapper-container">
      <div className="device-wrapper">
        {device === 'iPhoneX' && (
          <img
            alt=""
            className="frame"
            height="846"
            src={previewFrame}
            width="417"
          />
        )}
        <div
          className={classnames('preview-container', {
            'preview-container--with-safe-area': withSafeArea,
          })}
        >
          {children}
        </div>
      </div>
    </div>
  );
};

export default DeviceWrapper;
