import { useMemo } from 'react';
import Tenants from './tenants';

export const DEFAULT_TENANT_ID = '00000000-0000-0000-0000-000000000000';

const useCurrentTenant = () =>
  useMemo(() => {
    const { hostname } = window.location;
    const currentTenantName = hostname.split('.')[0];
    return {
      currentTenant: { name: currentTenantName as keyof typeof Tenants },
    };
  }, []);

export default useCurrentTenant;
