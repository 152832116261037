import { getStringWidth } from '@visx/text';
import { ChartDataPoint } from '../types';

const calculateXNumTicks = (
  width: number,
  data: Array<ChartDataPoint['values']>
): number | undefined => {
  if (width === 0) {
    return undefined;
  }
  const labelPadding = 64;

  const firstLabel = data[0].name;
  const labelWidth = getStringWidth(firstLabel, {
    fontSize: '11px',
    fontWeight: 500,
    fontFamily: 'Euclid Circular B',
  });
  if (labelWidth === null) {
    return undefined;
  }

  return Math.floor(width / (labelWidth + labelPadding));
};

export default calculateXNumTicks;
