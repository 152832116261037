import React, {
  useRef,
  useState,
  useMemo,
  useCallback,
  type SyntheticEvent,
} from 'react';
import ReactCrop, {
  centerCrop,
  makeAspectCrop,
  type ReactCropProps,
  type PixelCrop,
  type PercentCrop,
} from 'react-image-crop';
import type { ImageSize } from 'slices/Clips/types';

import './ImageCropper.scss';

/**
 * An image cropper component
 * (useful for thumbnails)
 */

interface ImageCropperProps {
  aspect?: ReactCropProps['aspect'];
  crop: PixelCrop | PercentCrop;
  imageUrl: string;
  onChange: (newPercentageCrop: PercentCrop, imageSize: ImageSize) => void;
}

const ImageCropper = ({
  aspect,
  imageUrl,
  crop,
  onChange: setSelectedCoordinates,
}: ImageCropperProps) => {
  const imageRef = useRef<HTMLImageElement | null>(null);
  const [imageSize, setImageSize] = useState<ImageSize | null>(null);

  const getPercentageCoordinatesFromPixels = useCallback(
    (cropSizeInPixels: number, maxSizeInPixels: number) =>
      (100 * cropSizeInPixels) / maxSizeInPixels,
    []
  );

  const percentageCrop: PercentCrop = useMemo(
    () =>
      crop.unit === '%'
        ? crop
        : {
            unit: '%',
            x: imageSize
              ? getPercentageCoordinatesFromPixels(crop.x, imageSize.width)
              : 0,
            y: imageSize
              ? getPercentageCoordinatesFromPixels(crop.y, imageSize.height)
              : 0,
            width: imageSize
              ? getPercentageCoordinatesFromPixels(crop.width, imageSize.width)
              : 100,
            height: imageSize
              ? getPercentageCoordinatesFromPixels(
                  crop.height,
                  imageSize.height
                )
              : 100,
          },
    [crop, getPercentageCoordinatesFromPixels, imageSize]
  );

  const onImageLoad = useCallback(
    (loadEvent: SyntheticEvent<HTMLImageElement>) => {
      const { naturalWidth: imageWidth, naturalHeight: imageHeight } =
        loadEvent.currentTarget;

      setImageSize({ width: imageWidth, height: imageHeight });

      if (aspect) {
        const initialPercentageCrop = centerCrop(
          makeAspectCrop(
            {
              unit: '%',
              width: 100,
              height: 100,
            },
            aspect,
            imageWidth,
            imageHeight
          ),
          imageWidth,
          imageHeight
        );

        setSelectedCoordinates(initialPercentageCrop, {
          width: imageWidth,
          height: imageHeight,
        });
      }
    },
    [aspect, setSelectedCoordinates]
  );

  return (
    <div className="image-cropper">
      <ReactCrop
        keepSelection
        className="cropping-area"
        aspect={aspect}
        crop={percentageCrop}
        onChange={(newPixelCrop, newPercentageCrop) =>
          imageSize ? setSelectedCoordinates(newPercentageCrop, imageSize) : {}
        }
        maxWidth={imageSize?.width}
        maxHeight={imageSize?.height}
      >
        <img
          src={imageUrl}
          alt="Thumbnail"
          ref={imageRef}
          onLoad={onImageLoad}
        />
      </ReactCrop>
    </div>
  );
};

export default ImageCropper;
