import URL_PARAMS from 'components/v2/DataTable/data/urlParams';

const handleURLSearchQueryParam = (searchText?: string): void => {
  const url = new URL(window.location.href);
  if (searchText) {
    url.searchParams.set(URL_PARAMS.textSearch, `${searchText}`);
  } else {
    url.searchParams.delete(URL_PARAMS.textSearch);
  }

  const urlHasToChange = url.toString() !== window.location.href;
  if (urlHasToChange) window.history.replaceState(null, '', url);
};

export default handleURLSearchQueryParam;
