import React, {
  PropsWithChildren,
  useCallback,
  useEffect,
  useMemo,
  useState,
  type RefObject,
} from 'react';
import cx from 'classnames';
import {
  components,
  GroupBase,
  SelectInstance,
  ValueContainerProps,
  Props as SelectProps,
} from 'react-select';
import type { SelectFieldOptionObject } from './types';

const useCustomSelectFieldValue = <
  Option extends SelectFieldOptionObject,
  IsMulti extends boolean = false,
  Group extends GroupBase<Option> = GroupBase<Option>
>({
  displayValue,
  selectRef,
}: {
  displayValue?: string;
  selectRef: RefObject<SelectInstance<Option, IsMulti, Group>>;
}): SelectProps<Option, IsMulti, GroupBase<Option>> => {
  const [dropdownIsOpen, setDropdownIsOpen] = useState<boolean>(false);

  const onShowDropdown = useCallback(() => {
    setDropdownIsOpen(true);
    selectRef.current?.focus();
  }, [selectRef]);

  const onHideDropdown = useCallback(() => {
    setDropdownIsOpen(false);
    selectRef.current?.blur();
  }, [selectRef]);

  const customValueContainer = useMemo(
    () =>
      displayValue
        ? (
            valueContainerProps: PropsWithChildren<
              ValueContainerProps<Option, IsMulti, GroupBase<Option>>
            >
          ) => (
            <components.ValueContainer
              className={cx(
                valueContainerProps.className,
                'value-container--is-custom'
              )}
              {...valueContainerProps}
            >
              <input
                onFocus={onShowDropdown}
                value={displayValue}
                size={displayValue.length}
              />
            </components.ValueContainer>
          )
        : undefined,
    [displayValue, onShowDropdown]
  );

  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      const clickTarget = event.target as HTMLElement;

      if (selectRef.current) {
        const { controlRef, menuListRef } = selectRef.current;

        const clickedInsideInput = controlRef?.contains(clickTarget);
        const clickedInsideDropdown = menuListRef?.contains(clickTarget);

        if (!clickedInsideInput && !clickedInsideDropdown) {
          onHideDropdown();
        }
      }
    }

    if (dropdownIsOpen) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }

    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, [dropdownIsOpen, onHideDropdown, selectRef]);

  if (!displayValue) {
    return {};
  }

  return {
    components: { ValueContainer: customValueContainer },
    menuIsOpen: dropdownIsOpen,
    onMenuOpen: onShowDropdown,
    onMenuClose: onHideDropdown,
  };
};

export default useCustomSelectFieldValue;
