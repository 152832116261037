import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMinusCircle } from '@fortawesome/pro-solid-svg-icons';
import Button from 'components/Button';

export interface FieldItemsProps {
  Component: React.FC<FieldItemComponentProps>;
  name: string;
  values: any;
  remove: (index: number) => void;
}

export interface FieldItemComponentProps {
  index: number;
  name: string;
}

const SingleFieldItems = ({
  Component,
  name,
  values,
  remove,
  ...props
}: FieldItemsProps) => (
  <>
    {values.map((_: any, index: number) => (
      <div className="single-field" key={`${name + index}`}>
        <Component {...props} index={index} name={`${name}[${index}]`} />
        <Button
          data-cy="singleItemRemove"
          aria-label="Remove"
          className="btn-light icon fieldset-right-icon rounded-pill"
          buttonStyle="link"
          onClick={() => {
            remove(index);
            (document.activeElement as HTMLElement)?.blur();
          }}
          outline
          color="danger"
          title="Remove"
          icon={
            <FontAwesomeIcon icon={faMinusCircle} className="text-danger" />
          }
        />
      </div>
    ))}
  </>
);

export default SingleFieldItems;
