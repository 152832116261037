import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'formik';
import renderDropZoneField from './DropZoneField';
import './DropZone.scss';

/** A component for uploading file(s) */

const wrapValue = value => {
  if (!value) return value;

  if (!Array.isArray(value)) {
    return [value];
  }

  return value;
};

const DropZone = props => {
  const {
    accept,
    aspectRatio,
    autoPlayPreview,
    className,
    cropPreview,
    customFilePreview,
    removeFileCallback,
    disabled,
    dropZoneShape,
    editButtonProps,
    editButtonContents,
    emptyMessage,
    fieldProps,
    id,
    initialValue,
    maxSize,
    multiple,
    name,
    noClick,
    onChange,
    onDropRejected,
    previewShape,
    processing,
    showEditButton,
    showFileOverlay,
    showRemoveButton,
    isFileValid,
    isCustomFileTypeValidationOn,
  } = props;

  return (
    <Field
      name={name}
      id={id || name}
      render={({ form, field }) =>
        renderDropZoneField({
          isFileValid,
          isCustomFileTypeValidationOn,
          name,
          accept,
          aspectRatio,
          autoPlayPreview,
          className,
          cropPreview,
          customFilePreview,
          disabled,
          dropZoneShape,
          editButtonProps,
          editButtonContents,
          emptyMessage,
          fieldProps,
          initialValue,
          input: {
            ...field,
            value: wrapValue(field.value, multiple),
            onChange: files => {
              if (multiple || !files || !Array.isArray(files)) {
                onChange(files);
                return;
              }
              const [file] = files;
              form.setFieldValue(name, file);
              onChange(file);
            },
          },
          maxSize,
          multiple,
          noClick,
          removeFileCallback,
          onDropRejected,
          previewShape: previewShape || dropZoneShape,
          processing,
          showEditButton,
          showFileOverlay,
          showRemoveButton,
        })
      }
    />
  );
};

DropZone.propTypes = {
  /** Array of accepted mime types */
  accept: PropTypes.arrayOf(PropTypes.string),
  /** Set a custom aspect ratio for the preview, less than 1 is landscape, more than 1 is portrait */
  aspectRatio: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  /** Autoplay video previews */
  autoPlayPreview: PropTypes.bool,
  /** Custom style class */
  className: PropTypes.string,
  /** Crop the preview image to better match server side processing */
  cropPreview: PropTypes.bool,
  /** Custom File preview component, will have access to the following props:
   * cropPreview
   * file
   * previewShape
   * showRemoveButton
   * showFileOverlay */
  customFilePreview: PropTypes.node,
  /** Fired when a file is removed, passed event and file index */
  removeFileCallback: PropTypes.func,
  /** Disabled input */
  disabled: PropTypes.bool,
  /** Function for handling what happens when files are rejected */
  dropZoneShape: PropTypes.oneOf([
    null,
    'circle',
    'square',
    'portrait',
    'landscape',
    '2x3',
    '4x5',
  ]),
  /** Custom props for edit button */
  editButtonProps: PropTypes.shape(),
  /** Content for edit button, defaults to "Edit" */
  editButtonContents: PropTypes.node,
  /** Empty message */
  emptyMessage: PropTypes.node,
  /** Props to be passed directly to Dropzone field, including events for validation */
  fieldProps: PropTypes.shape({}),
  /** Input & ID */
  id: PropTypes.string,
  /** Set initial value of dropzone field, useful for editing forms the user has previously uploaded an image to */
  initialValue: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      poster: PropTypes.string,
      rotation: PropTypes.number,
      src: PropTypes.string,
      type: PropTypes.string,
    })
  ),
  /** Maximum individual file size in bytes */
  maxSize: PropTypes.number,
  /** Can upload more than one file */
  multiple: PropTypes.bool,
  /** Input name & default ID if nopne is provided */
  name: PropTypes.string.isRequired,
  /** Prevents file picker opening when clicking on the dropzone area if true */
  noClick: PropTypes.bool,
  /** onChange function */
  onChange: PropTypes.func,
  /** Handle when the user tries to upload the wrong file type */
  onDropRejected: PropTypes.func,
  /** For implying the shape an uploaded asset will be displayed in */
  previewShape: PropTypes.oneOf([
    null,
    'circle',
    'square',
    'portrait',
    'landscape',
    '2x3',
    '4x5',
  ]),
  /** Generic processing state */
  processing: PropTypes.bool,
  /** Render an explicit edit button to open file picker */
  showEditButton: PropTypes.bool,
  /** Show file overlay with filename and delete button on hover */
  showFileOverlay: PropTypes.bool,
  /** Hide remove buttons on file previews */
  showRemoveButton: PropTypes.bool,
  isFileValid: PropTypes.bool,
  isCustomFileTypeValidationOn: PropTypes.bool,
};

DropZone.defaultProps = {
  accept: [
    'image/jpg',
    'image/jpeg',
    'image/png',
    'video/mp4',
    'video/quicktime',
  ],
  aspectRatio: null,
  autoPlayPreview: false,
  className: '',
  cropPreview: true,
  customFilePreview: null,
  removeFileCallback: null,
  disabled: false,
  dropZoneShape: null,
  editButtonProps: null,
  editButtonContents: 'Edit',
  emptyMessage: 'Drop a file here or click to upload',
  fieldProps: {
    accept: ['image/jpg', 'image/jpeg', 'image/png', 'video/mp4'],
  },
  id: PropTypes.string,
  initialValue: null,
  maxSize: 64000000,
  multiple: false,
  noClick: false,
  onChange: () => {},
  onDropRejected: null,
  previewShape: null,
  processing: false,
  showEditButton: false,
  showFileOverlay: true,
  showRemoveButton: true,
  isFileValid: false,
  isCustomFileTypeValidationOn: false,
};

export default DropZone;
