import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'formik';
import { startCase } from 'lodash';
import { processValidate } from 'infrastructure/form';
import renderChromeColorPickerField from './ChromeColorPicker';

import './ColorPicker.scss';

/** A Color picker input */

const ColorPicker = props => {
  const { name, validate, defaultValue, onChange, pickerType } = props;
  const renderPickerField = {
    chrome: renderChromeColorPickerField,
  }[pickerType];

  return (
    <Field
      {...props}
      name={name}
      validate={processValidate(startCase(name), validate, props)}
      render={({ field, form, ...p }) =>
        renderPickerField({
          input: {
            ...field,
            onChange: value => {
              form.setFieldValue(name, value.hex);
              onChange(value.hex);
            },
            value: defaultValue,
          },
          ...p,
          meta: {},
        })
      }
    />
  );
};

ColorPicker.propTypes = {
  /** Input name & ID */
  name: PropTypes.string,
  /** Validation array */
  validate: PropTypes.arrayOf(PropTypes.shape({})),
  /** Default hex value */
  defaultValue: PropTypes.string,
  /** onChange handler */
  onChange: PropTypes.func,
  /** Picker type */
  pickerType: PropTypes.oneOf(['chrome', 'sketch', 'block']),
};

ColorPicker.defaultProps = {
  name: '',
  validate: [],
  defaultValue: '#70bbfd',
  onChange: () => {},
  pickerType: 'chrome',
};

export default ColorPicker;
