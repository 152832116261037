import React from 'react';
import { Link } from 'react-router-dom';
import { Col, Row } from 'reactstrap';
import { Button } from 'components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSignIn } from '@fortawesome/pro-regular-svg-icons';
import PropTypes from 'prop-types';
import './Error404.scss';

/** 404 page */

const Error404 = props => {
  const { image } = props;
  return (
    <div className="error-404">
      <Row>
        <Col
          xs="auto"
          md={{ size: 8, offset: 2 }}
          lg={{ size: 6, offset: 3 }}
          xl={{ size: 4, offset: 2 }}
        >
          <div className="message">
            {image && <img src={image} alt="404" className="header-image" />}
            <h1>Page not found</h1>
            <p>
              Sorry, the page you’re looking for doesn’t exist.
              <br />
              Please log in and try again.
            </p>
            <Button
              color="primary"
              icon={<FontAwesomeIcon icon={faSignIn} />}
              size="lg"
              tag={Link}
              to="/login?returnUrl=/404"
            >
              Log in
            </Button>
          </div>
        </Col>
      </Row>
    </div>
  );
};

Error404.propTypes = {
  image: PropTypes.string,
};

Error404.defaultProps = {
  image: null,
};

export default Error404;
