import { Loading } from 'components';
import React from 'react';
import Loadable from 'react-loadable';

export default Loadable({
  loader: () => import('./InstagramImportIndex'),
  loading: () => <Loading visible />,
});

export const InstagramAccountsList = Loadable({
  loader: () => import('./InstagramImportList'),
  loading: () => <Loading visible />,
});
