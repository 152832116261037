import React, { useCallback } from 'react';
import { Button } from 'components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/pro-light-svg-icons';
import { FileInfo } from 'infrastructure/uploads/UploadsContext';
import OverallProgress from './OverallProgress';

const getOverallProgress = (files: readonly FileInfo[]) => {
  const uploadingFile = files.find(file => file.status === 'uploading');
  const currentUploadingBatchId = uploadingFile?.batchId ?? '';

  const currentBatchFiles = files.filter(
    file => file.batchId === currentUploadingBatchId
  );

  const totalFileSize = currentBatchFiles.reduce(
    (accumulator, file) => accumulator + file.size,
    0
  );

  const totalUploaded = currentBatchFiles.reduce(
    (accumulator, file) => accumulator + file.bytesUploaded,
    0
  );

  return Number((totalUploaded / totalFileSize) * 100);
};

const getTitle = (
  uploadingFiles: number,
  failedFiles: number,
  hasFailedFiles: boolean
) =>
  // eslint-disable-next-line no-nested-ternary
  uploadingFiles === 0
    ? 'Upload successful!'
    : hasFailedFiles
    ? `Failed to upload ${failedFiles} ${failedFiles > 1 ? 'files' : 'file'}!`
    : `Uploading ${uploadingFiles} ${uploadingFiles > 1 ? 'files' : 'file'}…`;

export interface UploadSummaryHeaderProps {
  files: readonly FileInfo[];
  showCancelButton: boolean;
  cancelFunc?: () => void;
  clearUploads?: () => void;
}

const UploadSummaryHeader: React.FC<UploadSummaryHeaderProps> = ({
  cancelFunc,
  clearUploads,
  files,
  showCancelButton,
}) => {
  const uploadedFilesCount = files.filter(
    file => file.status === 'uploaded'
  ).length;

  const failedFilesCount = files.filter(
    file => file.status === 'failed'
  ).length;

  const uploadingFilesCount = files.length - uploadedFilesCount;

  const allFilesUploaded = files.length === uploadedFilesCount;

  const hasFailedFiles = failedFilesCount > 0;

  const overallProgress = allFilesUploaded ? 100 : getOverallProgress(files);

  const uploadsComplete = overallProgress === 100 || allFilesUploaded;

  const onCancel: React.MouseEventHandler = useCallback(
    e => {
      e.stopPropagation();
      cancelFunc?.();
      clearUploads?.();
    },
    [cancelFunc, clearUploads]
  );

  return (
    <div className="upload-summary-header">
      {cancelFunc && showCancelButton && (
        <Button
          aria-label="Cancel Upload"
          buttonStyle="link"
          className="cancel-btn unstyled-button"
          color="light"
          onClick={onCancel}
          title="Cancel Upload"
          icon={<FontAwesomeIcon icon={faTimes} fixedWidth />}
        />
      )}
      <h4 className="title">
        {getTitle(uploadingFilesCount, failedFilesCount, hasFailedFiles)}
      </h4>
      <OverallProgress
        hasFailedFiles={hasFailedFiles}
        overallProgress={overallProgress}
        uploadsComplete={uploadsComplete}
      />
    </div>
  );
};

export default UploadSummaryHeader;
