import React, { ReactElement } from 'react';
import { PopoverBody } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faQuestionCircle } from '@fortawesome/pro-solid-svg-icons';
import variables from 'scss/1-settings/colors.scss';

import Popover, { type PopoverProps } from './Popover';

interface PopoverButtonProps extends Partial<PopoverProps> {
  id: string;
  content?: string | ReactElement;
  color?: string;
}

const PopoverButton: React.FC<PopoverButtonProps> = ({
  id,
  content = '',
  color = variables['gray-400'],
  ...popoverProps
}) => (
  <Popover
    {...popoverProps}
    buttonClassName="unstyled-button"
    id={`popover-${id}`}
    trigger={
      <FontAwesomeIcon
        icon={faQuestionCircle}
        aria-label="More information"
        aria-hidden="false"
        color={color}
      />
    }
  >
    <PopoverBody>
      {typeof content === 'string' ? <p>{content}</p> : content}
    </PopoverBody>
  </Popover>
);

export default PopoverButton;
