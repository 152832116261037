import React from 'react';
import classnames from 'classnames';
import { CSSTransition } from 'react-transition-group';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/pro-solid-svg-icons';

interface Props {
  className?: string;
  maskContainer?: boolean;
  delayShow?: boolean;
  minHeight?: number | string;
  visible?: boolean;
  size?: 'sm' | 'lg';
}
const Loading = ({
  delayShow = false,
  minHeight,
  maskContainer = false,
  size,
  visible,
  className,
}: Props) => (
  <CSSTransition in={visible} classNames="loading" timeout={200} unmountOnExit>
    <div
      className={classnames('loading-container text-center', className, {
        'mask-container': maskContainer,
        'delay-show': delayShow,
        [`loading-${size}`]: !!size, // will produce e.g. "loading-sm" class name when size is 'sm'
      })}
      style={{
        minHeight,
      }}
    >
      <div className="load-icon-wrap">
        <FontAwesomeIcon icon={faSpinner} className="load-icon" spin />
      </div>
    </div>
  </CSSTransition>
);

export default Loading;
