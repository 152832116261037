import React from 'react';
import { Loading } from 'components';
import Loadable from 'react-loadable';

export default Loadable({
  loader: () => import('./InterstitialAdIndex'),
  loading: () => <Loading visible />,
});

export const Form = Loadable({
  loader: () => import('./containers/InterstitialAdFormPageV2'),
  loading: () => <Loading visible />,
});

export const Preview = Loadable({
  loader: () => import('./InterstitialAdPreviewPage'),
  loading: () => <Loading visible />,
});

export const Analytics = Loadable({
  loader: () => import('./InterstitialAdAnalyticsPage'),
  loading: () => <Loading visible />,
});

export const List = Loadable({
  loader: () => import('./InterstitialAdList'),
  loading: () => <Loading visible />,
});

export const AdExport = Loadable({
  loader: () => import('./InterstitialAdExportPage'),
  loading: () => <Loading visible />,
});
