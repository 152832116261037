import React, { useCallback, useState } from 'react';
import { CSSTransition } from 'react-transition-group';
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faExternalLinkSquareAlt,
  faQuestionCircle,
} from '@fortawesome/pro-solid-svg-icons';
import { faSignOut } from '@fortawesome/pro-regular-svg-icons';
import { UserAvatar } from 'components';
import useCurrentUser from 'infrastructure/currentUser/useCurrentUser';
import useLogout from 'slices/AccountV2/hooks/useLogout';

const TopRightNav: React.FC<{}> = () => {
  const [helpMenuOpen, setHelpMenuOpen] = useState(false);
  const [profileMenuOpen, setProfileMenuOpen] = useState(false);

  const { currentUser } = useCurrentUser();
  const logout = useLogout({});

  const toggleHelpMenu = () => {
    setHelpMenuOpen(prevState => !prevState);
  };

  const toggleProfileMenu = () => {
    setProfileMenuOpen(prevState => !prevState);
  };

  const handleLogout = useCallback(() => {
    logout.mutate();
  }, [logout]);

  // eslint-disable-next-line max-len
  const mailtoLink = `mailto:support@getstoryteller.com?subject=Storyteller%20CMS%20Support&body=Hi%20Storyteller%20Support%2C%0D%0A%0D%0A--%3E%20Replace%20this%20line%20with%20a%20description%20of%20the%20issue.%0D%0A%0D%0A%0D%0A---%0D%0A%0D%0APlease%20don't%20delete%20anything%20below%20this%20line%2C%20it%20will%20help%20us%20debug%20the%20issue.%0D%0A%0D%0A${document.title}%20%7C%20${window.location}%20%7C%20${navigator.userAgent}`;

  return (
    <>
      <Dropdown
        isOpen={helpMenuOpen}
        toggle={toggleHelpMenu}
        className="topbar-dropdown-nav"
      >
        <DropdownToggle
          className="topbar-dropdown-trigger"
          onClick={event => event.currentTarget.blur()}
          title="Support"
        >
          <FontAwesomeIcon aria-label="Help Menu" icon={faQuestionCircle} />
        </DropdownToggle>
        <CSSTransition in={helpMenuOpen} classNames="dropdown" timeout={200}>
          <DropdownMenu right>
            <DropdownItem header>Support</DropdownItem>
            <DropdownItem
              href="https://docs.getstoryteller.com/documents/user-guide"
              target="_blank"
            >
              User Guide{' '}
              <FontAwesomeIcon
                icon={faExternalLinkSquareAlt}
                className="text-muted"
              />
            </DropdownItem>
            <DropdownItem href={mailtoLink} target="_blank">
              Report an issue
            </DropdownItem>
          </DropdownMenu>
        </CSSTransition>
      </Dropdown>
      <Dropdown
        isOpen={profileMenuOpen}
        toggle={toggleProfileMenu}
        className="topbar-dropdown-nav"
      >
        <DropdownToggle
          className="topbar-dropdown-trigger"
          onClick={event => event.currentTarget.blur()}
          title="Account"
        >
          <UserAvatar userName={currentUser && currentUser.name} />
        </DropdownToggle>
        <CSSTransition in={profileMenuOpen} classNames="dropdown" timeout={200}>
          <DropdownMenu right>
            <DropdownItem onClick={handleLogout}>
              <FontAwesomeIcon icon={faSignOut} className="highlight-icon" />{' '}
              Log Out
            </DropdownItem>
          </DropdownMenu>
        </CSSTransition>
      </Dropdown>
    </>
  );
};

export default TopRightNav;
