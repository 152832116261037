import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { compose, withProps, lifecycle } from 'recompose';
import browserHistory from 'infrastructure/history';
import { connect } from 'formik';
import classnames from 'classnames';
import '@stormideas/react-dates/initialize';
import { DayPickerRangeController } from '@stormideas/react-dates';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCalendarAlt,
  faMinusCircle,
  faCheck,
  faTrashAlt,
} from '@fortawesome/pro-regular-svg-icons';
import { Tabs } from '@stormideas/react-sdk';

import Activity from 'components/Activity';
import Button from 'components/Button';
import { toast } from 'components';
import { FormRow, TextField } from 'components/Form';

import './FormActions.scss';
import moment from 'moment';
import UnscheduleModal from './UnscheduleModal';
import DeleteModal from './DeleteModal';

import { validateStartTime, validateEndTime } from './validation';

const enhance = compose(
  connect,
  withProps(props => {
    const {
      formik: { status },
    } = props;

    if (status) {
      return {
        changingScheduledStatus: status.changingScheduledStatus,
      };
    }

    return { changingScheduledStatus: false };
  }),
  lifecycle({
    // eslint-cisable-next-line camelcase
    UNSAFE_componentWillReceiveProps(nextProps) {
      if (this.props.isSubmitting && !nextProps.isSubmitting) {
        if (
          this.props.changingScheduledStatus &&
          nextProps.changingScheduledStatus
        ) {
          nextProps.formik.setStatus({
            ...nextProps.formik.status,
            changingScheduledStatus: undefined,
          });
        }
      }
    },
  })
);
class FormActions extends Component {
  constructor(props) {
    super(props);
    const { formik } = props;

    let currentStartDate;
    let currentRemoveDate;

    if (formik.values) {
      currentStartDate = formik.values.publishAtDate;
      currentRemoveDate = formik.values.removeAtDate;
    }

    this.state = {
      focusedInput: props.autoFocusEndDate ? 'endDate' : 'startDate',
      startDate: (currentStartDate && moment(currentStartDate)) || null,
      endDate: (currentRemoveDate && moment(currentRemoveDate)) || null,
      showUnscheduleModal: false,
      showDeleteModal: false,
    };

    this.onDatesChange = this.onDatesChange.bind(this);
    this.onFocusChange = this.onFocusChange.bind(this);
    this.onCancel = this.onCancel.bind(this);
    this.onUnschedule = this.onUnschedule.bind(this);
    this.startTimeDisabled = this.startTimeDisabled.bind(this);
    this.endTimeDisabled = this.endTimeDisabled.bind(this);
  }

  componentDidUpdate(prevProps) {
    const { formik } = this.props;
    const { values } = formik;

    if (prevProps.formik.values !== values) {
      let currentStartDate;
      let currentRemoveDate;

      if (values) {
        currentStartDate = values.publishAtDate;
        currentRemoveDate = values.removeAtDate;
      }

      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({
        startDate: (currentStartDate && moment(currentStartDate)) || null,
        endDate: (currentRemoveDate && moment(currentRemoveDate)) || null,
      });
    }
  }

  onDatesChange({ startDate, endDate }) {
    const { formik } = this.props;

    // eslint-disable-next-line react/destructuring-assignment
    const currentStartDate = this.state.startDate;
    // eslint-disable-next-line react/destructuring-assignment
    const currentEndDate = this.state.endDate;

    if (currentStartDate === null && startDate !== null) {
      formik.setFieldValue('publishAtTime', '00:00');
    }

    if (currentEndDate === null && endDate !== null) {
      formik.setFieldValue('removeAtTime', '23:59');
    }

    if (currentEndDate !== null && endDate === null) {
      formik.setFieldValue('removeAtTime', '--:--');
    }

    formik.setFieldValue('publishAtDate', startDate);
    formik.setFieldValue('removeAtDate', endDate);

    formik.setFieldTouched('removeAtDate');

    this.setState({
      startDate,
      endDate,
    });
  }

  onFocusChange(focusedInput) {
    this.setState({ focusedInput: !focusedInput ? 'startDate' : focusedInput });
  }

  onUnschedule() {
    const { formik, handleSubmit } = this.props;
    formik.setFieldValue('publishAtDate', null);
    formik.setFieldValue('removeAtDate', null);
    formik.setFieldValue('publishAtTime', null);
    formik.setFieldValue('removeAtTime', null);
    handleSubmit();

    this.setState({
      showUnscheduleModal: false,
    });
  }

  onCancel() {
    this.setState({
      showUnscheduleModal: false,
      showDeleteModal: false,
    });
  }

  startTimeDisabled = values => {
    if (values && values.publishAtDate) {
      return false;
    }
    return true;
  };

  endTimeDisabled = values => {
    if (values && values.removeAtDate) {
      return false;
    }
    return true;
  };

  render() {
    const {
      focusedInput,
      startDate,
      endDate,
      showUnscheduleModal,
      showDeleteModal,
    } = this.state;
    const {
      api,
      activity,
      alert,
      changingScheduledStatus,
      children,
      current,
      extraActions,
      formik: { dirty, values, errors, touched },
      id,
      isSubmitting,
      nestedFieldName,
      preview,
      previewShow,
      setPreviewShow,
      showScheduling,
      schedulingRequired,
      isScheduled,
      isPublished,
      submitText,
      tabName,
      isValid,
      path,
      showDelete,
      onSubmitClicked,
      showSubmit,
      initialVisibleMonth,
    } = this.props;
    const onDelete = async () => {
      const title = current.title || current.name;

      // eslint-disable-next-line no-underscore-dangle
      await api._delete(id).then(({ error }) => {
        if (!error) {
          toast.success(`“${title}” has been deleted.`);
          browserHistory.push(path);
        }
      });
    };
    return (
      <div>
        <Tabs
          border="bottom"
          className="form-actions fieldset"
          content={[
            {
              tabName,
              tabContent: (
                <>
                  {!!children && <div className="form-group">{children}</div>}
                  {showScheduling && (
                    <div
                      className={classnames(
                        'datetime-field form__form-group-input-wrap--error-below contained',
                        {
                          'has-error': errors.removeAtDate,
                        }
                      )}
                    >
                      <div className="date-picker">
                        <DayPickerRangeController
                          block
                          enableOutsideDays
                          endDate={endDate} // momentPropTypes.momentObj or null,
                          endDateId="removeAtDate"
                          firstDayOfWeek={1}
                          focusedInput={focusedInput} // PropTypes.oneOf([START_DATE, END_DATE]) or null,
                          hideKeyboardShortcutsPanel
                          horizontalMonthPadding={0}
                          minimumNights={0}
                          monthFormat="MMMM YYYY"
                          noBorder
                          onDatesChange={this.onDatesChange} // PropTypes.func.isRequired,
                          onFocusChange={this.onFocusChange} // PropTypes.func.isRequired,
                          startDate={startDate} // momentPropTypes.momentObj or null,
                          startDateId="publishAtDate"
                          transitionDuration={0}
                          verticalHeight="260"
                          weekDayFormat="ddd"
                          daySize={40}
                          initialVisibleMonth={initialVisibleMonth}
                        />
                      </div>
                      {errors.removeAtDate &&
                        touched &&
                        touched.removeAtDate && (
                          <small className="validation form__form-group-error invalid-feedback">
                            {errors.removeAtDate}
                          </small>
                        )}
                      <div className="time-inputs">
                        <div className="time-input-container">
                          <FormRow
                            label="Start time (ET)"
                            name={`${
                              nestedFieldName
                                ? `${nestedFieldName}.publishAtTime`
                                : 'publishAtTime'
                            }`}
                            stacked
                          >
                            <TextField
                              name={`${
                                nestedFieldName
                                  ? `${nestedFieldName}.publishAtTime`
                                  : 'publishAtTime'
                              }`}
                              required={schedulingRequired}
                              type="time"
                              disabled={this.startTimeDisabled(values)}
                              validate={[
                                (field, value) =>
                                  validateStartTime(value, values),
                              ]}
                            />
                          </FormRow>
                        </div>
                        <div className="time-input-container">
                          <FormRow
                            label="End time (ET)"
                            name={`${
                              nestedFieldName
                                ? `${nestedFieldName}.removeAtTime`
                                : 'removeAtTime'
                            }`}
                            stacked
                          >
                            <TextField
                              name={`${
                                nestedFieldName
                                  ? `${nestedFieldName}.removeAtTime`
                                  : 'removeAtTime'
                              }`}
                              required={schedulingRequired}
                              type="time"
                              disabled={this.endTimeDisabled(values)}
                              validate={[
                                (field, value) =>
                                  validateEndTime(value, values),
                              ]}
                            />
                          </FormRow>
                        </div>
                      </div>
                    </div>
                  )}
                  {alert}
                  <div className="action-buttons">
                    {isScheduled && (
                      <Button
                        block
                        color="danger"
                        icon={<FontAwesomeIcon icon={faMinusCircle} />}
                        iconAfter={false}
                        outline
                        onClick={() =>
                          this.setState({
                            showUnscheduleModal: true,
                          })
                        }
                      >
                        Unschedule
                      </Button>
                    )}
                    {!isScheduled && tabName === 'Schedule' && (
                      <Button
                        block
                        data-cy="saveChangesButton"
                        className="btn-save-changes"
                        color="primary"
                        disabled={!dirty || !isValid}
                        icon={
                          <FontAwesomeIcon
                            icon={
                              submitText.toLowerCase() === 'schedule'
                                ? faCalendarAlt
                                : faCheck
                            }
                          />
                        }
                        iconAfter={false}
                        loading={isSubmitting && !changingScheduledStatus}
                        type="submit"
                      >
                        {submitText}
                      </Button>
                    )}
                    {tabName !== 'Schedule' && showSubmit && (
                      <Button
                        block
                        className="btn-save-changes"
                        color="primary"
                        disabled={!isValid}
                        icon={
                          <FontAwesomeIcon
                            icon={
                              submitText.toLowerCase() === 'schedule'
                                ? faCalendarAlt
                                : faCheck
                            }
                          />
                        }
                        iconAfter={false}
                        loading={isSubmitting}
                        type="submit"
                        onClick={() => {
                          if (onSubmitClicked) {
                            onSubmitClicked();
                          }
                        }}
                      >
                        {submitText}
                      </Button>
                    )}
                    {extraActions}
                    {showDelete && (
                      <Button
                        block
                        color="danger"
                        disabled={!id}
                        icon={<FontAwesomeIcon icon={faTrashAlt} />}
                        iconAfter={false}
                        outline
                        onClick={() =>
                          this.setState({
                            showDeleteModal: true,
                          })
                        }
                      >
                        Delete
                      </Button>
                    )}
                  </div>
                </>
              ),
            },
            !!preview && {
              tabName: 'Preview',
              tabContent: previewShow && preview,
            },
            !!activity && {
              tabName: 'Activity',
              tabContent: (
                <Activity
                  action="scheduled by"
                  imgSrc="https://placedog.net/72/72"
                  name="Rick Mason"
                  timestamp="Yesterday at 4:37 pm"
                />
              ),
            },
          ]}
          justify={false}
          onChange={
            !!preview &&
            setPreviewShow &&
            ((tab, name) => {
              if (name && name === 'Preview') {
                setPreviewShow(true);
              } else {
                setPreviewShow(false);
              }
            })
          }
        />
        {showUnscheduleModal && (
          <UnscheduleModal
            title={current && current.title}
            onCancel={this.onCancel}
            onUnschedule={this.onUnschedule}
            isPublished={isPublished}
            entityName="ad"
          />
        )}
        {showDeleteModal && (
          <DeleteModal
            title={current?.title || current?.name}
            message={current?.message}
            helperText={current?.helperText}
            onCancel={this.onCancel}
            onDelete={() => {
              onDelete();
              this.setState({
                showDeleteModal: false,
              });
            }}
            isPublished={isPublished}
            entityName="ad"
          />
        )}
      </div>
    );
  }
}

FormActions.propTypes = {
  /** Activity tab contents */
  activity: PropTypes.node,
  /**  API object */
  api: PropTypes.shape({
    _delete: PropTypes.func,
  }).isRequired,
  /** Additional info */
  alert: PropTypes.node,
  /** Legacy prop form Hallmark MC publishing flow */
  changingScheduledStatus: PropTypes.bool,
  /** Additional nodes, currently used for flexibility while we nail down the UX */
  children: PropTypes.node,
  /** Current item's object */
  current: PropTypes.shape({
    title: PropTypes.string,
    name: PropTypes.string,
    message: PropTypes.string,
    helperText: PropTypes.string,
  }),
  /** Additional actions below the submit button */
  extraActions: PropTypes.node,
  /** Formik object */
  formik: PropTypes.shape({
    errors: PropTypes.shape({
      removeAtDate: PropTypes.string,
    }),
    touched: PropTypes.shape({
      removeAtDate: PropTypes.bool,
    }),
    values: PropTypes.shape({
      name: PropTypes.string,
      publishAtDate: PropTypes.string,
      publishAtTime: PropTypes.string,
      removeAtDate: PropTypes.string,
      removeAtTime: PropTypes.string,
    }),
    dirty: PropTypes.bool.isRequired,
    setFieldTouched: PropTypes.func,
    setFieldValue: PropTypes.func,
  }).isRequired,
  handleSubmit: PropTypes.func,
  id: PropTypes.string,
  /** Triggers loading state on the save button */
  isSubmitting: PropTypes.bool,
  path: PropTypes.string,
  /** In device preview */
  preview: PropTypes.node,
  /** Callback function for when Preview tab becomes active */
  previewShow: PropTypes.func,
  /** Passing state from parent? */
  setPreviewShow: PropTypes.func,
  /** Legacy, show scheduling options */
  showScheduling: PropTypes.bool,
  /** Legacy, must be scheduled */
  schedulingRequired: PropTypes.bool,
  isScheduled: PropTypes.bool,
  nestedFieldName: PropTypes.string,
  submitText: PropTypes.string,
  tabName: PropTypes.string,
  autoFocusEndDate: PropTypes.bool,

  isPublished: PropTypes.bool,
  isValid: PropTypes.bool,
  showDelete: PropTypes.bool,
  onSubmitClicked: PropTypes.func,
  showSubmit: PropTypes.bool,
  initialVisibleMonth: PropTypes.func,
};

FormActions.defaultProps = {
  activity: null,
  alert: null,
  changingScheduledStatus: false,
  children: null,
  extraActions: null,
  isSubmitting: false,
  path: '',
  preview: null,
  previewShow: false,
  setPreviewShow: null,
  showScheduling: false,
  schedulingRequired: false,
  isScheduled: false,
  nestedFieldName: '',
  submitText: 'Save',
  tabName: 'Actions',
  autoFocusEndDate: false,
  current: null,
  handleSubmit: null,
  id: null,
  isPublished: false,
  isValid: false,
  showDelete: false,
  onSubmitClicked: null,
  showSubmit: true,
  initialVisibleMonth: undefined,
};

export default enhance(FormActions);
