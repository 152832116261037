import React, { useState, useRef, useCallback } from 'react';
import { Row, Col } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes, faTrashAlt } from '@fortawesome/pro-regular-svg-icons';
import { Button, Modalv2 } from 'components';

interface DeleteModalProps {
  deleteText: string;
  title: string;
  message: string;
  helperText: string;
  onDelete: () => void;
  onCancel: () => void;
  isPublished: boolean;
  entityName: string;
}

const DeleteModal: React.FC<DeleteModalProps> = ({
  deleteText = 'Yes, Delete',
  title,
  message,
  helperText,
  onDelete,
  onCancel,
  isPublished,
  entityName,
}) => {
  const [isDeleting, setIsDeleting] = useState(false);
  const removeButtonRef = useRef<HTMLButtonElement>(null);
  const focusSaveButtonOnOpened = () => removeButtonRef?.current?.focus();

  const handleOnDelete = useCallback(() => {
    setIsDeleting(true);
    onDelete();
  }, [onDelete]);

  return (
    <Modalv2
      isOpen
      color="danger"
      onOpened={focusSaveButtonOnOpened}
      size="sm"
      handleClose={onCancel}
      footer={
        <Row>
          <Col xs="auto">
            <Button
              color="black-tertiary"
              icon={<FontAwesomeIcon icon={faTimes} />}
              onClick={onCancel}
              outline
              disabled={isDeleting}
            >
              Cancel
            </Button>
          </Col>
          <Col xs="auto">
            <Button
              autoFocus
              color="danger"
              icon={<FontAwesomeIcon icon={faTrashAlt} />}
              innerRef={removeButtonRef}
              onClick={handleOnDelete}
              loading={isDeleting}
            >
              {deleteText}
            </Button>
          </Col>
        </Row>
      }
    >
      {typeof message === 'string' ? <h3>{message}</h3> : message}
      {!message && (
        <h3>
          {isPublished
            ? `${title} is live.
      This will immediately remove the ${entityName} from the app.`
            : `Are you sure you want to delete "${title}?"`}
        </h3>
      )}
      {helperText && <h5>{helperText}</h5>}
    </Modalv2>
  );
};

export default DeleteModal;
