import React, { type PropsWithChildren, createRef, useEffect } from 'react';
import cx from 'classnames';
import { UncontrolledPopover, UncontrolledPopoverProps } from 'reactstrap';
import { Button } from 'components';

import './Popover.scss';

export interface PopoverProps
  extends Pick<UncontrolledPopoverProps, 'placement'> {
  /** className for the trigger button */
  buttonClassName?: string;
  /** Custom trigger button */
  customButton?: JSX.Element;
  /** className for the popover */
  className?: string;
  /** Boolean to determine if the popover is disabled */
  isDisabled?: boolean;
  /** Unique ID required to link the popover to the trigger */
  id: string;
  /* Boolean used to update the zIndex of popovers inside modals */
  inModal?: boolean;
  /** Button/trigger contents */
  trigger: JSX.Element | string;
  /** Method to trigger the popover */
  popoverTriggerMethod?: 'focus' | 'click' | 'legacy';
  /** Boolean to determine if the popover should fade in/out */
  fade?: boolean;
  /** Controlled popover state */
  isOpen?: boolean;
  /** Setter function for controlled popover state */
  setIsOpen?: React.Dispatch<React.SetStateAction<boolean>>;
}

/** Popover for showing additional info on click. Required for the color picker. */

const Popover: React.FC<PropsWithChildren<PopoverProps>> = props => {
  const {
    buttonClassName,
    customButton,
    className,
    children,
    isDisabled,
    id,
    inModal,
    placement = 'auto',
    trigger,
    popoverTriggerMethod = 'focus',
    setIsOpen,
    ...remainingProps
  } = props;

  const popoverRef = createRef<HTMLDivElement>();

  useEffect(() => {
    const handleClick = (event: MouseEvent) => {
      if (!popoverRef.current?.contains(event.target as Node)) {
        setIsOpen?.(false);
      }
    };

    document.addEventListener('click', handleClick);

    return () => {
      document.removeEventListener('click', handleClick);
    };
  }, [popoverRef, setIsOpen]);

  return (
    <span>
      {customButton || (
        <Button
          className={cx('popover-btn', buttonClassName)}
          id={id}
          isPopoverTrigger
          tabIndex={-1}
          onClick={event => {
            setIsOpen?.(prevState => !prevState);
            event.currentTarget.focus();
          }}
          disabled={isDisabled}
        >
          {trigger}
        </Button>
      )}
      <UncontrolledPopover
        {...remainingProps}
        placement={placement}
        target={id}
        trigger={popoverTriggerMethod}
        className={cx(className, { modalPopover: inModal })}
      >
        <div ref={popoverRef}>{children}</div>
      </UncontrolledPopover>
    </span>
  );
};

export default Popover;
