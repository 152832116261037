import { hot, setConfig } from 'react-hot-loader';

import React from 'react';
import { QueryClientProvider } from '@tanstack/react-query';
import { Router } from 'react-router-dom';
import history from 'infrastructure/history';

import UploadSummaryV2 from 'components/v2/UploadSummary';

import SignalRContextProvider from 'infrastructure/signalR/SignalRContext';
import FeatureFlagsContextProvider from 'infrastructure/features/FeatureFlagsContext';
import addFontAwesomeIconsToLibrary from 'infrastructure/icons/FontAwesomeIcons';
import UserContextProvider from 'infrastructure/currentUser/UserContext';
import AxiosContextProvider from 'infrastructure/axios/AxiosContext';
import UploadsContextProvider, {
  useUploadsContext,
} from 'infrastructure/uploads/UploadsContext';
import { AppInsightsContextProvider } from 'infrastructure/telemetry/AppInsights';
import MenuCountersContextProvider from 'infrastructure/MenuContext/MenuCountersContext';
import AppRouter from '../../Router';
import ScrollRestoration from 'infrastructure/scrollRestoration/ScrollRestoration';
import queryClient from './queryClient';

addFontAwesomeIconsToLibrary();

setConfig({ pureSFC: true });

const UploadsV2 = () => {
  const { files } = useUploadsContext();
  return <UploadSummaryV2 files={files} />;
};

const App: React.FC = () => (
  <AxiosContextProvider>
    <QueryClientProvider client={queryClient}>
      <UserContextProvider>
        <AppInsightsContextProvider>
          <FeatureFlagsContextProvider>
            <MenuCountersContextProvider>
              <SignalRContextProvider>
                <UploadsContextProvider>
                  <Router history={history}>
                    <ScrollRestoration>
                      <>
                        <AppRouter />
                        <UploadsV2 />
                      </>
                    </ScrollRestoration>
                  </Router>
                </UploadsContextProvider>
              </SignalRContextProvider>
            </MenuCountersContextProvider>
          </FeatureFlagsContextProvider>
        </AppInsightsContextProvider>
      </UserContextProvider>
    </QueryClientProvider>
  </AxiosContextProvider>
);

export default hot(module)(App);
