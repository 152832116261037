import type { ButtonPropsCombined } from 'components/Button/Button';
import type { IconDefinition } from '@fortawesome/fontawesome-svg-core';

type InputIcon = Partial<Omit<ButtonPropsCombined, 'name'>> & {
  name: IconDefinition;
};

type CustomIcon = (className: string) => JSX.Element;

export type TextFieldIcon = InputIcon | CustomIcon;

export function isIconButton(args: any): args is ButtonPropsCombined {
  if ('onClick' in args) {
    return true;
  }

  return false;
}

export function isCustomIcon(args: any): args is CustomIcon {
  return args && {}.toString.call(args) === '[object Function]';
}
