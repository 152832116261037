import React from 'react';
import PropTypes from 'prop-types';
import { Badge } from 'reactstrap';
import './Tags.scss';

/** Renders an array of tags */

const Tags = props => {
  const { color, tags } = props;
  return (
    <div className="tags">
      {tags.map((tag, i) => (
        // eslint-disable-next-line react/no-array-index-key
        <Badge color={color} key={i}>
          {tag}
        </Badge>
      ))}
    </div>
  );
};

Tags.propTypes = {
  /** Bootstrap colors, sets the background color */
  color: PropTypes.oneOf([
    'default',
    'primary',
    'secondary',
    'success',
    'danger',
    'warning',
    'info',
    'light',
    'dark',
  ]),
  /** An array of strings (tags) */
  tags: PropTypes.arrayOf(PropTypes.string).isRequired,
};

Tags.defaultProps = {
  color: 'secondary',
};

export default Tags;
